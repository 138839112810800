import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import PropTypes from "prop-types";
import React from "react";

import "./service.css";

function Service(props) {
  return (
    <section id="services" className="ftco-section bg-light">
      <MDBContainer>
        <MDBRow className="justify-content-center mb-5 pb-2">
          <MDBCol md="12" className="text-center heading-section ftco-animate">
            <h2 className="mb-4">{props.title}</h2>
          </MDBCol>
        </MDBRow>
        <MDBRow>{props.children}</MDBRow>
      </MDBContainer>
    </section>
  );
}

Service.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Service;
