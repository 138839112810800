import publicAxios from "../../lib/publicAxios";
import {
  // AUTH
  AUTH_FAIL,
  AUTH_LOGOUT,
  AUTH_START,
  AUTH_SUCCESS,
  CHECK_AUTH_FAIL,

  // CHECK AUTH
  CHECK_AUTH_START,
  CHECK_AUTH_SUCCESS,

  // CSRF
  FETCH_CSRF_FAIL,
  FETCH_CSRF_START,
  FETCH_CSRF_SUCCESS,

  // FORGOT PASSWORD
  FORGOT_PASSWORD_OTP_FAIL,
  FORGOT_PASSWORD_OTP_START,
  FORGOT_PASSWORD_OTP_SUCCESS,

  // REGISTER
  REGISTER_FAIL,
  REGISTER_START,
  REGISTER_SUCCESS,

  // RESET PASSWORD
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,

  // SET REDIRECT PATH
  SET_AUTH_REDIRECT_PATH,

  // SET IS AUTHENTICATED
  SET_IS_AUTHENTICATED,
} from "./types";

const _isAuthenticated = (token, expiresAt) => {
  if (!token || !expiresAt) {
    return false;
  }

  return new Date().getTime() / 1000 < expiresAt;
};

export function isAuthenticated() {
  return (dispatch, getState) => {
    const token = getState().auth.auth.token;
    const expiresAt = getState().auth.auth.expiresAt;
    const authStatus = _isAuthenticated(token, expiresAt);
    dispatch({ type: SET_IS_AUTHENTICATED, payload: authStatus });
  };
}

export function fetchCsrfToken() {
  return async (dispatch) => {
    dispatch({
      type: FETCH_CSRF_START,
    });

    publicAxios
      .get("/auth/csrf", {
        responseType: "text",
        withCredentials: true,
      })
      .then((res) => {
        dispatch({ type: FETCH_CSRF_SUCCESS, payload: res.data });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          dispatch({
            type: FETCH_CSRF_FAIL,
            payload: err.response.data.message,
          });
        } else if (err.message) {
          dispatch({
            type: FETCH_CSRF_FAIL,
            payload: err.message,
          });
        } else {
          dispatch({
            type: FETCH_CSRF_FAIL,
            payload: err,
          });
        }
      });
  };
}

export function checkAuthState(successCallback, failCallback) {
  return (dispatch) => {
    dispatch({ type: CHECK_AUTH_START });

    const token = localStorage.getItem("token");
    const expiresAt = localStorage.getItem("expiresAt");
    const userInfo = localStorage.getItem("userInfo");

    const authStatus = _isAuthenticated(token, expiresAt);

    if (authStatus) {
      dispatch({ type: CHECK_AUTH_SUCCESS });
      dispatch({
        type: AUTH_SUCCESS,
        payload: {
          token,
          expiresAt,
          userInfo: userInfo ? JSON.parse(userInfo) : {},
        },
      });

      successCallback && typeof successCallback === "function" && successCallback();
    } else {
      dispatch({ type: SET_IS_AUTHENTICATED, payload: false });
      dispatch({ type: CHECK_AUTH_FAIL });

      failCallback && typeof failCallback === "function" && failCallback();
    }
  };
}

export function authenticate({ mobileNumber, password }, callback) {
  return async (dispatch) => {
    dispatch({ type: AUTH_START });

    try {
      const { data } = await publicAxios.post("/auth/login", {
        mobileNumber,
        password,
      });
      const token = data.token;
      const expiresAt = data.expiresAt;

      localStorage.setItem("token", token);
      localStorage.setItem("expiresAt", expiresAt);
      localStorage.setItem("userInfo", JSON.stringify(data.user));

      dispatch({
        type: AUTH_SUCCESS,
        payload: {
          token,
          expiresAt,
          userInfo: data.user,
        },
      });

      callback && typeof callback === "function" && callback();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        dispatch({
          type: AUTH_FAIL,
          payload: error.response.data.message,
        });
      } else {
        dispatch({
          type: AUTH_FAIL,
          payload: error.message ? error.message : error,
        });
      }
    }
  };
}

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expiresAt");
  localStorage.removeItem("userInfo");

  return {
    type: AUTH_LOGOUT,
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

/**
 *
 * @param {*} firstName
 * @param {*} lastName
 * @param {*} mobileNumber
 * @param {*} email
 * @param {*} tcNumber
 * @param {*} birthYear
 */
export function register(
  firstName,
  lastName,
  mobileNumber,
  email,
  tcNumber,
  birthYear,
  captchaToken,
  successCallback,
) {
  return (dispatch, getState) => {
    const csrf = getState().auth.csrf.data;
    dispatch({ type: REGISTER_START });

    publicAxios
      .post(
        "/auth/register",
        {
          firstName: firstName.trim().toLocaleUpperCase("tr-TR"),
          lastName: lastName.trim().toLocaleUpperCase("tr-TR"),
          mobileNumber,
          email: email.trim(),
          tcNumber: tcNumber.trim(),
          birthYear,
          captchaToken,
        },
        {
          withCredentials: true,
          headers: {
            "CSRF-TOKEN": csrf,
          },
        },
      )
      .then((response) => {
        dispatch({ type: REGISTER_SUCCESS, payload: response.data });
        successCallback();
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          try {
            const message = JSON.parse(err.response.data.message);
            dispatch({ type: REGISTER_FAIL, payload: message });
          } catch {
            dispatch({ type: REGISTER_FAIL, payload: err.response.data });
          }
        } else if (err.response && err.response.data && err.response.data.error) {
          dispatch({
            type: REGISTER_FAIL,
            payload: {
              message: err.response.data.error.message,
              code: err.response.data.error.statusCode,
            },
          });
        } else if (err.message) {
          dispatch({
            type: REGISTER_FAIL,
            payload: err.message,
          });
        } else {
          dispatch({
            type: REGISTER_FAIL,
            payload: {
              message: err,
              code: 400,
            },
          });
        }
      });
  };
}

export const forgotPasswordOTP = (mobileNumber) => {
  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_OTP_START,
    });

    publicAxios
      .post("/auth/forgot-password", {
        mobileNumber,
      })
      .then((response) => {
        dispatch({
          type: FORGOT_PASSWORD_OTP_SUCCESS,
          payload: response.data.smsReference,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          dispatch({
            type: FORGOT_PASSWORD_OTP_FAIL,
            payload: err.response.data.message,
          });
        } else if (err.message) {
          dispatch({
            type: FORGOT_PASSWORD_OTP_FAIL,
            payload: err.message,
          });
        } else {
          dispatch({ type: FORGOT_PASSWORD_OTP_FAIL, payload: err });
        }
      });
  };
};

export const resetPassword = (password, password2, smsReference) => {
  return (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_START,
    });

    publicAxios
      .post("/auth/reset-password", {
        password,
        password2,
        smsReference,
      })
      .then((response) => {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          dispatch({
            type: RESET_PASSWORD_FAIL,
            payload: err.response.data.message,
          });
        } else if (err.message) {
          dispatch({
            type: RESET_PASSWORD_FAIL,
            payload: err.message,
          });
        } else {
          dispatch({ type: RESET_PASSWORD_FAIL, payload: err });
        }
      });
  };
};
