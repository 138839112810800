import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import PropTypes from "prop-types";
import React from "react";

function LicensingArea(props) {
  const className = props.bgLight ? "ftco-section bg-light" : "ftco-section";
  return (
    <section className={className}>
      <MDBContainer>
        <MDBRow className="no-gutters justify-content-center mb-5 pb-2">
          <MDBCol md="12" className="text-center heading-section ftco-animate">
            <h2 className="mb-4">{props.title}</h2>
            {props.description ? <p>{props.description}</p> : null}
          </MDBCol>
        </MDBRow>
        <MDBRow className="no-gutters d-flex align-items-stretch">{props.children}</MDBRow>
      </MDBContainer>
    </section>
  );
}

LicensingArea.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node,
};

export default LicensingArea;
