export const GA_TRACKING_ID = "G-N06Y1YLXR4";

export const pageview = (path) => {
  window.gtag("config", GA_TRACKING_ID, {
    page_path: path,
  });
};

export const event = (action, category, label, value) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};
