import { connect } from "react-redux";
import { MDBCol, MDBRow, MDBSpinner, MDBAlert } from "mdbreact";
import React, { Component, Fragment } from "react";

import MaskInput from "../../Form/MaskInput";
import Stats from "../Stats";

import * as actions from "../../../redux/otprequest/actions";

class SecondStep extends Component {
  state = {
    time: 0,
    isOn: false,
  };

  update = e => {
    this.props.update(e.target.name, e.target.value);
  };

  startTimer = () => {
    this.setState({
      isOn: true,
      time: 300,
    });
    this.timer = setInterval(() => {
      let time = this.state.time - 1;
      if (time === 0) {
        this.stopTimer();
        this.props.previousStep();
      }
      this.setState({
        time,
      });
    }, 1000);
  };

  stopTimer = () => {
    this.setState({ isOn: false, time: 0 });
    clearInterval(this.timer);
  };

  resetTimer = () => {
    this.setState({ time: 300, isOn: false });
  };

  submit = () => {
    const { mobileNumber, verificationCode, smsReference } = this.props.form;
    const cleanedMobileNumber = mobileNumber.replace(/\s/g, "");

    console.log("asdasdasfasf");
    this.props.verify(verificationCode, smsReference, cleanedMobileNumber);
  };

  componentDidUpdate() {
    const { loading, error, data } = this.props;
    if (!loading && error && error.code > 1000 && error.code < 1004) {
      setTimeout(() => {
        this.props.firstStep();
      }, 4000);
    } else if (!loading && !error && data && data.ok && data.ok === true) {
      this.props.nextStep();
    }
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  render() {
    const { loading, error } = this.props;

    return (
      <div>
        <h3 className="dark-grey-text text-center">
          <strong>Onay kodunuzu giriniz</strong>
        </h3>
        <hr />

        {loading ? (
          <MDBRow>
            <MDBCol className="text-center" md="12">
              <MDBSpinner />
            </MDBCol>
          </MDBRow>
        ) : (
          <Fragment>
            {error ? (
              <MDBRow>
                <MDBCol md="12">
                  <MDBAlert color="danger" className="text-center">
                    {error.message}
                    {error.code > 1000 && error.code < 1004
                      ? "3 saniye içerisinde tekrar başa yönlendirileceksiniz."
                      : null}
                  </MDBAlert>
                  <hr />
                </MDBCol>
              </MDBRow>
            ) : null}

            <MDBRow>
              <MDBCol md="12" sm="12" className="text-center">
                <p>Telefonunuza gönderilen 6 haneli kodu giriniz</p>
                <p>
                  Onay kodunuz
                  <strong> {this.state.time}</strong> saniye sonra geçersiz olacak.
                </p>
              </MDBCol>
              <MDBCol md="12">
                <MaskInput
                  name="verificationCode"
                  label="Onay kodunu giriniz"
                  mask="999999"
                  maskChar="_"
                  onChange={this.update}
                  tooltip="6 haneli onay kodunuzu giriniz"
                />
              </MDBCol>
            </MDBRow>
          </Fragment>
        )}

        <div className="w-100 text-center">
          <Stats step={2} {...this.props} nextStep={this.submit} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.otpRequest.verify,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    verify: (pin, smsReference, mobileNumber) =>
      dispatch(actions.otpVerify(pin, smsReference, mobileNumber)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecondStep);
