import React, { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MDBBox, toast } from "mdbreact";

import TopNavigation from "../TopNavigation/AdminTopNavigation";
import * as authActions from "../../redux/auth/actions";
import "./admin.css";

const marginStyle = { margin: "4rem 4% 4rem" };

export default React.memo(function AdminLayout({ children }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const authState = useSelector((state) => state.auth.auth, shallowEqual);

  const failCallback = useCallback(() => {
    history.push("/cikis");
    toast.error("Oturum süreniz dolduğu için anasayfaya yönlendirildiniz", {
      autoClose: 10000,
      bodyClassName: "text-center",
      closeButton: true,
      position: "top-center",
    });
  }, [history]);

  const checkAuthStatus = useCallback(() => {
    dispatch(authActions.checkAuthState(null, failCallback));
  }, [dispatch, failCallback]);

  useEffect(() => {
    window.addEventListener("focus", checkAuthStatus);
    window.addEventListener("blur", checkAuthStatus);
    document.body.style.backgroundColor = "#eee";

    return () => {
      window.removeEventListener("focus", checkAuthStatus);
      window.removeEventListener("blur", checkAuthStatus);

      document.body.style.backgroundColor = null;
    };
  }, [checkAuthStatus]);

  return (
    <>
      <MDBBox tag="div" className="admin">
        <MDBBox tag="div" className="flexible-content white-skin">
          <TopNavigation
            items={[
              {
                href: "#!",
                name: authState.userInfo.fullName,
                disabled: true,
              },
              {
                divider: true,
              },
              {
                href: "/cikis",
                name: "Çıkış Yap",
                disabled: false,
              },
            ]}
          />
          <MDBBox tag="main" style={marginStyle}>
            {children || null}
          </MDBBox>
        </MDBBox>
      </MDBBox>
    </>
  );
});
