import updateObject from "../../lib/UpdateObject";
import { OTP_VERIFY_FAIL, OTP_VERIFY_START, OTP_VERIFY_SUCCESS, OTP_VERIFY_RESET } from "./types";

const initialState = {
  verify: {
    loading: false,
    error: null,
    data: {},
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // RESET
    case OTP_VERIFY_RESET:
      return updateObject(state, {
        verify: updateObject(state.verify, { loading: false, error: null, data: {} }),
      });

    // VERIFY
    case OTP_VERIFY_START:
      return updateObject(state, {
        verify: updateObject(state.verify, { loading: true, error: null }),
      });
    case OTP_VERIFY_SUCCESS:
      return updateObject(state, {
        verify: updateObject(state.verify, { loading: false, error: null, data: action.payload }),
      });
    case OTP_VERIFY_FAIL:
      return updateObject(state, {
        verify: updateObject(state.verify, { loading: false, error: action.payload }),
      });

    default:
      return state;
  }
};

export default reducer;
