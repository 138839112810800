import React, { Component } from "react";
import PropTypes from "prop-types";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";

const thickLine = {
  borderTop: "1px solid #b7b8cb",
};

class CalculationTable extends Component {
  render() {
    const remainingDay = this.props.data.remainingDay;
    const discount = this.props.data.discount;
    const discounted = this.props.data.discounted;

    return (
      <MDBTable responsive borderless>
        <MDBTableHead>
          <tr>
            <th>#</th>
            <th>Detay</th>
            <th>Tutar</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr style={thickLine}>
            <th scope="row">1</th>
            <td>MÜ-YAP TARİFE BEDELİ</td>
            <td>{`₺ ${remainingDay.muyap}`}</td>
          </tr>
          {discount.total > 0 ? (
            <>
              <tr>
                <th scope="row">2</th>
                <td>İndirim Tutarı</td>
                <td>{`₺ ${discount.total}`}</td>
              </tr>
              <tr>
                <th scope="row"></th>
                <td>
                  <strong>İndirim Uygulandıktan Sonraki Tutar</strong>
                </td>
                <td>{`₺ ${discounted.total}`}</td>
              </tr>
            </>
          ) : null}

          <tr style={thickLine}>
            <th scope="row">3</th>
            <td>K.D.V. (%20)</td>
            <td>{`₺ ${this.props.data.tax}`}</td>
          </tr>
          <tr style={thickLine}>
            <th scope="row"></th>
            <td>
              <strong>Genel Toplam</strong>
            </td>
            <td>{`₺ ${this.props.data.total}`}</td>
          </tr>
        </MDBTableBody>
      </MDBTable>
    );
  }
}

CalculationTable.propTypes = {
  data: PropTypes.object.isRequired,
};

CalculationTable.defaultProps = {
  data: {},
};

export default CalculationTable;
