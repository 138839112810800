import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import React, { useState, useRef } from "react";

function MaskInput(props) {
  const [active, setActive] = useState(props.value ? true : false);
  const inputRef = useRef(null);

  return (
    <InputMask
      name={props.name}
      mask={props.mask}
      maskChar={props.maskChar}
      value={props.value}
      onChange={(event) => {
        event.stopPropagation();
        if (!event.target.value) setActive(false);

        props.onChange(event);
      }}
      onFocus={(event) => {
        event.stopPropagation();
        if (event.target.value) setActive(true);

        props.onFocus && typeof props.onFocus === "function" && props.onFocus(event);
      }}
      onBlur={(event) => {
        event.stopPropagation();
        if (!event.target.value) setActive(false);
      }}>
      {(inputProps) => {
        let input = <input ref={inputRef} {...inputProps} type="text" className="form-control" />;

        /*         if (props.tooltip && props.tooltip.length > 0) {
          input = (
            <MDBTooltip material placement="top">
              <input ref={inputRef} {...inputProps} type="text" className="form-control" />
              <div>{props.tooltip}</div>
            </MDBTooltip>
          );
        }
 */
        return (
          <div className="md-form">
            {input || null}
            <label
              onClick={() => {
                if (!active) {
                  setActive(true);
                  inputRef.current && inputRef.current.focus();
                }
              }}
              className={active ? "active" : ""}>
              {props.label}
            </label>
          </div>
        );
      }}
    </InputMask>
  );
}

MaskInput.propTypes = {
  name: PropTypes.string,
  mask: PropTypes.string.isRequired,
  maskChar: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
};

MaskInput.defaultProps = {
  name: "",
  mask: "",
  maskChar: " ",
  onChange: null,
  value: null,
  label: "",
};

export default MaskInput;
