import React from "react";

function PrivacyStatementText() {
  return (
    <>
      <h2 className="h2 h2-responsive text-center mb-5">
        LISANSLAMA.ORG İNTERNET SİTESİ GİZLİLİK BEYANIDIR
      </h2>
      <p>
        MÜ-YAP Bağlantılı Hak Sahibi Fonogram Yapımcıları Meslek Birliği, gerek genel olarak
        internetin ve gerekse de lisanslama.org sitesinin kişisel ve mesleki etkinlikler
        çerçevesinde kullanılması ile ilgili olarak kişisel bilgilerin ciddi bir sorumluluk
        duygusuyla kullanılmasına büyük önem vermektedir.
      </p>
      <p>
        MÜ-YAP, özel hayata saygı kuralları çerçevesinde bilgilerinizin gizliliğini önemsemektedir.
      </p>
      <p>
        MÜ-YAP, lisanslama.org sitesini ziyaret ettiğiniz ve bir takım bilgiler girdiğinizde bu
        bilgilerinizin, gerekli araştırmanın yapılması veya genel olarak bu internet sitesinin
        geliştirilmesi, MÜ-YAP tarafından Fikir ve Sanat Eserleri Kanunu kapsamında gerçekleştirilen
        çalışmalara katkı sağlanması amacıyla kullanılması mümkündür.
      </p>
      <p>
        Genel çalışmalar kapsamında, lisanslama.org internet sitesini ziyaret etmeniz halinde
        kullanıcının isimsiz olduğu durumlarda oturumunu izleyebilmek için IP adresleri
        kaydedilebilmektedir.
      </p>
      <p>
        Bu veriler, www.lisanslama.org internet sitesinin kullanıcılarının sitemizin hangi
        bölümlerini ziyaret ettiği ve burada ne kadar kaldığı gibi istatistiksel bilgiler edinmek
        için analiz edebiliriz.
      </p>
      <p>
        İşbu Gizlilik Beyanımız ile ilgili olarak sorularınızı{" "}
        <a href="mailto:lisanslama@lisanslama.org" target="_blank" rel="noopener noreferrer">
          lisanslama@lisanslama.org
        </a>{" "}
        adrnesine iletebilirsiniz
      </p>
      <p>Saygılarımızla,</p>
    </>
  );
}

export default React.memo(PrivacyStatementText);
