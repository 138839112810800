export const ADD_FEEDBACK_START = "ADD_FEEDBACK_START";
export const ADD_FEEDBACK_SUCCESS = "ADD_FEEDBACK_SUCCESS";
export const ADD_FEEDBACK_FAIL = "ADD_FEEDBACK_FAIL";

export const FEEDBACK_SHOW_MODAL = "FEEDBACK_SHOW_MODAL";
export const FEEDBACK_HIDE_MODAL = "FEEDBACK_HIDE_MODAL";

export const CONTACT_SHOW_MODAL = "CONTACT_SHOW_MODAL";
export const CONTACT_HIDE_MODAL = "CONTACT_HIDE_MODAL";

export const REQUEST_CONTACT_START = "REQUEST_CONTACT_START";
export const REQUEST_CONTACT_SUCCESS = "REQUEST_CONTACT_SUCCESS";
export const REQUEST_CONTACT_FAIL = "REQUEST_CONTACT_FAIL";
