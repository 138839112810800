import updateObject from "../../lib/UpdateObject";

import {
  // FETCH ALL
  FETCH_CITIES_START,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAIL,

  // FETCH BY ID
  FETCH_CITY_BY_ID_START,
  FETCH_CITY_BY_ID_FAIL,
  FETCH_CITY_BY_ID_SUCCESS,
} from "./types";

const initialState = {
  cities: {
    loading: false,
    error: null,
    data: [],
  },
  city: {
    loading: false,
    error: null,
    data: {},
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH ALL
    case FETCH_CITIES_START:
      return updateObject(state, {
        cities: updateObject(state.cities, { loading: true, error: null }),
      });
    case FETCH_CITIES_SUCCESS:
      return updateObject(state, {
        cities: updateObject(state.cities, { loading: false, error: null, data: action.payload }),
      });
    case FETCH_CITIES_FAIL:
      return updateObject(state, {
        cities: updateObject(state.cities, { loading: false, error: action.payload }),
      });

    // FETCH BY ID
    case FETCH_CITY_BY_ID_START:
      return updateObject(state, {
        city: updateObject(state.city, { loading: true, error: null }),
      });
    case FETCH_CITY_BY_ID_SUCCESS:
      return updateObject(state, {
        city: updateObject(state.city, {
          loading: false,
          error: null,
          data: updateObject(state.city.data, action.payload),
        }),
      });
    case FETCH_CITY_BY_ID_FAIL:
      return updateObject(state, {
        city: updateObject(state.city, { loading: false, error: action.payload }),
      });

    default:
      return state;
  }
};

export default reducer;
