import updateObject from "../../lib/UpdateObject";
import {
  // ADD LICENSE
  ADD_LICENSE_FAIL,
  ADD_LICENSE_START,
  ADD_LICENSE_SUCCESS,

  // UI
  NEW_LICENSE_SHOW_MODAL,
  NEW_LICENSE_HIDE_MODAL,
  IS_VALID_LICENSE_FAIL,
  IS_VALID_LICENSE_SUCCESS,
  IS_VALID_LICENSE_START,
  RESET_IS_VALID_LICENSE,
} from "./types";

const initialState = {
  license: {
    data: {},
    loading: false,
    error: null,
  },
  isValid: {
    loading: false,
    error: null,
    data: {},
  },
  ui: {
    newLicenseModalShow: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // ADD LICENSE
    case ADD_LICENSE_START:
      return updateObject(state, {
        license: updateObject(state.license, { loading: true, error: null }),
      });
    case ADD_LICENSE_SUCCESS:
      return updateObject(state, {
        license: updateObject(state.license, { loading: false, error: null, data: action.payload }),
      });
    case ADD_LICENSE_FAIL:
      return updateObject(state, {
        license: updateObject(state.license, { loading: false, error: action.payload }),
      });

    case IS_VALID_LICENSE_START:
      return updateObject(state, {
        isValid: updateObject(state.isValid, { loading: true, error: null }),
      });
    case IS_VALID_LICENSE_SUCCESS:
      return updateObject(state, {
        isValid: updateObject(state.isValid, {
          data: action.payload,
          loading: false,
          error: null,
        }),
      });
    case IS_VALID_LICENSE_FAIL:
      return updateObject(state, {
        isValid: updateObject(state.isValid, { loading: false, error: action.payload }),
      });
    case RESET_IS_VALID_LICENSE:
      return updateObject(state, {
        isValid: updateObject(state.isValid, { loading: false, error: null, data: {} }),
      });
    // UI
    case NEW_LICENSE_SHOW_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { newLicenseModalShow: true }),
      });
    case NEW_LICENSE_HIDE_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { newLicenseModalShow: false }),
      });
    default:
      return state;
  }
};

export default reducer;
