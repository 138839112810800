import React from "react";
import PropTypes from "prop-types";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

const LogoArea = ({ children }) => (
  <section className="featured-wrapper ftco-section ftco-no-pt ftco-no-pb">
    <MDBContainer fluid>
      <MDBRow>
        <MDBCol md="12">
          <div className="featured">
            <MDBRow>{children}</MDBRow>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </section>
);

LogoArea.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LogoArea;
