import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  Col,
  Container,
  Row,
} from "reactstrap";

const items = [
  {
    src: require("../../../assets/images/slayder.jpg").default,
    altText: "Slide 1",
    caption: "Slide 1",
    key: 2,
    link: "/anasayfa",
  },
  {
    src: require("../../../assets/images/slayder5.jpg").default,
    altText: "Slide 2",
    caption: "Slide 2",
    key: 3,
    link: "/anasayfa/lisans-al#license-request",
  },
];

const LandingLargeWindow = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const history = useHistory();

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.key}>
        <img
          src={item.src}
          alt={item.altText}
          width="100%"
          height="100%"
          style={{ borderRadius: "10px", cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            history.push(item.link);
          }}
        />
      </CarouselItem>
    );
  });

  return (
    <>
      <header
        id="landing-header"
        className="pt-5"
        style={{
          fontFamily: "'Baloo Paaji 2', sans-serif",
          fontWeight: 400,
        }}>
        <div
          id="header-sticky"
          className="tp-header-main__area tp-header-main__pl p-relative z-index">
          <div className="tp-header-main__main-bg"></div>
          <Container className="p-0" fluid>
            <Row className="align-items-center" noGutters>
              <Col
                xl={2}
                lg={4}
                md={4}
                className="d-flex justify-content-center align-items-center">
                <div className="tp-header-main__logo-wrap d-none d-xxl-block">
                  <div className="tp-header-main__logo p-relative">
                    <a href="index.html">
                      <div className="tp-header-main__logo-1 z-index">
                        <img src={require("../../../assets/images/logo/logo.png").default} alt="" />
                      </div>
                    </a>
                  </div>
                </div>

                <div
                  className="tp-header-main__xl-logo d-xxl-none d-flex align-items-center justify-content-center"
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    background:
                      "linear-gradient(180deg, rgba(11,186,251,1) 0%, rgba(66,133,236,1) 100%)",
                    borderRadius: "30px",
                    height: "100%",
                    boxShadow: "2px 2px 10px 0px rgba(0, 0, 0, 0.75)",
                    WebkitBoxShadow: "2px 2px 10px 0px rgba(0, 0, 0, 0.75)",
                  }}>
                  <i className="fa fa-2x fa-user mr-2 text-white"></i>
                  <a
                    href="https://kullanici.lisanslama.org"
                    className="text-white text-left pl-2"
                    style={{
                      fontWeight: 500,
                      fontSize: "18px",
                      borderLeft: "2px solid #fff",
                    }}>
                    Kullanıcı
                    <br />
                    Girişi
                  </a>
                </div>
              </Col>
              <Col xl={10} lg={8} md={8}>
                <div className="tp-header-main__menu-wrapper">
                  <div className="tp-header-main__menu-bg">
                    <div className="tp-header-main__menu-box d-none d-xl-block">
                      <div className="tp-header-main__menu menu-icon p-relative">
                        <nav className="tp-main-menu-content">
                          <ul className="d-flex align-items-center">
                            <li
                              style={{
                                marginTop: "1px",
                                borderTop: "5px solid #fff",
                              }}>
                              <Link to="/anasayfa">
                                Müzik Lisansı
                                <br />
                                Hakkında Detaylı Bilgi
                              </Link>
                            </li>
                            <li>
                              <Link to="/gizlilik/kisisel-verilerin-korunmasi-ve-islenmesi-politikasi">
                                Kişisel Verilerin
                                <br />
                                İşlenmesi ve Korunması
                              </Link>
                            </li>
                            <li>
                              <Link to="/gizlilik/kisisel-verilerin-korunmasi-ve-islenmesi-politikasi?sekme=kullanim-kosullari">
                                Kullanım
                                <br />
                                Koşulları
                              </Link>
                            </li>
                            <li>
                              <Link to="/gizlilik/kisisel-verilerin-korunmasi-ve-islenmesi-politikasi?sekme=cerez-politikasi">
                                Çerez
                                <br />
                                Politikası
                              </Link>
                            </li>
                            <li>
                              <Link to="/anasayfa/iletisim">İletişim</Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </header>

      <main className="py-5">
        <Container fluid>
          <Row className="mx-5 my-3">
            <Col md={5} className="d-flex flex-column align-items-center justify-content-center">
              <h1 className="display-1 text-center landing-title">Lisanslama.org</h1>

              <p className="my-3 text-justify landing-description">
                <span style={{ fontWeight: "600" }}>T.C. Kültür ve Turizm Bakanlığı</span>
                'na bağlı müzik meslek birliği <span style={{ fontWeight: "600" }}>MÜ-YAP</span>
                'ın oluşturduğu, kullanıcının müzik lisansına daha kolay ve tek elden ulaşmasını
                sağlayan bir organizasyondur.
              </p>

              <Row className="mt-4">
                <Col md={6}>
                  <Link
                    to="/anasayfa/lisans-al#license-request"
                    className="btn btn-block btn-rounded btn-primary landing-license-button">
                    Lisans Başvurusu
                  </Link>
                </Col>
                <Col md={6}>
                  <Link
                    to="#!"
                    onClick={(e) => {
                      // e.preventDefault();
                      // dispatch(feedbackActions.showContactModal());
                    }}
                    className="btn btn-block btn-rounded btn-outline-primary landing-call-button">
                    Sizi Arayalım
                  </Link>
                </Col>

                <Col md={12} className="my-4"></Col>

                <Col
                  md={6}
                  className="d-flex justify-content-center align-items-center flex-column">
                  <a
                    href="https://www.ktb.gov.tr"
                    target="_blank"
                    rel="noreferrer"
                    className="text-dark text-center">
                    <img
                      src={
                        require("../../../assets/images/logo/bakanlik-yatay-kirmizi.png").default
                      }
                      alt="T.C. Kültür Bakanlığı"
                    />
                    <br />
                  </a>
                  <p
                    style={{
                      marginTop: -10,
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 12,
                      color: "#df1f27",
                    }}>
                    Katkılarıyla.
                  </p>
                </Col>
                <Col
                  md={6}
                  className="d-flex justify-content-center align-items-center flex-column">
                  <a href="https://tr.mu-yap.org" target="_blank" rel="noreferrer">
                    <img
                      src={require("../../../assets/images/logo/muyap_yatay.png").default}
                      alt="MÜ-YAP"
                      height={100}
                    />
                  </a>
                </Col>

                <Col sm={12} className="mt-4">
                  <p
                    className="text-justify landing-description text-muted"
                    style={{ fontSize: 14, fontWeight: "500" }}>
                    Kültür ve Turizm Bakanlığı'nın desteği ile hazırlanan bu projenin içeriği Kültür
                    ve Turizm Bakanlığı'nın görüş ya da tutumunu yansıtmamakta olup, içerik ile
                    ilgili sorumluluk (destek alan) Bağlantılı Hak Sahibi Fonogram Yapımcıları
                    Meslek Birliği (MÜ-YAP)'ne aittir.
                  </p>
                </Col>
              </Row>
            </Col>
            <Col
              md={{
                size: 6,
                offset: 1,
              }}>
              <Carousel
                className="w-100 landing-slider"
                activeIndex={activeIndex}
                next={next}
                previous={previous}>
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {slides}
                <CarouselControl
                  direction="prev"
                  directionText="Previous"
                  onClickHandler={previous}
                />
                <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
              </Carousel>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
};

export default LandingLargeWindow;
