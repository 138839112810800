import { connect } from "react-redux";
import { MDBRow, MDBCol, MDBSpinner, MDBAlert } from "mdbreact";
import React, { Component, Fragment } from "react";

import MaskInput from "../../Form/MaskInput";
import Stats from "../Stats";

import * as actions from "../../../redux/auth/actions";
import * as otpActions from "../../../redux/otprequest/actions";

class FirstStep extends Component {
  update = e => {
    this.props.update(e.target.name, e.target.value);
  };

  submit = () => {
    const mobileNumber = this.props.form.mobileNumber;
    if (!mobileNumber) {
      this.props.requestOTP(mobileNumber);
    } else {
      const cleanedMobileNumber = mobileNumber.replace(/\s/g, "");
      this.props.requestOTP(cleanedMobileNumber);
    }
  };

  componentDidUpdate() {
    const { smsReference, error, loading } = this.props;
    if (!error && !loading && smsReference) {
      this.props.update("smsReference", smsReference);
      this.props.nextStep();
    }
  }

  render() {
    const { loading, error } = this.props;
    const { mobileNumber } = this.props.form;

    return (
      <div>
        <h3 className="h3 dark-grey-text text-center font-weight-bold">Şifrenizi mi unuttunuz?</h3>
        <hr />

        {loading ? (
          <MDBRow>
            <MDBCol className="text-center" md="12">
              <MDBSpinner />
            </MDBCol>
          </MDBRow>
        ) : (
          <Fragment>
            {error ? (
              <MDBRow>
                <MDBCol md="12">
                  <MDBAlert color="danger" className="text-center">
                    {error}
                  </MDBAlert>
                  <hr />
                </MDBCol>
              </MDBRow>
            ) : null}

            <MDBRow>
              <MDBCol md="12" sm="12">
                <MaskInput
                  name="mobileNumber"
                  mask="999 999 9999"
                  maskChar="_"
                  label="Telefon numaranız"
                  value={mobileNumber}
                  onChange={this.update}
                  tooltip="Kayıt olduğunuz GSM numaranız (Örnek: 555 444 33 22)"
                />
              </MDBCol>
            </MDBRow>
          </Fragment>
        )}

        <div className="w-100 text-center">
          <Stats step={1} {...this.props} nextStep={this.submit} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.auth.forgotPassword,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestOTP: mobileNumber => {
      dispatch(actions.forgotPasswordOTP(mobileNumber));
      dispatch(otpActions.resetOtpVerify());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstStep);
