import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { Helmet } from "react-helmet";

import PageWrap from "../../components/PageWrap";

const Contact = () => (
  <div>
    <Helmet>
      <title>lisanslama.org - İletişim</title>
      <meta
        name="description"
        content="Online lisanslama iletişim sayfası, lisanslama.org iletişim sayfası, online lisanslama ile nasıl iletişime geçebileceğiniz hakkında"
      />
    </Helmet>
    <PageWrap title="İletişim" homeText="Anasayfa" pageName="İletişim" />

    <MDBContainer className="my-5">
      <MDBRow>
        <MDBCol md="12">
          <p>E-posta : lisanslama@lisanslama.org</p>
          <p>Tel :+90 212 243 82 14</p>
          <p>Faks :+90 212 292 46 17</p>
          <p>Adres : Kuloğlu Mh. Turnacıbaşı Cd. No: 10 Kat: 2 Beyoğlu / İstanbul</p>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </div>
);

export default Contact;
