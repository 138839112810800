import { MDBContainer, MDBCardBody, MDBCard, MDBCol, MDBRow } from "mdbreact";
import React, { Component } from "react";
import StepWizard from "react-step-wizard";

import FirstStep from "./Steps/FirstStep";
import SecondStep from "./Steps/SecondStep";
import ThirdStep from "./Steps/ThirdStep";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
    };
  }

  updateForm = (key, value) => {
    const { form } = this.state;

    form[key] = value;
    this.setState({ form });
  };

  render() {
    return (
      <MDBContainer>
        <MDBRow className="justify-content-center align-items-center">
          <MDBCol md="6">
            <MDBCard>
              <MDBCardBody className="z-depth-2">
                <StepWizard isLazyMount>
                  <FirstStep update={this.updateForm} form={this.state.form} />
                  <SecondStep update={this.updateForm} form={this.state.form} />
                  <ThirdStep update={this.updateForm} form={this.state.form} />
                </StepWizard>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default ForgotPassword;
