import React, { Fragment } from "react";
import { MDBBtn } from "mdbreact";

/**
 * Stats Component - to illustrate the possible functions * Could be used for nav buttons or overview
 */
const Stats = ({
  currentStep,
  firstStep,
  goToStep,
  lastStep,
  nextStep,
  previousStep,
  totalSteps,
  step,
}) => (
  <Fragment>
    {step < totalSteps ? (
      <MDBBtn color="primary" onClick={nextStep}>
        Devam edin
      </MDBBtn>
    ) : (
      <MDBBtn color="primary" onClick={nextStep}>
        Tamamla
      </MDBBtn>
    )}
  </Fragment>
);

export default Stats;
