function filterOption(option, inputValue) {
  if (!inputValue) return true;

  const normalizedOption = option.label.toLocaleLowerCase("tr-TR");
  const normalizedInput = inputValue.toLocaleLowerCase("tr-TR");

  return normalizedOption.includes(normalizedInput);
}

function sortComparator(optionA, optionB) {
  return optionA.label.localeCompare(optionB.label, "tr-TR");
}

export { filterOption, sortComparator };
