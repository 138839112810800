import { connect } from "react-redux";
import { MDBRow, MDBCol, MDBInput, MDBSpinner, MDBAlert } from "mdbreact";
import React, { Component, Fragment } from "react";

import Stats from "../Stats";

import * as actions from "../../../redux/auth/actions";

class ThirdStep extends Component {
  update = e => {
    this.props.update(e.target.name, e.target.value);
  };

  submit = () => {
    const { password, password2, smsReference } = this.props.form;
    this.props.resetPassword(password, password2, smsReference);
  };

  componentDidUpdate() {
    const { loading, error, result } = this.props;
    if (!loading && !error && result) {
      window.location.href = "/giris";
    }
  }

  render() {
    const { loading, error } = this.props;

    return (
      <div>
        {loading ? (
          <MDBRow>
            <MDBCol className="text-center" md="12">
              <MDBSpinner />
            </MDBCol>
          </MDBRow>
        ) : (
          <Fragment>
            {error ? (
              <MDBRow>
                <MDBCol md="12">
                  <MDBAlert color="danger" className="text-center">
                    {error}
                  </MDBAlert>
                  <hr />
                </MDBCol>
              </MDBRow>
            ) : null}

            <MDBRow>
              <MDBCol md="12" sm="12">
                <MDBInput name="password" label="Şifreniz" type="password" onChange={this.update} />
                <MDBInput
                  name="password2"
                  label="Şifreniz (Tekrar)"
                  type="password"
                  onChange={this.update}
                />
              </MDBCol>
            </MDBRow>
          </Fragment>
        )}

        <div className="w-100 text-center">
          <Stats step={3} {...this.props} nextStep={this.submit} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.auth.resetPassword,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetPassword: (pw1, pw2, smsReference) =>
      dispatch(actions.resetPassword(pw1, pw2, smsReference)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdStep);
