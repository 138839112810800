import React from "react";
import { Helmet } from "react-helmet";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import PageWrap from "../../components/PageWrap";

const MusicLicense = () => (
  <div>
    <Helmet>
      <title>lisanslama.org - Müzik Lisansı</title>
      <meta
        name="description"
        content="Online lisanslama müzik lisansı sayfası, lisanslama.org müzik lisansı sayfası, lisanslama.org müzik lisansı hakkında"
      />
    </Helmet>
    <PageWrap title="Müzik Lisansı" homeText="Anasayfa" pageName="Müzik Lisansı" />

    <MDBContainer className="my-5">
      <MDBRow>
        <MDBCol md="12">
          <p>
            İşletmenizde müzik yayını yapıyor ve bu müziği müşterilerinize iletiyorsanız telif hakkı
            ortaya çıkar. Müzik lisansı, MÜ-YAP ile yapacağınız müzik yayınları izin sözleşmesidir ve
            işletmenizde yaptığınız müziği MÜ-YAP açısından yasal hale getirir.
          </p>
          <p>
            Müzik lisansı alarak, müziği yaratanlara katkıda bulunur ve müziğin geleceği için bu
            insanları desteklemiş olursunuz. Böylece müziği kaydedenlerin sevdiğiniz müziği yapmaya
            devem etmesini sağlarsınız.
          </p>
          <p>
            İşletmenizde radyo, TV ve dijital cihazlardan yaptığınız ve türü ne olursa olsun bütün
            müzikler için lisans almanız gerekir.
          </p>
          <p>
            Müzik lisansı bedeli iş yerinizin türüne, büyüklüğüne ve yerine göre değişiklik
            gösterir. Kesin olarak ne kadar ödemeniz gerektiğini sayfanın en üstünde bulunan LİSANS
            AL butonuna basarak görebilirsiniz.
          </p>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </div>
);

export default MusicLicense;
