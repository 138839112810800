import { isNil } from "lodash";
import axios from "../../lib/publicAxios";

import { generateErrorMessageString } from "../../utils";
import {
  CALCULATE_FAIL,
  CALCULATE_HIDE_MODAL,
  // UI
  CALCULATE_SHOW_MODAL,
  CALCULATE_START,
  CALCULATE_SUCCESS,
  SEASONAL_INFO_HIDE_MODAL,
  SEASONAL_INFO_SHOW_MODAL,
} from "./types";

/**
 * @name showCalculationModal
 * @description Tarife hesaplama modalını açar
 */

export const showCalculationModal = () => {
  return (dispatch) => {
    dispatch({
      type: CALCULATE_SHOW_MODAL,
    });
  };
};

/**
 * @name hideCalculationModal
 * @description Tarife hesaplama modalını kapatır
 */

export const hideCalculationModal = () => {
  return (dispatch) => {
    dispatch({
      type: CALCULATE_HIDE_MODAL,
    });
  };
};

/**
 * @name showSeasonalInfoModal
 * @description Sezonluk seçilince il bilgilerini gösteren modalı açar
 */
export const showSeasonalInfoModal = () => {
  return (dispatch) => {
    dispatch({ type: SEASONAL_INFO_SHOW_MODAL });
  };
};

/**
 * @name hideSeasonalInfoModal
 * @description Sezonluk seçilince il bilgilerini gösteren modalı kapatır
 */
export const hideSeasonalInfoModal = () => {
  return (dispatch) => {
    dispatch({ type: SEASONAL_INFO_HIDE_MODAL });
  };
};

/**
 * @name calculate
 * @description Verilen parametrelere göre firmanın tarife bedelini hesaplar
 *
 * @param {ObjectId-string} companyType            - Firmanın idsi
 * @param {ObjectId-string} city                   - İlin idsi
 * @param {ObjectId-string} province               - İlçenin idsi
 * @param {number}          squareMeter            - Metrekare
 * @param {number}          roomCount              - Otel/Hastane Oda sayısı
 * @param {number}          starCount              - Otel Yıldız sayısı
 * @param {number}          personCount            - Kişi sayısı
 * @param {boolean}         isSeasonal             - Sezonluk Mu?
 * @param {boolean}         isMunicipalCertificate - Belediye Belgeli Mi?
 */

export const calculate = ({
  companyType,
  city,
  province,
  squareMeter,
  roomCount,
  starCount,
  personCount,
  isSeasonal,
  isMunicipalCertificate,
}) => {
  return (dispatch, getState) => {
    const csrf = getState().auth.csrf.data;

    dispatch({
      type: CALCULATE_START,
    });

    const data = {
      cityId: city,
      provinceId: province,
      companyTypeId: companyType,
      isSeasonal: isSeasonal || false,
      isMunicipalCertificate: isMunicipalCertificate || false,
    };

    if (!isNil(squareMeter)) {
      data.squareMeter = squareMeter;
    }
    if (!isNil(roomCount)) {
      data.roomCount = roomCount;
    }
    if (!isNil(starCount)) {
      data.starCount = starCount;
    }
    if (!isNil(personCount)) {
      data.personCount = personCount;
    }

    axios
      .post("/calculation", data, {
        withCredentials: true,
        headers: {
          "CSRF-TOKEN": csrf,
        },
      })
      .then((response) => {
        const totalPrice = response.data.price.total;
        if (totalPrice <= 1) {
          dispatch({
            type: CALCULATE_FAIL,
            payload: "Hesaplama sırasında bir hata oluştu. Yeniden deneyin veya iletişime geçin.",
          });
        } else {
          dispatch({ type: CALCULATE_SUCCESS, payload: response.data });
        }
      })
      .catch((err) => {
        dispatch({
          type: CALCULATE_FAIL,
          payload: generateErrorMessageString(err),
        });
      });
  };
};
