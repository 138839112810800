import React from "react";

function CookiePolicyText() {
  return (
    <>
      <h3 className="h3 h3-responsive text-center mb-5">ÇEREZ POLİTİKASI</h3>
      <p>
        Çerez politikamız, çerezlerin ne olduğunu, çerezlerin nasıl kullanıldığını, birlikte
        çalışabildiğimiz 3. Tarafların bu web sitesinde çerezleri nasıl kullanabileceğini,
        çerezlerle ilgili seçimlerinizi ve çerezler hakkında daha fazla bilgi olmanızı sağlar
      </p>

      <h3 className="h3 h3-responsive mt-4">ÇEREZ NEDİR?</h3>
      <p>
        Çerezler bir web sitesinden gönderilen ve kullanıcı göz atarken web tarayıcısı tarafından
        kullanıcının bilgisayarında depolanan küçük veri parçacıklarıdır. Bu bir web sitesinin veya
        üçüncü bir kişinin sizi tanımasına ve bir sonraki ziyaretinizi daha kolay hale getirmesine
        ve hizmetin sizin için daha yararlı olmasına olanak tanır.
      </p>
      <p>
        Kullanıcının tarayıcısı, belirli bir web sitesi yüklediğinde çerezler oluşturulur. Web
        sitesi daha sonra bir metin dosyası halinde oluşturduğu bilgiyi tarayıcıya gönderir.
        Kullanıcı aynı web sitesine girdiğinde tarayıcı bu bilgileri alır ve web sitesinin
        sunucusuna gönderir. Böylece siz internette sörf yaparken kullanıcı profilinizin oluşmasını
        sağlar.
      </p>
      <p>
        Çerezler kalıcı veya oturum çerezleri olabilir. Sitemizi ziyaret ettiğinizde web
        tarayıcınıza birkaç çerez dosyası yerleştirebiliriz.
      </p>

      <h3 className="h3 h3-responsive mt-4">NEDEN ÇEREZ KULLANIYORUZ?</h3>

      <p>
        Sizin sitemizi ziyaret ettikten sonraki bir tarihte yeniden siteye geldiğinizde sizi
        hatırlamamız kolaylaşır ve hizmetlerimizi daha yararlı kullanmanızı sağlayabiliriz. Bu
        örneğin odanızı düzenlemeniz ve neyi nerede bulacağınızı bilmeniz gibidir. İnternet sitemizi
        de kendi odanız gibi düşünebilir ve kendiniz yerleştirmeyi deneyebilirsiniz. Çerezler de bu
        anlamda size yardımcı olmaktadır. Bazı çerezler kesinlikle kullanmanız gereken çerezler
        olup, sistemde diğer çerezleri seçmeden bu <b>"Zorunlu Çerezleri Kabul Ediyorum"</b>{" "}
        butonunu tıklayarak sitemizi kullanmaya devam edebilirsiniz.
      </p>
      <p>
        Bazen deneyiminizi geliştirmek için size farklı çerez türleri de sunabiliriz. Örneğin
        “performans çerezleri” ya da “işlevsel çerezler” gibi. Bu çerezleri sadece sen onaylarsan
        kullanabiliriz.
      </p>

      <h3 className="h3 h3-responsive mt-4">ÇEREZLER NE KADAR SÜRER?</h3>
      <p>
        Web sitesini ya da uygulamayı kapattığınızda bazı çerezler otomatik olarak silinir. Bazı
        çerezler, daha uzun kalır ve bilgisayarınıza dosya olarak kaydedilir. Bilgisayarınızda ve
        mobil cihazınızda uygulama ayarlarını bu çerezlerin hepsinin silinmesi için
        değiştirebilirsiniz. Bu konuda teknik destek alınız.
      </p>

      <h3 className="h3 h3-responsive mt-4">ÇEREZLERİ NASIL KONTROL EDERSİNİZ?</h3>
      <p>
        İlk kez web sitemizi ziyaret ettiğinizde, size çerezlerden bahsedeceğiz ve onaylamanız için
        iki seçenek sunacağız. Bu seçeneklerden bir tanesi “sadece zorunlu olan çerezleri kabul
        ediyorum”, diğeri ise “tüm çerezleri kabul ediyorum.” olacaktır. Çerez ayarlarınızı
        değiştirerek onayınızı geri alabilirsiniz. Eğer sadece zorunlu olan çerezleri kabul
        ederseniz sitemizin bazı hizmetlerinden yararlanamayabilirsiniz. Çerez ayarlarınızı açma ve
        kapama durumları ile ilgili aşağıda yer alan linkten faydalanabileceğiniz gibi kullandığınız
        arama motorlarının çerez ayarları ile ilgili bilgilendirmelerine göz atabilirsiniz.
      </p>
      <a
        rel="noopener noreferrer"
        href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DAndroid&hl=tr"
        target="_blank"
        style={{ textDecoration: "underline" }}>
        https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DAndroid&hl=tr
      </a>

      <h3 className="h3 h3-responsive mt-4">TÜM ÇEREZLER AYNI MIDIR?</h3>
      <p>Oturum Çerezleri: Tarayıcınızı kapattığınızda bu çerezler otomatik olarak silinir.</p>
      <p>Kalıcı Çerezler: Bu çerezler süre dolana kadar cihazınızda kalır.</p>
      <p>
        Üçüncü Taraf Çerezleri: Bu çerezler üçüncü taraflar adına saklanır. Çerezler tarayıcınız
        ayarlanarak yönetilebilir ve silinebilir. Ancak bu durum web sitemizde belirli özellikleri
        doğru kullanmanızı engelleyebilir.
      </p>

      <h3 className="h3 h3-responsive mt-4">ÖZELLİKLER NELERDİR?</h3>
      <p>Bazı çerezler web sitesinin düzgün çalışması için gereklidir.</p>
      <p>
        Örneğin kimlik doğrulama çerezleri, konfor çerezleri, performans çerezleri gibi. Arama
        sonuçlarınızı, dili ya da kullandığınız karakteri saklamak için çerezler kullanılabilir.
      </p>
      <p>
        Çerezler, bilgisayar kullanıcılarına ait kimlik, bilgisayar adresi, elektronik posta adresi
        gibi verileri otomatik olarak kaydederler.
      </p>

      <h3 className="h3 h3-responsive mt-4">ÇEREZLERİ HANGİ AMAÇLAR İÇİN KULLANIRIZ?</h3>
      <p>
        Bu web sitesini bir sonraki ziyaretinizde daha kolay kullanmanızı sağlamak, analiz yapmak,
        tercihlerinizi saklamak, davranışsal reklam faaliyetlerini gerçekleştirmek vs. gibi amaçlar
        için çerezler kullanılmaktadır. Sistemimizde üçüncü taraf çerezleri kullanmayı tercih
        etmemekteyiz.
      </p>

      <h3 className="h3 h3-responsive mt-4">ÇEREZLERLE İLGİLİ HAKLARINIZ NELERDİR?</h3>
      <p>
        Çerezleri silmek ya da reddetmek istiyorsanız lütfen çerezlerle ilgili yardım sayfamızı
        ziyaret ediniz. Eğer çerezleri siler ya da reddederseniz bazı sayfaların tamamını
        görüntüleyemez ya da verilerinizi sistemde saklayamazsınız. Sitemizi ziyaret ettiğinizde
        daha önce yaptığınız ya da işlediğiniz tüm tercihleri yeniden işlemek zorunda kalırsınız.
      </p>
      <h3 className="h3 h3-responsive mt-4">ÇEREZLERİ NASIL KONTROL EDERSİNİZ?</h3>
      <p>
        Sitemizi ilk ziyaret ettiğinizde çerezlerimizden bahsedeceğiz ve bunları kullanıp
        kullanmayacağınız ile ilgili onay kutusunu işaretledikten sonra siteyi ziyarete
        başlayabilirsiniz. Burada oluşturduğunuz tercihinizi ayarlardan her zaman
        değiştirebilirsiniz.
      </p>
      <h3 className="h3 h3-responsive mt-4">
        POPÜLER BAĞLANTILARI KULLANMAK SURETİYE ÇEREZLER NASIL YÖNETİLİR?
      </h3>

      <p>
        Çerezlerinizi yönetmek konusunda daha çok bilgi sahibi olmak için aşağıda yer alan
        bağlantıları tıklayın:
      </p>

      <ol style={{ listStyle: "disc" }}>
        <li>
          <a
            rel="noopener noreferrer"
            href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DAndroid&hl=tr"
            target="_blank"
            style={{ textDecoration: "underline" }}>
            Google Chrome
          </a>
        </li>
        <li>
          <a
            rel="noopener noreferrer"
            href="https://support.microsoft.com/tr-tr/help/4027947/microsoft-edge-delete-cookies"
            target="_blank"
            style={{ textDecoration: "underline" }}>
            Microsoft Edge
          </a>
        </li>
        <li>
          <a
            rel="noopener noreferrer"
            href="https://support.mozilla.org/tr/kb/cerezleri-silme-web-sitelerinin-bilgilerini-kaldirma"
            target="_blank"
            style={{ textDecoration: "underline" }}>
            Mozilla Firefox
          </a>
        </li>
        <li>
          <a
            rel="noopener noreferrer"
            href="https://support.microsoft.com/tr-tr/help/278835/how-to-delete-cookie-files-in-internet-explorer"
            target="_blank"
            style={{ textDecoration: "underline" }}>
            Microsoft Internet Explorer
          </a>
        </li>
        <li>
          <a
            rel="noopener noreferrer"
            href="https://www.opera.com/tr/case-studies/clean-browser-and-remove-trackers"
            target="_blank"
            style={{ textDecoration: "underline" }}>
            Opera
          </a>
        </li>
        <li>
          <a
            rel="noopener noreferrer"
            href="https://support.apple.com/tr-tr/guide/safari/sfri11471/mac"
            target="_blank"
            style={{ textDecoration: "underline" }}>
            Apple Safari
          </a>
        </li>
      </ol>
      <p>Diğer tarayıcılarla ilgili tarayıcı geliştiricinin web sitesini ziyaret edebilirsiniz.</p>

      <h3 className="h3 h3-responsive mt-4">ÇEREZLER NE KADAR SÜRE SAKLANIR?</h3>
      <p>
        Çerezler türüne göre değişmekle birlikte iki hafta ile yirmi yıl aralığında değişen saklama
        koşullarına sahiptir. Bazı çerezlerin süresi onları kullanma anınız bittiğinde süresi dolar
        ancak bazı çerezler sizi izlemeye devam edebilir. Kendi web ayarlarınızı yaparak ya da
        yardım butonundan çerezleri silme koşullarına gözden geçirerek de silme işleminizi
        gerçekleştirebilirsiniz.
      </p>

      <h3 className="h3 h3-responsive mt-4">GİZLİLİK</h3>
      <p>
        Gizlilik ile ilgili 6698 sayılı KVKK ve GDPR hükümleri uygulanacak olup, sitemizde yer alan
        Kişisel Verilerin Korunması Politikamızı ve Aydınlatma Metnimizi mutlaka ziyaret ediniz.
      </p>
    </>
  );
}

export default React.memo(CookiePolicyText);
