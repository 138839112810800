import updateObject from "../../lib/UpdateObject";
import {
  PRIVACY_CLARIFICATION_TERM_HIDE_MODAL,
  PRIVACY_CLARIFICATION_TERM_SHOW_MODAL,
  PRIVACY_PROTECTION_OF_PERSONAL_DATA_HIDE_MODAL,
  PRIVACY_PROTECTION_OF_PERSONAL_DATA_SHOW_MODAL,
} from "./types";

const initialState = {
  ui: {
    isClaritifactionTermModalOpen: false,
    isProtectionOfPersonalDataModalOpen: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // UI
    case PRIVACY_CLARIFICATION_TERM_SHOW_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { isClaritifactionTermModalOpen: true }),
      });
    case PRIVACY_CLARIFICATION_TERM_HIDE_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { isClaritifactionTermModalOpen: false }),
      });

    case PRIVACY_PROTECTION_OF_PERSONAL_DATA_SHOW_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { isProtectionOfPersonalDataModalOpen: true }),
      });
    case PRIVACY_PROTECTION_OF_PERSONAL_DATA_HIDE_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { isProtectionOfPersonalDataModalOpen: false }),
      });

    default:
      return state;
  }
};

export default reducer;
