import axios from "../../lib/publicAxios";
import {
  // ADD FEEDBACK
  ADD_FEEDBACK_FAIL,
  ADD_FEEDBACK_START,
  ADD_FEEDBACK_SUCCESS,
  CONTACT_HIDE_MODAL,
  CONTACT_SHOW_MODAL,

  // UI
  FEEDBACK_HIDE_MODAL,
  FEEDBACK_SHOW_MODAL,
  REQUEST_CONTACT_FAIL,
  REQUEST_CONTACT_START,
  REQUEST_CONTACT_SUCCESS,
} from "./types";

/**
 * @name showFeedbackModal
 * @function
 * @description Geri bildirim modalını açar
 */

export const showFeedbackModal = () => {
  return (dispatch) => {
    dispatch({
      type: FEEDBACK_SHOW_MODAL,
    });
  };
};

/**
 * @name hideFeedbackModal
 * @description Geri bildirim modalını kapatır
 */

export const hideFeedbackModal = () => {
  return (dispatch) => {
    dispatch({
      type: FEEDBACK_HIDE_MODAL,
    });
  };
};

export const showContactModal = () => {
  return (dispatch) => {
    dispatch({
      type: CONTACT_SHOW_MODAL,
    });
  };
};

export const hideContactModal = () => {
  return (dispatch) => {
    dispatch({
      type: CONTACT_HIDE_MODAL,
    });
  };
};

/**
 * @name addFeedback
 * @description Geri bildirim ekleme isteği gönderir
 *
 * @param {string} firstName    - Adı
 * @param {string} lastName     - Soyadı
 * @param {string} mobileNumber - Telefon numarası
 * @param {string} message      - Geri bildirim mesajı
 */

export function addFeedback(firstName, lastName, mobileNumber, message, captchaToken) {
  return (dispatch, getState) => {
    const csrf = getState().auth.csrf.data;
    dispatch({ type: ADD_FEEDBACK_START });

    axios
      .post(
        "/feedback",
        {
          firstName,
          lastName,
          mobileNumber,
          message,
          captchaToken,
        },
        {
          withCredentials: true,
          headers: {
            "CSRF-TOKEN": csrf,
          },
        },
      )
      .then((response) => {
        const feedback = response.data.data;
        dispatch({ type: ADD_FEEDBACK_SUCCESS, payload: feedback });
        dispatch(hideFeedbackModal());
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.error) {
          dispatch({
            type: ADD_FEEDBACK_FAIL,
            payload: err.response.data.error.message,
          });
        } else if (err.message) {
          dispatch({
            type: ADD_FEEDBACK_FAIL,
            payload: err.message,
          });
        } else {
          dispatch({
            type: ADD_FEEDBACK_FAIL,
            payload: err,
          });
        }
      });
  };
}

export function requestContact(name, mobileNumber, message, captchaToken) {
  return (dispatch, getState) => {
    const csrf = getState().auth.csrf.data;
    dispatch({ type: REQUEST_CONTACT_START });

    axios
      .post(
        "/feedback/contact",
        {
          name,
          mobileNumber,
          message,
          captchaToken,
        },
        {
          withCredentials: true,
          headers: {
            "CSRF-TOKEN": csrf,
          },
        },
      )
      .then((response) => {
        const data = response.data;
        dispatch({ type: REQUEST_CONTACT_SUCCESS, payload: data });
        dispatch(hideContactModal());
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          dispatch({
            type: REQUEST_CONTACT_FAIL,
            payload: err.response.data.message,
          });
        } else if (err.response && err.response.data && err.response.data.error) {
          dispatch({
            type: REQUEST_CONTACT_FAIL,
            payload: err.response.data.error.message,
          });
        } else if (err.message) {
          dispatch({
            type: REQUEST_CONTACT_FAIL,
            payload: err.message,
          });
        } else {
          dispatch({
            type: REQUEST_CONTACT_FAIL,
            payload: err,
          });
        }
      });
  };
}
