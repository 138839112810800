import React, { useState } from "react";

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBContainer,
  MDBNavbarNav,
} from "mdbreact";

import "./site.css";
import { Link } from "react-router-dom";

function SiteTopNavigation(props) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <MDBNavbar
      expand="lg"
      dark
      color="dark"
      className="ftco_navbar ftco-navbar-light"
      id="ftco-navbar">
      <MDBContainer>
        <MDBNavbarBrand href="/">{props.siteTitle}</MDBNavbarBrand>
        <MDBNavbarToggler tag="button" onClick={toggle}>
          <span className="oi oi-menu"></span> Menü
        </MDBNavbarToggler>

        <MDBCollapse isOpen={isOpen} navbar id="ftco-nav">
          <MDBNavbarNav className="ml-auto" right>
            <MDBNavItem>
              <Link className="nav-link" to="/anasayfa" onClick={toggle}>
                Anasayfa
              </Link>
            </MDBNavItem>
            <MDBNavItem>
              <Link className="nav-link" to="/anasayfa/muzik-lisansi" onClick={toggle}>
                Müzik Lisansı
              </Link>
            </MDBNavItem>
            <MDBNavItem>
              <Link className="nav-link" to="/anasayfa/hakkimizda" onClick={toggle}>
                Hakkımızda
              </Link>
            </MDBNavItem>
            <MDBNavItem>
              <Link className="nav-link" to="/anasayfa/tarifeler" onClick={toggle}>
                Tarifeler
              </Link>
            </MDBNavItem>
            <MDBNavItem>
              <Link className="nav-link" to="/anasayfa/sikca-sorulan-sorular" onClick={toggle}>
                S.S.S.
              </Link>
            </MDBNavItem>
            <MDBNavItem>
              <Link className="nav-link" to="/anasayfa/iletisim" onClick={toggle}>
                İletişim
              </Link>
            </MDBNavItem>
            <MDBNavItem>
              <Link className="nav-link" to="/anasayfa/basvuru-sureci" onClick={toggle}>
                Başvuru Süreci
              </Link>
            </MDBNavItem>
            <MDBNavItem className="cta">
              <MDBNavLink to="/anasayfa/lisans-al">Lisans Al</MDBNavLink>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}

export default SiteTopNavigation;
