import { ToastContainer } from "mdbreact";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import CookieModal from "./components/CookieModal";
import { pageview } from "./lib/gtag";
import * as appActions from "./redux/app/actions";
import * as authActions from "./redux/auth/actions";

export default function App() {
  const location = useLocation();
  const dispatch = useDispatch();

  dispatch(authActions.fetchCsrfToken());
  dispatch(authActions.checkAuthState());

  useEffect(() => {
    dispatch(appActions.fetchCurrentTime());
    pageview(location.pathname);
  }, [dispatch, location.pathname]);

  return (
    <>
      <AppRoutes />
      <CookieModal />
      <ToastContainer hideProgressBar={false} newestOnTop={true} autoClose={15000} />
    </>
  );
}
