import PropTypes from "prop-types";
import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

const Header = ({ phoneNumber, email, openDays, openHours }) => (
  <div className="py-1 bg-black top-nav">
    <MDBContainer>
      <MDBRow className="d-flex align-items-start align-items-center px-md-0">
        <MDBCol lg="12" className="d-block">
          <MDBRow className="no-gutters d-flex">
            <MDBCol className="pr-4 d-flex topper align-items-center">
              <div className="icon mr-2 d-flex justify-content-center align-items-center">
                <span className="icon-phone2"></span>
              </div>
              <span className="text">{phoneNumber}</span>
            </MDBCol>
            <MDBCol className="pr-4 d-flex topper align-items-center">
              <div className="icon mr-2 d-flex justify-content-center align-items-center">
                <span className="icon-paper-plane"></span>
              </div>
              <span className="text">{email}</span>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </div>
);

Header.propTypes = {
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  openHours: PropTypes.string,
  openDays: PropTypes.string,
};

Header.defaultProps = {
  phoneNumber: "",
  email: "",
  openHours: "",
  openDays: "",
};

export default Header;
