import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  Col,
  Container,
  Row,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const items = [
  {
    src: require("../../../assets/images/slayder.jpg").default,
    altText: "Slide 1",
    caption: "Slide 1",
    key: 2,
    link: "/anasayfa",
  },
  {
    src: require("../../../assets/images/slayder5.jpg").default,
    altText: "Slide 2",
    caption: "Slide 2",
    key: 3,
    link: "/anasayfa/lisans-al#license-request",
  },
];

const LandingMobileWindow = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const history = useHistory();

  const { width } = useWindowDimensions();

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.key}>
        <img
          src={item.src}
          alt={item.altText}
          width="100%"
          height="100%"
          style={{ borderRadius: "10px", cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            history.push(item.link);
          }}
        />
      </CarouselItem>
    );
  });

  return (
    <main className="my-4">
      <Container fluid>
        <Row className="mx-1 d-flex flex-row align-items-center justify-content-center">
          <Col lg={12} className="d-flex flex-column align-items-center justify-content-center">
            <h1 className={`text-center landing-title ${width > 400 && "display-4"}`}>
              Lisanslama.org
            </h1>

            <p className=" text-justify landing-description">
              <span style={{ fontWeight: "600" }}>T.C. Kültür ve Turizm Bakanlığı</span>
              'na bağlı müzik meslek birliği <span style={{ fontWeight: "600" }}>MÜ-YAP</span>
              'ın oluşturduğu, kullanıcının müzik lisansına daha kolay ve tek elden ulaşmasını
              sağlayan bir organizasyondur.
            </p>

            <Row noGutters>
              <Col lg={12} className="mb-2">
                <Link
                  to="/anasayfa/lisans-al#license-request"
                  className="btn btn-block btn-rounded btn-primary landing-license-button">
                  Lisans Başvurusu
                </Link>
              </Col>
              <Col lg={12} className="mb-2">
                <Link
                  to="#!"
                  onClick={(e) => {
                    // e.preventDefault();
                    // dispatch(feedbackActions.showContactModal());
                  }}
                  className="btn btn-block btn-rounded btn-outline-primary landing-call-button">
                  Sizi Arayalım
                </Link>
              </Col>

              <Col lg={12}>
                <a
                  href="https://kullanici.lisanslama.org"
                  className="btn btn-block btn-rounded btn-outline-primary landing-call-button">
                  Kullanıcı Girişi
                </a>
              </Col>

              <Col md={12} className="my-2"></Col>

              <Col
                xs={12}
                sm={6}
                className="d-flex justify-content-start align-items-center flex-column">
                <a
                  href="https://www.ktb.gov.tr"
                  target="_blank"
                  rel="noreferrer"
                  className="text-dark text-center">
                  <img
                    src={require("../../../assets/images/logo/bakanlik-yatay-kirmizi.png").default}
                    alt="T.C. Kültür Bakanlığı"
                  />
                  <br />
                </a>
                <p
                  style={{
                    marginTop: -10,
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 12,
                    color: "#df1f27",
                  }}>
                  Katkılarıyla.
                </p>
              </Col>
              <Col
                xs={12}
                sm={6}
                className="d-flex justify-content-start align-items-center flex-column">
                <a href="https://tr.mu-yap.org" target="_blank" rel="noreferrer">
                  <img
                    src={require("../../../assets/images/logo/muyap_yatay.png").default}
                    alt="MÜ-YAP"
                    height={100}
                  />
                </a>
              </Col>

              <Col sm={12} className="mt-2">
                <p
                  className="text-justify landing-description text-muted"
                  style={{ fontSize: 14, fontWeight: "500" }}>
                  Kültür ve Turizm Bakanlığı'nın desteği ile hazırlanan bu projenin içeriği Kültür
                  ve Turizm Bakanlığı'nın görüş ya da tutumunu yansıtmamakta olup, içerik ile ilgili
                  sorumluluk (destek alan) Bağlantılı Hak Sahibi Fonogram Yapımcıları Meslek Birliği
                  (MÜ-YAP)'ne aittir.
                </p>
              </Col>
            </Row>
          </Col>
          <Col lg={12}>
            <Carousel
              className="w-100 landing-slider"
              activeIndex={activeIndex}
              next={next}
              previous={previous}>
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {slides}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
              />
              <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
            </Carousel>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default LandingMobileWindow;
