import Cookies from "js-cookie";
import { MDBBtn, MDBCol, MDBModal, MDBModalBody, MDBRow } from "mdbreact";
import React from "react";
import { useLocation } from "react-router-dom";

function CookieModal() {
  const location = useLocation();
  const allCookiesName = "all-cookies";
  const requiredCookiesName = "required-cookies";
  const [isOpen, setIsOpen] = React.useState(true);

  const toggle = () => setIsOpen(!isOpen);

  const getLegacyCookieName = name => {
    return `${name}-legacy`;
  };

  const getCookieConsentValue = React.useCallback(name => {
    let cookieValue = Cookies.get(name);
    // if the cookieValue is undefined check for the legacy cookie
    if (cookieValue === undefined) {
      cookieValue = Cookies.get(getLegacyCookieName(name));
    }
    return cookieValue;
  }, []);

  const getCookieValue = React.useCallback(
    cookieName => {
      return getCookieConsentValue(cookieName);
    },
    [getCookieConsentValue],
  );

  const setCookie = React.useCallback(
    (cookieName, cookieValue) => {
      const cookieSecurity = location ? location.protocol === "https:" : true;
      const cookieOptions = {
        expires: 365,
        sameSite: "lax",
        secure: cookieSecurity,
      };

      // set the regular cookie
      Cookies.set(cookieName, cookieValue, cookieOptions);
    },
    [location],
  );

  const acceptAllCookies = React.useCallback(() => {
    setCookie(allCookiesName, true);
    setIsOpen(false);
  }, [setCookie]);

  const acceptRequiredCookies = React.useCallback(() => {
    setCookie(requiredCookiesName, true);
    setIsOpen(false);
  }, [setCookie]);

  React.useEffect(() => {
    if (
      getCookieValue(allCookiesName) === undefined &&
      getCookieValue(requiredCookiesName) === undefined
    ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [getCookieValue]);

  return (
    <MDBModal
      keyboard={false}
      position="bottom"
      backdrop={true}
      autoFocus
      frame
      isOpen={isOpen}
      toggle={toggle}>
      <MDBModalBody className="elegant-color-dark text-white">
        <MDBRow>
          <MDBCol md="12" className="text-center">
            <p className="pt-3 pr-2 text-text">
              Web sitemizde size en iyi deneyimi sunabilmemiz için çerezleri kullanıyoruz. Bu siteyi
              kullanmaya devam ederseniz, bunu kabul ettiğinizi varsayarız.
            </p>
          </MDBCol>
          <MDBCol md="12" className="text-center">
            <MDBBtn color="primary" onClick={acceptAllCookies} size="sm">
              Tüm çerezleri kabul ediyorum
            </MDBBtn>
            <MDBBtn color="primary" onClick={acceptRequiredCookies} size="sm">
              Sadece zorunlu çerezleri kullanmayı kabul ediyorum
            </MDBBtn>
            <MDBBtn
              color="primary"
              tag="a"
              href="/gizlilik/kisisel-verilerin-korunmasi-ve-islenmesi-politikasi?sekme=cerez-politikasi"
              onClick={toggle}
              size="sm">
              Ayrıntılı Bilgi
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBModalBody>
    </MDBModal>
  );
}

export default React.memo(CookieModal);
