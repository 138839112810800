import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import React from "react";

import Wizard from "./Wizard";

function LicenseRequest() {
  return (
    <section className="ftco-animate ftco-section ftco-no-pt ftco-no-pb" id="license-request">
      <MDBContainer fluid className="px-0">
        <MDBRow className="no-gutters d-flex">
          <MDBCol md="6" className="d-none d-md-flex align-items-stretch">
            <video autoPlay loop muted style={{ width: "100%", objectFit: "cover" }}>
              <source src={require("../../assets/videos/contract.mp4").default} type="video/mp4" />
            </video>
          </MDBCol>
          <MDBCol md="6" className="p-3 mb-5">
            <div className="py-md-5">
              <div className="heading-section ftco-animate mb-5">
                <h2 className="mb-4">Lisans Başvurunuzu Yapın</h2>
              </div>
              <Wizard />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}

export default LicenseRequest;
