import axios from "../../lib/publicAxios";
import { FETCH_TIME_FAIL, FETCH_TIME_START, FETCH_TIME_SUCCESS } from "./types";

export function fetchCurrentTime() {
  return (dispatch) => {
    dispatch({ type: FETCH_TIME_START });

    axios
      .get("/app/currentTime")
      .then((response) => {
        dispatch({ type: FETCH_TIME_SUCCESS, payload: response.data.data });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          dispatch({
            type: FETCH_TIME_FAIL,
            payload: err.response.data.message,
          });
        } else if (err.message) {
          dispatch({
            type: FETCH_TIME_FAIL,
            payload: err.message,
          });
        } else {
          dispatch({
            type: FETCH_TIME_FAIL,
            payload: err,
          });
        }
      });
  };
}
