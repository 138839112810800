import updateObject from "../../lib/UpdateObject";
import {
  // FETCH USER LICENSES
  FETCH_USER_LICENSES_FAIL,
  FETCH_USER_LICENSES_START,
  FETCH_USER_LICENSES_SUCCESS,

  // FETCH LICENSE BY ID
  FETCH_LICENSE_FAIL,
  FETCH_LICENSE_START,
  FETCH_LICENSE_SUCCESS,

  // FETCH USER COMPANIES
  FETCH_USER_COMPANIES_FAIL,
  FETCH_USER_COMPANIES_START,
  FETCH_USER_COMPANIES_SUCCESS,

  // DOWNLOAD LICENSE PDF
  DOWNLOAD_LICENSE_PDF_FAIL,
  DOWNLOAD_LICENSE_PDF_START,
  DOWNLOAD_LICENSE_PDF_SUCCESS,
  FETCH_USER_PLANTS_START,
  FETCH_USER_PLANTS_SUCCESS,
  FETCH_USER_PLANTS_FAIL,
} from "./types";

const initialState = {
  licenses: {
    data: [],
    loading: false,
    error: null,
  },
  companies: {
    data: [],
    loading: false,
    error: null,
  },
  plants: {
    data: [],
    loading: false,
    error: null,
  },
  license: {
    data: {},
    loading: false,
    error: null,
  },
  pdf: {
    data: null,
    loading: false,
    error: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // USER LICENSES
    case FETCH_USER_LICENSES_START:
      return updateObject(state, {
        licenses: updateObject(state.licenses, { loading: true, error: null }),
      });
    case FETCH_USER_LICENSES_SUCCESS:
      return updateObject(state, {
        licenses: updateObject(state.licenses, {
          data: action.payload,
          loading: false,
          error: null,
        }),
      });
    case FETCH_USER_LICENSES_FAIL:
      return updateObject(state, {
        licenses: updateObject(state.licenses, { loading: false, error: action.payload }),
      });

    // FETCH USER LICENSE BY ID
    case FETCH_LICENSE_START:
      return updateObject(state, {
        license: updateObject(state.license, { loading: true, error: null }),
      });
    case FETCH_LICENSE_SUCCESS:
      return updateObject(state, {
        license: updateObject(state.license, { loading: false, error: null, data: action.payload }),
      });
    case FETCH_LICENSE_FAIL:
      return updateObject(state, {
        license: updateObject(state.license, { loading: false, error: action.payload }),
      });

    // FETCH USER COMPANIES
    case FETCH_USER_COMPANIES_START:
      return updateObject(state, {
        companies: updateObject(state.companies, { loading: true, error: null }),
      });
    case FETCH_USER_COMPANIES_SUCCESS:
      return updateObject(state, {
        companies: updateObject(state.companies, {
          data: action.payload,
          loading: false,
          error: null,
        }),
      });
    case FETCH_USER_COMPANIES_FAIL:
      return updateObject(state, {
        companies: updateObject(state.companies, { loading: false, error: action.payload }),
      });

    // FETCH USER PLANTS
    case FETCH_USER_PLANTS_START:
      return updateObject(state, {
        plants: updateObject(state.plants, { loading: true, error: null }),
      });
    case FETCH_USER_PLANTS_SUCCESS:
      return updateObject(state, {
        plants: updateObject(state.plants, {
          data: action.payload,
          loading: false,
          error: null,
        }),
      });
    case FETCH_USER_PLANTS_FAIL:
      return updateObject(state, {
        plants: updateObject(state.plants, { loading: false, error: action.payload }),
      });

    // LICENSE PDF
    case DOWNLOAD_LICENSE_PDF_START:
      return updateObject(state, {
        pdf: updateObject(state.pdf, { loading: true, error: null }),
      });
    case DOWNLOAD_LICENSE_PDF_SUCCESS:
      return updateObject(state, {
        pdf: updateObject(state.pdf, {
          data: action.payload,
          loading: false,
          error: null,
        }),
      });
    case DOWNLOAD_LICENSE_PDF_FAIL:
      return updateObject(state, {
        pdf: updateObject(state.pdf, { loading: false, error: action.payload }),
      });

    default:
      return state;
  }
};

export default reducer;
