import updateObject from "../../lib/UpdateObject";
import {
  // UI
  CONTRACT_TERM_HIDE_MODAL,
  CONTRACT_TERM_SHOW_MODAL,
} from "./types";

const initialState = {
  ui: {
    showModal: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // UI
    case CONTRACT_TERM_SHOW_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { showModal: true }),
      });
    case CONTRACT_TERM_HIDE_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { showModal: false }),
      });

    default:
      return state;
  }
};

export default reducer;
