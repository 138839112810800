import { has, isArray, isNil, isObject, isString, join } from "lodash";

const getMessage = (error) => {
  if (isString(error)) {
    return error;
  } else if (isObject(error)) {
    if (has(error, "response.data.error")) {
      return getMessage(error.response.data.error);
    } else if (has(error, "request.data.error")) {
      return getMessage(error.request.data.error);
    } else if (has(error, "message")) {
      return getMessage(error.message);
    }
  }
  return error;
};

export default function generateErrorMessageString(error) {
  if (isNil(error)) {
    return "Bir hata oluştu";
  }

  const message = getMessage(error);

  if (isArray(message)) {
    return join(message, ", ");
  } else if (isString(message)) {
    return message;
  }

  return "Bir hata oluştu";
}
