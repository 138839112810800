import React, { Component } from "react";
import StepWizard from "react-step-wizard";

import FirstStep from "./Steps/FirstStep";
import SecondStep from "./Steps/SecondStep";

class Wizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
    };
  }

  updateForm = (key, value) => {
    const { form } = this.state;

    form[key] = value;
    this.setState({ form });
  };

  render() {
    return (
      <StepWizard isLazyMount>
        <FirstStep update={this.updateForm} form={this.state.form} />
        <SecondStep
          calculationRedirectUrl={this.props.calculationRedirectUrl}
          update={this.updateForm}
          form={this.state.form}
        />
      </StepWizard>
    );
  }
}

export default Wizard;
