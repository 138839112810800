import updateObject from "../../lib/UpdateObject";
import {
  //
  CREDIT_CARD_HIDE_MODAL,
  CREDIT_CARD_SHOW_MODAL,
  //
  CREDIT_CARD_V2_HIDE_MODAL,
  CREDIT_CARD_V2_SHOW_MODAL,
  //
  HAVALE_EFT_HIDE_MODAL,
  HAVALE_EFT_SHOW_MODAL,
  //
  PAYMENT_INFO_HIDE_MODAL,
  PAYMENT_INFO_SHOW_MODAL,
  //
  PAYMENT_FAIL_HIDE_MODAL,
  PAYMENT_FAIL_SHOW_MODAL,
  //
  PAYMENT_SUCCESS_HIDE_MODAL,
  PAYMENT_SUCCESS_SHOW_MODAL,
  //
  SECURE_PAYMENT_HIDE_MODAL,
  SECURE_PAYMENT_SHOW_MODAL,
  // PAYMENT
  LICENSE_PAY_FAIL,
  LICENSE_PAY_START,
  LICENSE_PAY_SUCCESS,
  // Card Info
  GET_CARD_INFO_BY_BIN_NO_FAIL,
  GET_CARD_INFO_BY_BIN_NO_START,
  GET_CARD_INFO_BY_BIN_NO_SUCCESS,
  // SUCCESS STATUS
  FETCH_PAYMENT_SUCCESS_STATUS_FAIL,
  FETCH_PAYMENT_SUCCESS_STATUS_START,
  FETCH_PAYMENT_SUCCESS_STATUS_SUCESSS,
  // FAIL Status
  FETCH_PAYMENT_FAIL_STATUS_FAIL,
  FETCH_PAYMENT_FAIL_STATUS_START,
  FETCH_PAYMENT_FAIL_STATUS_SUCESSS,
  RESET_CARD_INFO_BY_BIN_NO,
} from "./types";

const initialState = {
  ui: {
    infoModalShow: false,
    havaleEftModalShow: false,
    creditCardModalShow: false,
    creditCardV2ModalShow: false,
    paymentSuccessModalShow: false,
    paymentFailModalShow: false,
    secureModalShow: false,
  },
  payment: {
    error: null,
    loading: false,
    data: {},
  },
  cardInfo: {
    error: null,
    loading: false,
    data: {},
  },
  successStatus: {
    error: null,
    loading: false,
    data: {},
  },
  failStatus: {
    error: null,
    loading: false,
    data: {},
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // UI
    case PAYMENT_INFO_SHOW_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { infoModalShow: true }),
      });
    case PAYMENT_INFO_HIDE_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { infoModalShow: false }),
      });
    case HAVALE_EFT_SHOW_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { havaleEftModalShow: true }),
      });
    case HAVALE_EFT_HIDE_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { havaleEftModalShow: false }),
      });
    case CREDIT_CARD_SHOW_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { creditCardModalShow: true }),
      });
    case CREDIT_CARD_HIDE_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { creditCardModalShow: false }),
      });
    case CREDIT_CARD_V2_SHOW_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { creditCardV2ModalShow: true }),
      });
    case CREDIT_CARD_V2_HIDE_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { creditCardV2ModalShow: false }),
      });
    case PAYMENT_FAIL_SHOW_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { paymentFailModalShow: true }),
      });
    case PAYMENT_FAIL_HIDE_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { paymentFailModalShow: false }),
      });
    case PAYMENT_SUCCESS_SHOW_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { paymentSuccessModalShow: true }),
      });
    case PAYMENT_SUCCESS_HIDE_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { paymentSuccessModalShow: false }),
      });
    case SECURE_PAYMENT_SHOW_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { secureModalShow: true }),
      });
    case SECURE_PAYMENT_HIDE_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { secureModalShow: false }),
      });

    // PAYMENT
    case LICENSE_PAY_FAIL:
      return updateObject(state, {
        payment: updateObject(state.payment, { loading: false, error: action.payload }),
      });
    case LICENSE_PAY_START:
      return updateObject(state, {
        payment: updateObject(state.payment, { loading: true, error: null }),
      });
    case LICENSE_PAY_SUCCESS:
      return updateObject(state, {
        payment: updateObject(state.payment, { loading: false, error: null, data: action.payload }),
      });

    // Card Info
    case GET_CARD_INFO_BY_BIN_NO_FAIL:
      return updateObject(state, {
        cardInfo: updateObject(state.cardInfo, { loading: false, error: action.payload }),
      });
    case GET_CARD_INFO_BY_BIN_NO_START:
      return updateObject(state, {
        cardInfo: updateObject(state.cardInfo, { loading: true, error: null, data: {} }),
      });
    case GET_CARD_INFO_BY_BIN_NO_SUCCESS:
      return updateObject(state, {
        cardInfo: updateObject(state.cardInfo, {
          loading: false,
          error: null,
          data: action.payload,
        }),
      });
    case RESET_CARD_INFO_BY_BIN_NO:
      return updateObject(state, {
        cardInfo: updateObject(state.cardInfo, {
          loading: false,
          error: null,
          data: null,
        }),
      });

    // SUCCESS STATUS
    case FETCH_PAYMENT_SUCCESS_STATUS_FAIL:
      return updateObject(state, {
        successStatus: updateObject(state.successStatus, {
          loading: false,
          error: action.payload,
          data: {},
        }),
      });
    case FETCH_PAYMENT_SUCCESS_STATUS_START:
      return updateObject(state, {
        successStatus: updateObject(state.successStatus, { loading: true, error: null, data: {} }),
      });
    case FETCH_PAYMENT_SUCCESS_STATUS_SUCESSS:
      return updateObject(state, {
        successStatus: updateObject(state.successStatus, {
          loading: false,
          error: null,
          data: action.payload,
        }),
      });

    // FAIL STATUS
    case FETCH_PAYMENT_FAIL_STATUS_FAIL:
      return updateObject(state, {
        failStatus: updateObject(state.failStatus, {
          loading: false,
          error: action.payload,
          data: {},
        }),
      });
    case FETCH_PAYMENT_FAIL_STATUS_START:
      return updateObject(state, {
        failStatus: updateObject(state.failStatus, { loading: true, error: null, data: {} }),
      });
    case FETCH_PAYMENT_FAIL_STATUS_SUCESSS:
      return updateObject(state, {
        failStatus: updateObject(state.failStatus, {
          loading: false,
          error: null,
          data: action.payload,
        }),
      });

    default:
      return state;
  }
};

export default reducer;
