export const PAYMENT_INFO_SHOW_MODAL = "PAYMENT_INFO_SHOW_MODAL";
export const PAYMENT_INFO_HIDE_MODAL = "PAYMENT_INFO_HIDE_MODAL";

export const HAVALE_EFT_SHOW_MODAL = "HAVALE_EFT_SHOW_MODAL";
export const HAVALE_EFT_HIDE_MODAL = "HAVALE_EFT_HIDE_MODAL";

export const CREDIT_CARD_SHOW_MODAL = "CREDIT_CARD_SHOW_MODAL";
export const CREDIT_CARD_HIDE_MODAL = "CREDIT_CARD_HIDE_MODAL";

export const LICENSE_PAYMENT_START = "LICENSE_PAYMENT_START";
export const LICENSE_PAYMENT_SUCCESS = "LICENSE_PAYMENT_SUCCESS";
export const LICENSE_PAYMENT_FAIL = "LICENSE_PAYMENT_FAIL";

export const CREDIT_CARD_V2_SHOW_MODAL = "CREDIT_CARD_V2_SHOW_MODAL";
export const CREDIT_CARD_V2_HIDE_MODAL = "CREDIT_CARD_V2_HIDE_MODAL";

export const PAYMENT_SUCCESS_SHOW_MODAL = "PAYMENT_SUCCESS_SHOW_MODAL";
export const PAYMENT_SUCCESS_HIDE_MODAL = "PAYMENT_SUCCESS_HIDE_MODAL";

export const PAYMENT_FAIL_SHOW_MODAL = "PAYMENT_FAIL_SHOW_MODAL";
export const PAYMENT_FAIL_HIDE_MODAL = "PAYMENT_FAIL_HIDE_MODAL";

export const SECURE_PAYMENT_SHOW_MODAL = "SECURE_PAYMENT_SHOW_MODAL";
export const SECURE_PAYMENT_HIDE_MODAL = "SECURE_HIDE_SHOW_MODAL";

export const GET_CARD_INFO_BY_BIN_NO_START = "GET_CARD_INFO_BY_BIN_NO_START";
export const GET_CARD_INFO_BY_BIN_NO_SUCCESS = "GET_CARD_INFO_BY_BIN_NO_SUCCESS";
export const GET_CARD_INFO_BY_BIN_NO_FAIL = "GET_CARD_INFO_BY_BIN_NO_FAIL";
export const RESET_CARD_INFO_BY_BIN_NO = "RESET_CARD_INFO_BY_BIN_NO";

export const LICENSE_PAY_START = "LICENSE_PAY_START";
export const LICENSE_PAY_SUCCESS = "LICENSE_PAY_SUCCESS";
export const LICENSE_PAY_FAIL = "LICENSE_PAY_FAIL";

export const FETCH_PAYMENT_SUCCESS_STATUS_START = "FETCH_PAYMENT_SUCCESS_STATUS_START";
export const FETCH_PAYMENT_SUCCESS_STATUS_SUCESSS = "FETCH_PAYMENT_SUCCESS_STATUS_SUCESSS";
export const FETCH_PAYMENT_SUCCESS_STATUS_FAIL = "FETCH_PAYMENT_SUCCESS_STATUS_FAIL";

export const FETCH_PAYMENT_FAIL_STATUS_START = "FETCH_PAYMENT_FAIL_STATUS_START";
export const FETCH_PAYMENT_FAIL_STATUS_SUCESSS = "FETCH_PAYMENT_FAIL_STATUS_SUCESSS";
export const FETCH_PAYMENT_FAIL_STATUS_FAIL = "FETCH_PAYMENT_FAIL_STATUS_FAIL";
