import updateObject from "../../lib/UpdateObject";
import {
  // ADD FEEDBACK
  ADD_FEEDBACK_FAIL,
  ADD_FEEDBACK_START,
  ADD_FEEDBACK_SUCCESS,
  CONTACT_HIDE_MODAL,
  CONTACT_SHOW_MODAL,

  // UI
  FEEDBACK_HIDE_MODAL,
  FEEDBACK_SHOW_MODAL,

  //
  REQUEST_CONTACT_FAIL,
  REQUEST_CONTACT_START,
  REQUEST_CONTACT_SUCCESS,
} from "./types";

const initialState = {
  feedback: {
    data: {},
    loading: false,
    error: null,
  },
  contact: {
    data: {},
    loading: false,
    error: null,
  },
  ui: {
    showModal: false,
    showContactModal: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // UI
    case FEEDBACK_SHOW_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { showModal: true }),
      });
    case FEEDBACK_HIDE_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { showModal: false }),
      });

    case CONTACT_SHOW_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { showContactModal: true }),
      });
    case CONTACT_HIDE_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { showContactModal: false }),
      });

    case ADD_FEEDBACK_START:
      return updateObject(state, {
        feedback: updateObject(state.feedback, { loading: true, error: null }),
      });
    case ADD_FEEDBACK_SUCCESS:
      return updateObject(state, {
        feedback: updateObject(state.feedback, {
          loading: false,
          error: null,
          data: action.payload,
        }),
      });
    case ADD_FEEDBACK_FAIL:
      return updateObject(state, {
        feedback: updateObject(state.feedback, { loading: false, error: action.payload }),
      });

    case REQUEST_CONTACT_START:
      return updateObject(state, {
        contact: updateObject(state.contact, { loading: true, error: null }),
      });
    case REQUEST_CONTACT_SUCCESS:
      return updateObject(state, {
        contact: updateObject(state.contact, {
          loading: false,
          error: null,
          data: action.payload,
        }),
      });
    case REQUEST_CONTACT_FAIL:
      return updateObject(state, {
        contact: updateObject(state.contact, { loading: false, error: action.payload }),
      });

    default:
      return state;
  }
};

export default reducer;
