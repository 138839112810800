import { MDBCol } from "mdbreact";
import PropTypes from "prop-types";
import React from "react";

function ServiceItem(props) {
  return (
    <MDBCol className="d-flex align-self-stretch ftco-animate text-center">
      <div className="media block-6 services d-block">
        <div className="icon d-flex justify-content-center align-items-center">
          <span className={props.icon}></span>
        </div>
        <div className="media-body p-2 mt-3">
          <h3 className="heading">{props.title}</h3>
          <p>{props.description}</p>
        </div>
      </div>
    </MDBCol>
  );
}

ServiceItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default ServiceItem;
