import { MDBAlert, MDBCard, MDBCardBody, MDBCol, MDBInput, MDBRow } from "mdbreact";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactSelect from "react-select";

// Actions
import * as cityActions from "../../../../redux/city/actions";
import * as companyTypeActions from "../../../../redux/companytype/actions";
import * as privacyActions from "../../../../redux/privacy/actions";
import * as provinceActions from "../../../../redux/province/actions";
// Utils
import { toSelectData } from "../../../../utils";
import * as selectUtils from "../../../../utils/react-select-tr-utils";
// Components
import { Modal, ModalBody } from "reactstrap";
import { event } from "../../../../lib/gtag";
import ClarificationTermModal from "../../../PrivacyModals/ClarificationTermModal";
import ProtectionOfPersonalDataModal from "../../../PrivacyModals/ProtectionOfPersonalDataModal";
import Stats from "../Stats";

class FirstStep extends Component {
  state = {
    validated: true,
    termAccepted: true,
    message: "",
    showTurofedMessage: false,
  };

  componentDidMount() {
    this.props.fetchCities();
    this.props.fetchCompanyTypes();

    // Update Wizard component form object to null
    for (let prop in this.props.form) {
      this.props.update(prop, null);
    }

    if (localStorage.getItem("geo_cityId") && localStorage.getItem("geo_cityId") !== "-1") {
      this.props.update("city", localStorage.getItem("geo_cityId"));
      this.handleCityChange({
        label: localStorage.getItem("geo_city"),
        value: localStorage.getItem("geo_cityId"),
      });
    }
    if (localStorage.getItem("geo_provinceId") && localStorage.getItem("geo_provinceId") !== "-1") {
      this.props.update("province", localStorage.getItem("geo_provinceId"));
    }
  }

  handleCityChange = (option) => {
    if (option.value && option.value !== "-1") {
      this.props.fetchProvincesByCity(option.value);
    }
    this.handleSelectChange(option, { name: "city" });
  };

  handleCompanyTypeChange = (option) => {
    this.props.companytype.companyTypes.data.forEach((item) => {
      if (item.id === option.value && item.alias === "hotel") {
        this.toggleTurofedModal();
      }
    });
    this.handleSelectChange(option, { name: "companyType" });
  };

  handleSelectChange = (option, action) => {
    this.props.update(action.name, option.value);
  };

  handleCheckboxChange = (e) => {
    this.props.update(e.target.name, e.target.checked);
  };

  validate = () => {
    const companyType = this.props.form.companyType;
    const termAccepted = this.props.form.termAccepted;
    const clarificationTermAccepted = this.props.form.clarificationTermAccepted;
    const cType = this.props.companytype.companyTypes.data.find((i) => i.id === companyType);

    const companyTypeStatus = companyType || null;
    const termStatus = (termAccepted && termAccepted === true) || null;
    const clarificationTermStatus =
      (clarificationTermAccepted && clarificationTermAccepted === true) || null;
    const cityStatus = this.props.form.city || null;
    const provinceStatus = this.props.form.province || null;

    if (!companyTypeStatus) {
      this.setState({ validated: false, message: "İşletme Türü Seçiniz!" });
    } else if (!cityStatus) {
      this.setState({ validated: false, message: "İl Seçiniz!" });
    } else if (!provinceStatus) {
      this.setState({ validated: false, message: "İlçe Seçiniz!" });
    } else if (!termStatus) {
      this.setState({
        validated: false,
        message: "KVVK Bildirimini Onaylayınız!",
      });
    } else if (!clarificationTermStatus) {
      this.setState({
        validated: false,
        message: "Aydınlatma Metnini Onaylayınız!",
      });
    } else if (cType.alias === "hotel") {
      this.toggleTurofedModal();
    } else {
      this.setState({ validated: true });
      event("license_request_step1", "license_request");
      this.props.nextStep();
    }
  };

  toggleTurofedModal = () => {
    this.setState({ showTurofedMessage: !this.state.showTurofedMessage });
  };

  render() {
    return (
      <>
        <MDBCard>
          <MDBCardBody>
            <MDBRow className="d-flex">
              <MDBCol md="12">
                {this.state.validated ? null : (
                  <MDBAlert className="text-center" color="danger">
                    {this.state.message}
                  </MDBAlert>
                )}
              </MDBCol>

              <MDBCol md="12" className="mb-3">
                <label htmlFor="city">İl</label>
                <ReactSelect
                  filterOption={selectUtils.filterOption}
                  sortComparator={selectUtils.sortComparator}
                  name="city"
                  placeholder="İl Seçiniz"
                  defaultValue={
                    localStorage.getItem("geo_cityId") &&
                    localStorage.getItem("geo_cityId") !== "-1"
                      ? {
                          label: localStorage.getItem("geo_city"),
                          value: localStorage.getItem("geo_cityId"),
                        }
                      : null
                  }
                  options={toSelectData(this.props.city.cities.data, "id", "name")}
                  onChange={this.handleCityChange}
                  isSearchable
                />
              </MDBCol>
              <MDBCol md="12" className="mb-3">
                <label htmlFor="province">İlçe</label>
                <ReactSelect
                  filterOption={selectUtils.filterOption}
                  sortComparator={selectUtils.sortComparator}
                  name="province"
                  placeholder="İlçe Seçiniz"
                  defaultValue={
                    localStorage.getItem("geo_province") &&
                    localStorage.getItem("geo_province") !== "-1"
                      ? {
                          label: localStorage.getItem("geo_province"),
                          value: localStorage.getItem("geo_provinceId"),
                        }
                      : null
                  }
                  options={toSelectData(this.props.province.provinces.data, "id", "name")}
                  onChange={this.handleSelectChange}
                  isSearchable
                />
              </MDBCol>
              <MDBCol md="12">
                <label htmlFor="companyType">İşletme Türü</label>
                <ReactSelect
                  filterOption={selectUtils.filterOption}
                  sortComparator={selectUtils.sortComparator}
                  name="companyType"
                  placeholder="İşletme Türü Seçiniz"
                  options={toSelectData(this.props.companytype.companyTypes.data, "id", "name")}
                  onChange={this.handleCompanyTypeChange}
                  isSearchable
                />
              </MDBCol>

              <MDBCol md="12" className="mt-3">
                <MDBInput
                  containerClass="pl-0"
                  label="KVKK bildirimini okudum ve onaylıyorum"
                  type="checkbox"
                  id="termAccepted"
                  name="termAccepted"
                  onChange={(event) => {
                    if (event.target.checked === true)
                      this.props.showProtectionOfPersonalDataModal();
                    this.handleCheckboxChange(event);
                  }}
                />
                <MDBInput
                  containerClass="pl-0"
                  label="Aydınlatma metnini okudum ve onaylıyorum"
                  type="checkbox"
                  id="clarificationTermAccepted"
                  name="clarificationTermAccepted"
                  onChange={(event) => {
                    if (event.target.checked === true) this.props.showClarificationTermModal();
                    this.handleCheckboxChange(event);
                  }}
                />
              </MDBCol>
            </MDBRow>
            <div className="text-center">
              <Stats step={1} {...this.props} nextStep={this.validate} />
            </div>
          </MDBCardBody>
        </MDBCard>

        <ClarificationTermModal />
        <ProtectionOfPersonalDataModal />

        {this.state.showTurofedMessage === true && (
          <Modal
            isOpen={this.state.showTurofedMessage}
            toggle={this.toggleTurofedModal}
            size="lg"
            centered
            keyboard={false}
            backdrop="static">
            <ModalBody>
              <MDBRow>
                <MDBCol md="12" className="p-5">
                  <h5 className="text-center">
                    <a
                      className="text-dark"
                      rel="noreferrer"
                      target="_blank"
                      href="https://otellisanslama.org">
                      Müzik Lisans Belgesi almak için lütfen tıklayınız
                    </a>
                  </h5>
                </MDBCol>
              </MDBRow>
            </ModalBody>
          </Modal>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { city, companytype, province } = state;
  return { city, companytype, province };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCities: () => dispatch(cityActions.fetchCities()),
    fetchProvincesByCity: (cityId) => dispatch(provinceActions.fetchProvincesByCity(cityId)),
    fetchCompanyTypes: () => dispatch(companyTypeActions.fetchCompanyTypes()),
    showClarificationTermModal: () => dispatch(privacyActions.showClarificationTermModal()),
    showProtectionOfPersonalDataModal: () =>
      dispatch(privacyActions.showProtectionOfPersonalDataModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstStep);
