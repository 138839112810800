import { connect } from "react-redux";
import { MDBModal, MDBModalBody, MDBRow, MDBCol, MDBModalFooter, MDBBtn } from "mdbreact";
import React, { Component } from "react";

import * as calculationActions from "../../redux/calculation/actions";

class SeasonalInfoModal extends Component {
  toggle = () => {
    console.log(this.props.isModalOpen);
    if (this.props.isModalOpen === true) {
      this.props.hideModal();
    } else {
      this.props.showModal();
    }
  };

  componentWillUnmount() {
    this.props.hideModal();
  }

  render() {
    return (
      <MDBModal size="lg" isOpen={this.props.isModalOpen} toggle={this.toggle}>
        <MDBModalBody>
          <MDBRow>
            <MDBCol md="12" className="p-2 mt-4">
              <h5 className="text-center">
                Sezonluk indirimi sadece <b>Antalya, İzmir ve Muğla</b> illeri için geçerlidir.
              </h5>
              <h5 className="text-center mt-5">
                Eğer farklı bir il seçtiyseniz, seçtiğiniz ilin indirim oranları uygulanacaktır!
              </h5>
              <hr />
              <h4 className="text-center text-underline">
                Daha fazla bilgi için telefon numaramız: <b>+90 212 243 82 14</b>
              </h4>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter className="justify-content-center align-items-center">
          <MDBBtn color="blue-grey" onClick={this.toggle}>
            ANLADIM (KAPAT)
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    isModalOpen: state.calculation.ui.showSeasonalInfoModal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showModal: () => dispatch(calculationActions.showSeasonalInfoModal()),
    hideModal: () => dispatch(calculationActions.hideSeasonalInfoModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SeasonalInfoModal);
