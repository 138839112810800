import {
  PRIVACY_CLARIFICATION_TERM_HIDE_MODAL,
  PRIVACY_CLARIFICATION_TERM_SHOW_MODAL,
  PRIVACY_PROTECTION_OF_PERSONAL_DATA_HIDE_MODAL,
  PRIVACY_PROTECTION_OF_PERSONAL_DATA_SHOW_MODAL,
} from "./types";

export const showClarificationTermModal = () => {
  return dispatch => {
    dispatch({
      type: PRIVACY_CLARIFICATION_TERM_SHOW_MODAL,
    });
  };
};

export const hideClarificationTermModal = () => {
  return dispatch => {
    dispatch({
      type: PRIVACY_CLARIFICATION_TERM_HIDE_MODAL,
    });
  };
};

export const showProtectionOfPersonalDataModal = () => {
  return dispatch => {
    dispatch({
      type: PRIVACY_PROTECTION_OF_PERSONAL_DATA_SHOW_MODAL,
    });
  };
};

export const hideProtectionOfPersonalDataModal = () => {
  return dispatch => {
    dispatch({
      type: PRIVACY_PROTECTION_OF_PERSONAL_DATA_HIDE_MODAL,
    });
  };
};
