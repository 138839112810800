import updateObject from "../../lib/UpdateObject";
import { FETCH_TIME_FAIL, FETCH_TIME_START, FETCH_TIME_SUCCESS } from "./types";

const initialState = {
  time: {
    loading: false,
    error: null,
    data: {},
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TIME_START:
      return updateObject(state, {
        time: updateObject(state.time, { loading: true, error: false }),
      });
    case FETCH_TIME_SUCCESS:
      return updateObject(state, {
        time: updateObject(state.time, {
          data: updateObject(state.time.data, action.payload),
          loading: false,
          error: null,
        }),
      });
    case FETCH_TIME_FAIL:
      return updateObject(state, {
        time: updateObject(state.time, { loading: false, error: action.payload }),
      });

    default:
      return state;
  }
};

export default reducer;
