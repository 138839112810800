import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBMask,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBView,
} from "mdbreact";
import React from "react";
import { useHistory } from "react-router-dom";

import LandingImg from "../../assets/images/landing.jpg";

export default function NotFound() {
  const history = useHistory();
  return (
    <MDBView src={LandingImg} style={{ backgroundSize: "cover", height: "100vh" }}>
      <MDBMask className="d-flex justify-content-center align-items-center" overlay="black-strong">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12" lg="12" xl="12" sm="12" className="mx-auto">
              <MDBModal
                size="lg"
                centered
                cascading
                className="modal-avatar"
                isOpen={true}
                backdrop={false}
                toggle={() => true}>
                <MDBModalBody className="text-center p-5">
                  <h1 className="h1-responsive font-weight-bold">404</h1>
                  <hr />
                  <h2 className="h2-responsive">Aradığınız Sayfa Bulunamadı!</h2>
                </MDBModalBody>
                <MDBModalFooter className="justify-content-center">
                  <MDBBtn
                    type="submit"
                    color="primary"
                    onClick={e => {
                      e.preventDefault();
                      history.push("/");
                    }}>
                    Anasayfaya Dön
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModal>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBMask>
    </MDBView>
  );
};
