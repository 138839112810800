import React from "react";
import PropTypes from "prop-types";
import { MDBCol } from "mdbreact";

function StatisticItem(props) {
  return (
    <MDBCol
      md="6"
      lg="3"
      className="mb-4 mb-lg-0 d-flex justify-content-center counter-wrap ftco-animate">
      <div className="block-18">
        <div className="text">
          <strong className="number" data-number={props.number}>
            0
          </strong>
          <span>{props.text}</span>
        </div>
      </div>
    </MDBCol>
  );
}

StatisticItem.defaultProps = {
  number: 0,
  text: "",
};

StatisticItem.propTypes = {
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

export default StatisticItem;
