import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import PropTypes from "prop-types";
import React from "react";

import "./counter.css";

function Statistic(props) {
  return (
    <section className="ftco-section ftco-counter img ftco-no-pt" id="section-counter">
      <MDBContainer>
        <MDBRow className="d-md-flex">
          <MDBCol md="8">
            <MDBRow className="d-md-flex align-items-center">{props.children}</MDBRow>
          </MDBCol>
          <MDBCol className="text-center text-md-left">
            <p>{props.description}</p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}

Statistic.defaultProps = {
  description: "",
};

Statistic.propTypes = {
  description: PropTypes.string.isRequired,
};

export default Statistic;
