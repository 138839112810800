import {
  MDBAlert,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBSpinner,
} from "mdbreact";
import React, { Component, createRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { Modal } from "reactstrap";

import * as feedbackActions from "../../redux/feedback/actions";

class FeedbackModal extends Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = createRef();
  }

  state = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    message: "",
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggleModal = () => {
    this.props.feedback.ui.showModal === true ? this.props.hideModal() : this.props.showModal();
  };

  createFeedback = async () => {
    const { firstName, lastName, mobileNumber, message } = this.state;
    const captchaToken = await this.recaptchaRef.current.executeAsync();
    this.props.addFeedback(firstName, lastName, mobileNumber, message, captchaToken);
  };

  render() {
    return (
      <Modal centered size="md" isOpen={this.props.feedback.ui.showModal} toggle={this.toggleModal}>
        <MDBModalHeader tag="p" toggle={this.toggleModal}>
          Geri Bildirim
        </MDBModalHeader>
        <MDBModalBody className="text-center">
          <MDBIcon far icon="file-alt" size="4x" className="animated rotateIn mb-3" />
          <p className="font-weight-bold mb-3">Düşünceleriniz bizim için değerli</p>
          <p>Neden lisans almak istemediğiniz hakkında bize geri bildirim gönderin.</p>
          <hr />
          <p className="mb-3">Sizinle iletişime geçelim</p>
          <MDBInput
            group
            name="firstName"
            label="Adınız"
            valueDefault={this.state.firstName}
            onChange={this.handleInputChange}
          />
          <MDBInput
            group
            name="lastName"
            label="Soyadınız"
            valueDefault={this.state.lastName}
            onChange={this.handleInputChange}
          />
          <MDBInput
            group
            name="mobileNumber"
            label="Telefon Numaranız"
            valueDefault={this.state.mobileNumber}
            onChange={this.handleInputChange}
          />
          <p className="mb-3">Sebebinizi bize açıklayın</p>
          <MDBInput
            group
            name="message"
            label="Mesajınız"
            type="textarea"
            valueDefault={this.state.message}
            onChange={this.handleInputChange}
          />
          <ReCAPTCHA
            ref={this.recaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
            hl="tr"
            badge="bottomleft"
          />
        </MDBModalBody>
        <MDBModalFooter>
          {this.props.feedback.feedback.loading === true ? (
            <div className="text-center w-100">
              <MDBSpinner />
            </div>
          ) : (
            <div>
              <MDBBtn color="primary" onClick={this.createFeedback}>
                Gönder
                <MDBIcon icon="paper-plane" className="ml-1" />
              </MDBBtn>
              <MDBBtn color="primary" outline onClick={this.toggleModal}>
                Kapat
              </MDBBtn>
            </div>
          )}
          {this.props.feedback.feedback.error && (
            <div style={{ width: "100%" }}>
              <MDBAlert color="danger" className="mb-1">
                {this.props.feedback.feedback.error}
              </MDBAlert>
            </div>
          )}
        </MDBModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    feedback: state.feedback,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showModal: () => dispatch(feedbackActions.showFeedbackModal()),
    hideModal: () => dispatch(feedbackActions.hideFeedbackModal()),
    addFeedback: (firstName, lastName, mobileNumber, message, captchaToken) => {
      dispatch(
        feedbackActions.addFeedback(firstName, lastName, mobileNumber, message, captchaToken),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackModal);
