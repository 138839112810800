import updateObject from "../../lib/UpdateObject";

import {
  // FETCH ALL
  FETCH_COMPANY_TYPES_START,
  FETCH_COMPANY_TYPES_SUCCESS,
  FETCH_COMPANY_TYPES_FAIL,

  // FETCH BY ID
  FETCH_COMPANY_TYPE_BY_ID_START,
  FETCH_COMPANY_TYPE_BY_ID_FAIL,
  FETCH_COMPANY_TYPE_BY_ID_SUCCESS,
} from "./types";

const initialState = {
  companyTypes: {
    loading: false,
    error: null,
    data: [],
  },
  companyType: {
    loading: false,
    error: null,
    data: {},
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH ALL
    case FETCH_COMPANY_TYPES_START:
      return updateObject(state, {
        companyTypes: updateObject(state.companyTypes, { loading: true, error: null }),
      });
    case FETCH_COMPANY_TYPES_SUCCESS:
      return updateObject(state, {
        companyTypes: updateObject(state.companyTypes, {
          loading: false,
          error: null,
          data: action.payload,
        }),
      });
    case FETCH_COMPANY_TYPES_FAIL:
      return updateObject(state, {
        companyTypes: updateObject(state.companyTypes, { loading: false, error: action.payload }),
      });

    // FETCH BY ID
    case FETCH_COMPANY_TYPE_BY_ID_START:
      return updateObject(state, {
        companyType: updateObject(state.companyType, { loading: true, error: null }),
      });
    case FETCH_COMPANY_TYPE_BY_ID_SUCCESS:
      return updateObject(state, {
        companyType: updateObject(state.companyType, {
          loading: false,
          error: null,
          data: action.payload,
        }),
      });
    case FETCH_COMPANY_TYPE_BY_ID_FAIL:
      return updateObject(state, {
        companyType: updateObject(state.companyType, { loading: false, error: action.payload }),
      });

    default:
      return state;
  }
};

export default reducer;
