import { isNil } from "lodash";
import { MDBAlert, MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBRow, MDBSpinner } from "mdbreact";
import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

// Actions
import * as appActions from "../../../../redux/app/actions";
import * as calculationActions from "../../../../redux/calculation/actions";
import * as cityActions from "../../../../redux/city/actions";
import * as companyTypeActions from "../../../../redux/companytype/actions";
import * as feedbackActions from "../../../../redux/feedback/actions";
import * as provinceActions from "../../../../redux/province/actions";

// Components
import FeedbackModal from "../../../FeedbackModal";
import {
  DailyForm,
  DailySquareMeterForm,
  HospitalForm,
  HotelForm,
  PersonForm,
  SquareMeterForm,
} from "../../../LicenseForms";
import SeasonalInfoModal from "../../../SeasonalInfoModal";
import CalculationTable from "../../CalculationTable";
import Stats from "../Stats";

import { useWindowDimensions } from "../../../../hooks";
import { event } from "../../../../lib/gtag";
import { whatIsCompanyTypeForm } from "../../../../utils";

export default function SecondStep(props) {
  const { width } = useWindowDimensions();
  const history = useHistory();
  const [determinatedForm, setDeterminatedForm] = useState(null);
  const [validationStatus, setValidationStatus] = useState(true);
  const [validationText, setValidationText] = useState("");

  const isMobile = width < 768;

  const updateFormInput = useCallback((e) => props.update(e.target.name, e.target.value), [props]);
  const updateFormCheckbox = useCallback(
    (e) => props.update(e.target.name, e.target.checked),
    [props],
  );
  const updateFormSelect = useCallback(
    (option, action) => props.update(action.name, option.value),
    [props],
  );

  // Redux
  const dispatch = useDispatch();
  const appTimeSelector = useSelector((state) => state.app.time);
  const calculationSelector = useSelector((state) => state.calculation.calculation, shallowEqual);
  const calculationUiSelector = useSelector((state) => state.calculation.ui, shallowEqual);
  const citySelector = useSelector((state) => state.city.city, shallowEqual);
  const companyTypeSelector = useSelector((state) => state.companytype.companyType, shallowEqual);
  const provinceSelector = useSelector((state) => state.province.province, shallowEqual);

  // Set form
  const determinateForm = useCallback(
    (companyType) => {
      const formType = whatIsCompanyTypeForm(companyType.alias);

      switch (formType) {
        case "squareMeter":
          setDeterminatedForm(
            <SquareMeterForm
              onCheckboxChange={updateFormCheckbox}
              onTextChange={updateFormInput}
            />,
          );
          break;
        case "daily":
          setDeterminatedForm(
            <DailyForm onCheckboxChange={updateFormCheckbox} onTextChange={updateFormInput} />,
          );
          break;
        case "dailywithsquaremeter":
          setDeterminatedForm(
            <DailySquareMeterForm
              onCheckboxChange={updateFormCheckbox}
              onTextChange={updateFormInput}
            />,
          );
          break;
        case "person":
          setDeterminatedForm(
            <PersonForm onCheckboxChange={updateFormCheckbox} onTextChange={updateFormInput} />,
          );
          break;
        case "hotel":
          setDeterminatedForm(
            <HotelForm
              onCheckboxChange={updateFormCheckbox}
              onSelectChange={updateFormSelect}
              onTextChange={updateFormInput}
            />,
          );
          break;
        case "hospital":
          setDeterminatedForm(
            <HospitalForm onCheckboxChange={updateFormCheckbox} onTextChange={updateFormInput} />,
          );
          break;
        default:
          setDeterminatedForm(null);
          break;
      }
    },
    [updateFormCheckbox, updateFormInput, updateFormSelect],
  );

  useEffect(() => {
    dispatch(appActions.fetchCurrentTime());
    dispatch(companyTypeActions.fetchCompanyTypeById(props.form.companyType, determinateForm));
    dispatch(cityActions.fetchCityById(props.form.city));
    dispatch(provinceActions.fetchProvinceById(props.form.province));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculate = () => {
    event("calculate", "license_request");

    const companyTypeForm = whatIsCompanyTypeForm(companyTypeSelector.data.alias);
    const isSquareMeterIsNil = isNil(props.form.squareMeter);
    const isRoomCountNil = isNil(props.form.roomCount);
    const isPersonCountIsNil = isNil(props.form.personCount);

    let flag = true;

    if (companyTypeForm === "squareMeter") {
      if (isSquareMeterIsNil) {
        flag = false;
        setValidationStatus(false);
        setValidationText("Metrekare giriniz!");
      } else {
        const squareMeter = Number(props.form.squareMeter);
        if (isNaN(squareMeter)) {
          flag = false;
          setValidationStatus(false);
          setValidationText("Geçersiz metrekare bilgisi girdiniz!");
        } else if (squareMeter <= 0) {
          flag = false;
          setValidationStatus(false);
          setValidationText("Metrekare değeri 0 ve 0'dan küçük olamaz!");
        }
      }
    } else if (companyTypeForm === "person") {
      if (isPersonCountIsNil) {
        flag = false;
        setValidationStatus(false);
        setValidationText("Kişi sayısı giriniz!");
      } else {
        const personCount = Number(props.form.personCount);
        if (isNaN(personCount)) {
          flag = false;
          setValidationStatus(false);
          setValidationText("Geçersiz kişi bilgisi girdiniz!");
        } else if (personCount <= 0) {
          flag = false;
          setValidationStatus(false);
          setValidationText("Kişi sayısı 0 ve 0'dan küçük olamaz!");
        }
      }
    } else if (companyTypeForm === "hospital") {
      if (isRoomCountNil) {
        flag = false;
        setValidationStatus(false);
        setValidationText("Oda sayısı giriniz!");
      } else {
        const roomCount = Number(props.form.roomCount);
        if (isNaN(roomCount)) {
          flag = false;
          setValidationStatus(false);
          setValidationText("Geçersiz oda sayısı girdiniz!");
        } else if (roomCount <= 0) {
          flag = false;
          setValidationStatus(false);
          setValidationText("Oda sayısı 0 ve 0'dan küçük olamaz!");
        }
      }
    }

    if (flag === true) {
      dispatch(calculationActions.showCalculationModal());
      dispatch(
        calculationActions.calculate({
          companyType: props.form.companyType,
          city: props.form.city,
          province: props.form.province,
          squareMeter: props.form.squareMeter,
          roomCount: props.form.roomCount,
          starCount: props.form.starCount,
          personCount: props.form.personCount,
          isSeasonal: props.form.isSeasonal,
          isMunicipalCertificate: props.form.isMunicipalCertificate,
        }),
      );
    }
  };

  return (
    <>
      <MDBCard>
        <MDBCardBody>
          <MDBRow className="d-flex">
            <SeasonalInfoModal />

            <MDBCol md="12">
              {!validationStatus && (
                <MDBAlert className="text-center" color="danger">
                  {validationText}
                </MDBAlert>
              )}
            </MDBCol>
            <MDBCol md="12">{determinatedForm || null}</MDBCol>
            <MDBCol md="12">
              <Stats step={2} {...props} nextStep={calculate} />
            </MDBCol>
            <MDBCol md="12">
              <Modal centered size="xl" isOpen={calculationUiSelector.showModal}>
                <ModalBody>
                  {calculationSelector.loading === true ? (
                    <MDBRow>
                      <MDBCol className="d-flex justify-content-center align-items-center flex-column">
                        <MDBSpinner />
                        <p className="mt-3">Hesaplanıyor, lütfen bekleyiniz...</p>
                      </MDBCol>
                    </MDBRow>
                  ) : !isNil(calculationSelector.error) ? (
                    <MDBRow>
                      <MDBCol md="12">
                        <MDBAlert color="danger" className="text-center">
                          {calculationSelector.error}
                        </MDBAlert>
                      </MDBCol>
                    </MDBRow>
                  ) : (
                    <MDBRow>
                      <MDBCol md="12" className="mb-3">
                        <h1 className="text-center">Lisanslama Ücretiniz</h1>
                        <hr />
                      </MDBCol>

                      <MDBCol md="12" className="mb-2 d-sm-block d-md-inline-flex">
                        <MDBCol
                          md="2"
                          sm="6"
                          style={{
                            borderLeft: "2px solid #b7b8cb",
                          }}>
                          <h5>Lisans Başlangıcı</h5>
                          <span>
                            {!appTimeSelector.loading && appTimeSelector.data.startOfYearDate}
                          </span>
                        </MDBCol>
                        <MDBCol
                          md="2"
                          sm="6"
                          style={{
                            borderLeft: "2px solid #b7b8cb",
                          }}>
                          <h5>Lisans Bitişi</h5>
                          <span>
                            {!appTimeSelector.loading && appTimeSelector.data.endOfYearDate}
                          </span>
                        </MDBCol>

                        <MDBCol
                          md="2"
                          sm="6"
                          style={{
                            borderLeft: "2px solid #b7b8cb",
                          }}>
                          <h5>İl</h5>
                          <span>{citySelector.data.name}</span>
                        </MDBCol>
                        <MDBCol
                          md="2"
                          sm="6"
                          style={{
                            borderLeft: "2px solid #b7b8cb",
                          }}>
                          <h5>İlçe</h5>
                          <span>{provinceSelector.data.name}</span>
                        </MDBCol>
                        <MDBCol
                          md="2"
                          sm="6"
                          style={{
                            borderLeft: "2px solid #b7b8cb",
                          }}>
                          <h5>İşletme Türü</h5>
                          <span>{companyTypeSelector.data.name}</span>
                        </MDBCol>
                      </MDBCol>

                      <MDBCol md="12">
                        <hr />
                      </MDBCol>

                      <MDBCol md="12">
                        <CalculationTable data={calculationSelector.data.price} />
                      </MDBCol>

                      {!isMobile && (
                        <MDBCol
                          md="12"
                          className="d-flex align-items-center my-2 flex-row justify-content-end">
                          <h5 className="text-muted mb-0 ml-1 mr-5" style={{ fontWeight: 500 }}>
                            Lisans bedelini kredi kartınızla
                            <br />
                            <b>taksit</b> seçenekleri ile ödeyebilirsiniz.
                          </h5>

                          <img
                            className="mr-4"
                            width={80}
                            src={require("../../../../assets/images/guvenlialisveris.png").default}
                            alt="Güvenli Alışveriş"
                          />
                          <img
                            className="mr-4"
                            width={80}
                            src={require("../../../../assets/images/logo/mastercard.png").default}
                            alt="Master Card"
                          />
                          <img
                            width={80}
                            src={require("../../../../assets/images/logo/visa.png").default}
                            alt="Visa"
                          />
                        </MDBCol>
                      )}
                    </MDBRow>
                  )}
                </ModalBody>
                {!calculationSelector.loading && (
                  <ModalFooter>
                    <MDBBtn
                      outline
                      color="blue-grey"
                      onClick={() => {
                        dispatch(feedbackActions.showFeedbackModal());
                        dispatch(calculationActions.hideCalculationModal());
                      }}>
                      ÇIKIŞ
                    </MDBBtn>
                    {isNil(calculationSelector.error) && (
                      <MDBBtn
                        color="primary"
                        onClick={() => {
                          dispatch(calculationActions.hideCalculationModal());
                          event("approve_calculation", "license_request");
                          history.push(
                            props.calculationRedirectUrl || "/portal/kullanicibilgileri",
                            {
                              data: Object.assign({}, props.form, calculationSelector.data.price),
                            },
                          );
                        }}>
                        ONAYLA
                      </MDBBtn>
                    )}

                    {isMobile && (
                      <MDBCol md="12" className="text-center">
                        <hr />
                        <h6 className="text-muted" style={{ fontWeight: 400 }}>
                          Lisans bedelini kredi kartınızla <br />
                          <b>taksit</b>
                          <br /> seçenekleri ile ödeyebilirsiniz.
                        </h6>

                        <img
                          className="mr-2"
                          width={60}
                          src={require("../../../../assets/images/logo/mastercard.png").default}
                          alt="Master Card"
                        />
                        <img
                          className="mr-2"
                          width={60}
                          src={require("../../../../assets/images/logo/visa.png").default}
                          alt="Visa"
                        />

                        <img
                          width={80}
                          src={require("../../../../assets/images/guvenlialisveris.png").default}
                          alt="Güvenli Alışveriş"
                        />
                      </MDBCol>
                    )}
                  </ModalFooter>
                )}
              </Modal>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>

      <FeedbackModal />
    </>
  );
}
