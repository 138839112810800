import React, { useState } from "react";
import ReactSelect from "react-select";
import { MDBInput, MDBCol } from "mdbreact";

export default function HotemForm({ onTextChange, onSelectChange, onCheckboxChange }) {
  const [starCount, setStarCount] = useState(null);
  return (
    <>
      <MDBCol lg="12" md="12" sm="12" xs="12">
        <MDBInput
          containerClass="pl-0"
          label="Oteliniz Belediye Belgeli Mi?"
          type="checkbox"
          name="isMunicipalCertificate"
          id="isMunicipalCertificate"
          onChange={e => {
            if (e.target.checked) {
              setStarCount({ label: "BELEDİYE BELGELİ", value: 6 });
              onSelectChange({ label: "BELEDİYE BELGELİ", value: 6 }, { name: "starCount" });
            } else {
              setStarCount(null);
              onSelectChange({ value: null }, { name: "starCount" });
            }
            onCheckboxChange(e);
          }}
        />
      </MDBCol>
      <MDBCol lg="12" md="12" sm="12" xs="12" className="mb-3">
        <MDBInput
          containerClass="pl-0"
          label="Oteliniz Sezonluk Mu? (6 aydan daha az süre faaliyette)"
          type="checkbox"
          name="isSeasonal"
          id="isSeasonal"
          onChange={onCheckboxChange}
        />
      </MDBCol>
      <MDBCol lg="12" md="12" sm="12" xs="12">
        <label htmlFor="starCount">Yıldız Sayısı</label>
        <ReactSelect
          id="starCount"
          name="starCount"
          placeholder="Yıldız Seçiniz"
          options={[
            { label: "BELEDİYE BELGELİ", value: 6 },
            { label: "1 YILDIZ / PANSİYON", value: 1 },
            { label: "2 YILDIZ", value: 2 },
            { label: "3 YILDIZ", value: 3 },
            { label: "4 YILDIZ", value: 4 },
            { label: "5 YILDIZ / 1. SINIF TATİL KÖYÜ", value: 5 },
          ]}
          onChange={(option, action) => {
            setStarCount(option);
            onSelectChange(option, action);
          }}
          isSearchable
          value={starCount}
        />
      </MDBCol>
      <MDBCol lg="12" md="12" sm="12" xs="12">
        <MDBInput
          type="text"
          name="roomCount"
          label="Oteliniz Oda Sayısı"
          group
          onChange={onTextChange}
        />
      </MDBCol>
    </>
  );
}
