import PropTypes from "prop-types";
import React from "react";

import "./site.css";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { Link } from "react-router-dom";

const SiteFooter = ({ siteTitle, siteDescription }) => (
  <footer className="ftco-footer ftco-section">
    <MDBContainer>
      <MDBRow className="mb-5">
        <MDBCol md="6" lg="6">
          <div className="ftco-footer-widget mb-4">
            <h2 className="ftco-heading-2">{siteTitle}</h2>
            <p>{siteDescription}</p>
          </div>
        </MDBCol>
        <MDBCol md="6" lg="6">
          <div className="ftco-footer-widget mb-4">
            <h2 className="ftco-heading-2">Bilgilendirme</h2>
            <ul className="list-unstyled open-hours">
              <li className="d-flex">
                <Link to="/anasayfa/hakkimizda">Hakkımızda</Link>
              </li>
              <li className="d-flex">
                <Link to="/gizlilik/kisisel-verilerin-korunmasi-ve-islenmesi-politikasi">
                  Kişisel Verilerin Korunması ve İşlenmesi Politikası
                </Link>
              </li>
              <li className="d-flex">
                <Link to="/gizlilik/kisisel-verilerin-korunmasi-ve-islenmesi-politikasi?sekme=kullanim-kosullari">
                  <span>Kullanım Koşulları</span>
                </Link>
              </li>
              <Link to="/gizlilik/kisisel-verilerin-korunmasi-ve-islenmesi-politikasi?sekme=cerez-politikasi">
                <span>Çerez Politikası</span>
              </Link>
              <li className="d-flex">
                <Link to="/anasayfa/iletisim">İletişim</Link>
              </li>
            </ul>
          </div>
        </MDBCol>
        <MDBCol md="12">
          <p>
            Kültür ve Turizm Bakanlığı'nın desteği ile hazırlanan bu projenin içeriği Kültür ve
            Turizm Bakanlığı'nın görüş ya da tutumunu yansıtmamakta olup, içerik ile ilgili
            sorumluluk (destek alan) Bağlantılı Hak Sahibi Fonogram Yapımcıları Meslek Birliği
            (MÜ-YAP)'ne aittir.
          </p>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </footer>
);

SiteFooter.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
};

SiteFooter.defaultProps = {
  siteTitle: "",
  siteDescription: "",
};

export default React.memo(SiteFooter);
