import { FETCH_LOCATION_FAIL, FETCH_LOCATION_START, FETCH_LOCATION_SUCCESS } from "./types";
import updateObject from "../../lib/UpdateObject";

const initialState = {
  location: {
    loading: false,
    error: null,
    data: {
      cityText: "",
      provinceText: "",
      cityId: "",
      provinceId: "",
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // LOCATION
    case FETCH_LOCATION_START:
      return updateObject(state, {
        location: updateObject(state.location, { loading: true, error: false }),
      });
    case FETCH_LOCATION_SUCCESS:
      return updateObject(state, {
        location: updateObject(state.location, {
          data: updateObject(state.location.data, action.payload),
          loading: false,
          error: null,
        }),
      });
    case FETCH_LOCATION_FAIL:
      return updateObject(state, {
        location: updateObject(state.location, { loading: false, error: action.payload }),
      });
    default:
      return state;
  }
};

export default reducer;
