import { MDBRow, MDBCol } from "mdbreact";
import React from "react";

function TimelineElement(props) {
  return (
    <MDBRow
      className={` timeline-element ${props.right ? "" : "reverse"} ${
        props.last ? "" : "separline"
      }`}>
      <MDBCol
        xs="12"
        md="6"
        className={`ftco-animate timeline-date-panel ${props.right ? "text-right" : "text-left"}`}>
        <div className="time-line-date-content">
          <p
            className="mbr-timeline-date display-5"
            style={{ background: "#1da1d7", color: "white" }}>
            {props.date}
          </p>
        </div>
      </MDBCol>
      <span className="ftco-animate iconBackground"></span>
      <MDBCol xs="12" md="6" className={`ftco-animate ${props.right ? "text-left" : "text-right"}`}>
        <div className="timeline-text-content">
          <h4 className="mbr-timeline-title pb-3 display-5" style={{ color: "#153F59" }}>
            {props.title}
          </h4>
          <p className="mbr-timeline-text display-7">{props.description}</p>
        </div>
      </MDBCol>
    </MDBRow>
  );
}

export default TimelineElement;
