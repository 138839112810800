import OwlCarousel from "react-owl-carousel";
import PropTypes from "prop-types";
import React from "react";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./slider.css";

const HomeSlider = ({ children }) => (
  <OwlCarousel
    center
    loop
    autoplay
    animateIn="fadeIn"
    animateOut="fadeOut"
    items={1}
    stagePadding={0}
    margin={0}
    nav={true}
    dots={false}
    autoplayHoverPause={false}
    navText={[
      "<span class='ion-ios-arrow-back'></span>",
      "<span class='ion-ios-arrow-forward'></span>",
    ]}
    responsive={{
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    }}
    className="home-slider owl-carousel js-fullheight">
    {children}
  </OwlCarousel>
);

HomeSlider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HomeSlider;
