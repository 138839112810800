import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import AppRoute from "./components/AppRoute";
import { SiteLayout } from "./components/Layout";
import RouterLoadingFallback from "./components/RouterLoadingFallback";
import About from "./pages/About";
import ApplicationProcess from "./pages/ApplicationProcess";
import Contact from "./pages/Contact";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import IsValidLicense from "./pages/IsValidLicense";
import Landing from "./pages/Landing";
import LicenseRequest from "./pages/LicenseRequest";
import Login from "./pages/Login/Login";
import MusicLicense from "./pages/MusicLicense";
import NotFound from "./pages/NotFound";
import { PrivacyStatement, ProtectionOfPersonalData } from "./pages/Privacy";
import SSS from "./pages/SSS";
import BakanlikYazisi from "./pages/SSS/BakanlikYazi";
import Tarrifs from "./pages/Tarrifs";

const UserInformation = React.lazy(() => import("./pages/UserInformation"));

const UnauthenticatedRoutes = () => (
  <Switch>
    <AppRoute path="/" exact component={Landing} />
    <AppRoute path="/giris" exact component={Login} />
    <AppRoute path="/giris/sifremi-unuttum" exact component={ForgotPassword} />
    <AppRoute path="/portal/kullanicibilgileri" exact component={UserInformation} />
    <AppRoute path="/portal/lisans-kontrolu/:id" exact component={IsValidLicense} />
    <AppRoute path="/anasayfa" exact layout={SiteLayout} component={Home} />
    <AppRoute path="/anasayfa/hakkimizda" exact layout={SiteLayout} component={About} />
    <AppRoute path="/anasayfa/iletisim" exact layout={SiteLayout} component={Contact} />
    <AppRoute path="/anasayfa/lisans-al" exact layout={SiteLayout} component={LicenseRequest} />
    <AppRoute path="/anasayfa/sikca-sorulan-sorular" exact layout={SiteLayout} component={SSS} />
    <AppRoute
      path="/anasayfa/sikca-sorulan-sorular/bakanlik-yazisi"
      exact
      layout={SiteLayout}
      component={BakanlikYazisi}
    />
    <AppRoute path="/anasayfa/tarifeler" exact layout={SiteLayout} component={Tarrifs} />
    <AppRoute path="/anasayfa/muzik-lisansi" exact layout={SiteLayout} component={MusicLicense} />
    <AppRoute
      path="/anasayfa/basvuru-sureci"
      exact
      layout={SiteLayout}
      component={ApplicationProcess}
    />
    <AppRoute
      path="/gizlilik/kisisel-verilerin-korunmasi-ve-islenmesi-politikasi"
      layout={SiteLayout}
      component={ProtectionOfPersonalData}
      exact
    />
    <AppRoute path="/gizlilik/gizlilik-beyani" layout={SiteLayout} component={PrivacyStatement} />
    <AppRoute component={NotFound} />
  </Switch>
);

export default function AppRoutes() {
  return (
    <Suspense fallback={<RouterLoadingFallback />}>
      <Switch>
        <UnauthenticatedRoutes />
      </Switch>
    </Suspense>
  );
}
