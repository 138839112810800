import updateObject from "../../lib/UpdateObject";

import {
  // CALCULATE
  CALCULATE_FAIL,
  CALCULATE_START,
  CALCULATE_SUCCESS,

  // UI
  CALCULATE_SHOW_MODAL,
  CALCULATE_HIDE_MODAL,
  SEASONAL_INFO_SHOW_MODAL,
  SEASONAL_INFO_HIDE_MODAL,
} from "./types";

const initialState = {
  calculation: {
    loading: false,
    error: null,
    data: {
      price: {
        tariff: {
          muyap: 0,
          muyorbir: 0,
          total: 0,
        },
        remainingDay: {
          muyap: 0,
          muyorbir: 0,
          total: 0,
        },
        discount: {
          muyap: 0,
          muyorbir: 0,
          total: 0,
        },
        discounted: {
          muyap: 0,
          muyorbir: 0,
          total: 0,
        },
        tax: 0,
        total: 0,
      },
      discounts: [],
    },
  },
  ui: {
    showModal: false,
    showSeasonalInfoModal: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // UI
    case CALCULATE_SHOW_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { showModal: true }),
      });
    case CALCULATE_HIDE_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { showModal: false }),
      });
    case SEASONAL_INFO_SHOW_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { showSeasonalInfoModal: true }),
      });
    case SEASONAL_INFO_HIDE_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { showSeasonalInfoModal: false }),
      });

    // CALCULATE
    case CALCULATE_START:
      return updateObject(state, {
        calculation: updateObject(state.calculation, { loading: true, error: false }),
      });
    case CALCULATE_SUCCESS:
      return updateObject(state, {
        calculation: updateObject(state.calculation, {
          data: updateObject(state.calculation.data, action.payload),
          loading: false,
          error: null,
        }),
      });
    case CALCULATE_FAIL:
      return updateObject(state, {
        calculation: updateObject(state.calculation, { loading: false, error: action.payload }),
      });

    default:
      return state;
  }
};

export default reducer;
