import React from "react";
import PropTypes from "prop-types";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

function HomeSliderItem(props) {
  return (
    <div
      className="slider-item js-fullheight"
      style={{ backgroundImage: `url(${props.imagePath})` }}>
      <div className="overlay"></div>
      <MDBContainer>
        <MDBRow
          className="slider-text js-fullheight justify-content-center align-items-center"
          data-scrollax-parent="true">
          <MDBCol md="12" sm="12" className="text-center ftco-animate">
            <span className="subheading" style={{ color: "white", letterSpacing: "3px" }}>
              {props.title}
            </span>
            <h3 className="h3 mb-4 mt-3" style={{ fontWeight: 200 }}>
              {props.description}
            </h3>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

HomeSliderItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

HomeSliderItem.defaultProps = {
  title: "Online Lisanslama",
  description: "Müziğin sesini açın. Çünkü artık yasal müzik kullanıyorsunuz",
};

export default HomeSliderItem;
