import useWindowDimensions from "../../hooks/useWindowDimensions";
import LandingLargeWindow from "./components/LandingLargeWindow";
import LandingMediumWindow from "./components/LandingMediumWindow";
import LandingMobileWindow from "./components/LandingMobileWindow";
import LandingTabletWindow from "./components/LandingTabletWindow";
import "./landing.css";

const Landing = () => {
  const { width } = useWindowDimensions();

  if (width >= 1700) {
    return <LandingLargeWindow />;
  } else if (width >= 1400) {
    return <LandingMediumWindow />;
  } else if (width >= 1200) {
    return <LandingTabletWindow />;
  } else {
    return <LandingMobileWindow />;
  }
};

export default Landing;
