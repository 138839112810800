export const ADD_LICENSE_START = "ADD_LICENSE_START";
export const ADD_LICENSE_SUCCESS = "ADD_LICENSE_SUCCESS";
export const ADD_LICENSE_FAIL = "ADD_LICENSE_FAIL";

export const NEW_LICENSE_SHOW_MODAL = "NEW_LICENSE_SHOW_MODAL";
export const NEW_LICENSE_HIDE_MODAL = "NEW_LICENSE_HIDE_MODAL";

export const IS_VALID_LICENSE_SUCCESS = "IS_VALID_LICENSE_SUCCESS";
export const IS_VALID_LICENSE_FAIL = "IS_VALID_LICENSE_FAIL";
export const IS_VALID_LICENSE_START = "IS_VALID_LICENSE_START";
export const RESET_IS_VALID_LICENSE = "RESET_IS_VALID_LICENSE";
