export const ADD_COMPANY_START = "ADD_COMPANY_START";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL";

export const ADD_COMPANY_SHOW_MODAL = "ADD_COMPANY_SHOW_MODAL";
export const ADD_COMPANY_HIDE_MODAL = "ADD_COMPANY_HIDE_MODAL";

export const ADD_COMPANY_PLANT_LICENSE_START = "ADD_COMPANY_PLANT_LICENSE_START";
export const ADD_COMPANY_PLANT_LICENSE_SUCCESS = "ADD_COMPANY_PLANT_LICENSE_SUCCESS";
export const ADD_COMPANY_PLANT_LICENSE_FAIL = "ADD_COMPANY_PLANT_LICENSE_FAIL";

export const ADD_PLANT_LICENSE_START = "ADD_PLANT_LICENSE_START";
export const ADD_PLANT_LICENSE_SUCCESS = "ADD_PLANT_LICENSE_SUCCESS";
export const ADD_PLANT_LICENSE_FAIL = "ADD_PLANT_LICENSE_FAIL";

export const ADD_COMPANY_LICENSE_START = "ADD_COMPANY_LICENSE_START";
export const ADD_COMPANY_LICENSE_SUCCESS = "ADD_COMPANY_LICENSE_SUCCESS";
export const ADD_COMPANY_LICENSE_FAIL = "ADD_COMPANY_LICENSE_FAIL";
