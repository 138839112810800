import axios from "../../lib/publicAxios";
import {
  // FETCH ALL BY CITY
  FETCH_PROVINCES_BY_CITY_FAIL,
  FETCH_PROVINCES_BY_CITY_START,
  FETCH_PROVINCES_BY_CITY_SUCCESS,

  // FETCH BY ID
  FETCH_PROVINCE_BY_ID_FAIL,
  FETCH_PROVINCE_BY_ID_SUCCESS,
  FETCH_PROVINCE_BY_ID_START,
} from "./types";

/**
 * @name fetchProvinceById
 * @description Belirli bir ilçenin detaylarını çeker
 *
 * @param {ObjectId-string} provinceId - İlçenin idsi
 */
export const fetchProvinceById = provinceId => {
  return dispatch => {
    dispatch({ type: FETCH_PROVINCE_BY_ID_START });

    axios
      .get(`/province/${provinceId}`)
      .then(res => {
        const province = res.data;
        dispatch({ type: FETCH_PROVINCE_BY_ID_SUCCESS, payload: province });
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          dispatch({
            type: FETCH_PROVINCE_BY_ID_FAIL,
            payload: err.response.data.message,
          });
        } else if (err.message) {
          dispatch({
            type: FETCH_PROVINCE_BY_ID_FAIL,
            payload: err.message,
          });
        } else {
          dispatch({
            type: FETCH_PROVINCE_BY_ID_FAIL,
            payload: err,
          });
        }
      });
  };
};

/**
 * @name fetchProvincesByCity
 * @description Belirli bir ilin ilçelerini çeker
 *
 * @param {ObjectId-string} cityId - İlin idsi
 */
export const fetchProvincesByCity = cityId => {
  return dispatch => {
    dispatch({
      type: FETCH_PROVINCES_BY_CITY_START,
    });

    axios
      .get(`/city/${cityId}/provinces`)
      .then(res => {
        dispatch({ type: FETCH_PROVINCES_BY_CITY_SUCCESS, payload: res.data.records });
      })
      .catch(err => {
        dispatch({
          type: FETCH_PROVINCES_BY_CITY_FAIL,
          error: err,
        });
      });
  };
};
