export default function whatIsCompanyTypeForm(companyTypeAlias) {
  switch (companyTypeAlias) {
    case "lokanta":
    case "beautycenter":
    case "mall":
    case "market":
    case "carpark":
    case "shop":
    case "weddinghall":
    case "bar":
    case "coffeeshop":
    case "caferestaurantbar":
    case "restaurant":
    case "restaurantwithalcohol":
    case "coffeeshopwithalcohol":
    case "gym":
      return "squareMeter";

    case "tearoom":
      return "daily";

    case "patisserie":
    case "hairdresser":
      return "dailywithsquaremeter";

    case "hotel":
      return "hotel";

    case "hospital":
      return "hospital";
    default:
      return "";
  }
}
