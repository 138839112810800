const toSelectData = (rawData, valueFieldName, labelFieldName) => {
  return rawData.map((item) => {
    return {
      label: item[labelFieldName],
      value: item[valueFieldName],
    };
  });
};

export default toSelectData;
