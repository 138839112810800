import axios from "../../lib/publicAxios";
import { OTP_VERIFY_FAIL, OTP_VERIFY_START, OTP_VERIFY_SUCCESS, OTP_VERIFY_RESET } from "./types";

/**
 * @name resetOtpVerify
 * @description OTP state verisini resetler
 */

export const resetOtpVerify = () => {
  return (dispatch) => {
    dispatch({ type: OTP_VERIFY_RESET });
  };
};

/**
 * @name otpVerify
 * @description Bir OTP isteğini doğrular
 *
 * @param {number} pin
 * @param {ObjectId-string} smsReference
 * @param {number} mobileNumber
 */

export function otpVerify(pin, mobileNumber, successCallback) {
  return (dispatch, getState) => {
    const csrfToken = getState().auth.csrf.data;
    dispatch({
      type: OTP_VERIFY_START,
    });

    if (pin && pin.toString().length === 6) {
      axios
        .post(
          "/auth/verify",
          {
            pin: pin.trim(),
            mobileNumber,
          },
          {
            withCredentials: true,
            headers: {
              "CSRF-TOKEN": csrfToken,
            },
          },
        )
        .then((response) => {
          dispatch({ type: OTP_VERIFY_SUCCESS, payload: response.data });
          successCallback(response.data);
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            try {
              const message = JSON.parse(err.response.data.message);
              dispatch({ type: OTP_VERIFY_FAIL, payload: message });
            } catch {
              dispatch({ type: OTP_VERIFY_FAIL, payload: err.response.data });
            }
          } else if (err.message) {
            dispatch({
              type: OTP_VERIFY_FAIL,
              payload: err.message,
            });
          } else {
            dispatch({
              type: OTP_VERIFY_FAIL,
              payload: {
                message: err,
                code: 400,
              },
            });
          }
        });
    } else {
      dispatch({
        type: OTP_VERIFY_FAIL,
        payload: "Kodunuzu kontrol ediniz",
      });
    }
  };
}
