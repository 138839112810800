import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import React from "react";

function TextWithHeader({ title, children, bgLight }) {
  const className = bgLight ? "ftco-section bg-light" : "ftco-section";
  return (
    <section className={className}>
      <MDBContainer>
        <MDBRow className="no-gutters justify-content-center">
          <MDBCol md="12" className="text-center heading-section ftco-animate">
            <h2 className="mb-4">{title}</h2>
            {children}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}

export default TextWithHeader;
