import updateObject from "../../lib/UpdateObject";
import {
  // REGISTER
  REGISTER_FAIL,
  REGISTER_START,
  REGISTER_SUCCESS,

  // AUTH
  AUTH_FAIL,
  AUTH_LOGOUT,
  AUTH_START,
  AUTH_SUCCESS,

  // SET REDIRECT PATH
  SET_AUTH_REDIRECT_PATH,

  // SET IS AUTHENTICATED
  SET_IS_AUTHENTICATED,

  // FORGOT PASSWORD
  FORGOT_PASSWORD_OTP_START,
  FORGOT_PASSWORD_OTP_SUCCESS,
  FORGOT_PASSWORD_OTP_FAIL,

  // RESET PASSWORD
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,

  // CHECK AUTH STATE
  CHECK_AUTH_START,
  CHECK_AUTH_FAIL,
  CHECK_AUTH_SUCCESS,

  //  CSRF
  FETCH_CSRF_FAIL,
  FETCH_CSRF_START,
  FETCH_CSRF_SUCCESS,
} from "./types";

const initialState = {
  auth: {
    loading: false,
    error: null,
    token: null,
    expiresAt: null,
    userInfo: {},
  },
  check: {
    loading: false,
    error: null,
  },
  register: {
    loading: false,
    error: null,
    token: null,
    expiresAt: null,
    userInfo: {},
    smsReference: null,
  },
  login: {
    accessToken: null,
    accessTokenExpires: null,
    userId: null,
    error: null,
    loading: false,
  },
  forgotPassword: {
    smsReference: null,
    loading: false,
    error: null,
  },
  resetPassword: {
    result: null,
    loading: false,
    error: null,
  },
  csrf: {
    data: null,
    loading: false,
    error: null,
  },
  isAuthenticated: false,
  authRedirectPath: "/portal/dashboard",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // REGISTER
    case REGISTER_START:
      return updateObject(state, {
        register: updateObject(state.register, { loading: true, error: null }),
      });
    case REGISTER_SUCCESS:
      return updateObject(state, {
        register: updateObject(state.register, {
          loading: false,
          error: null,
          smsReference: action.payload.smsReference,
          token: action.payload.token,
          expiresAt: action.payload.expiresAt,
          userInfo: action.payload.user,
        }),
      });
    case REGISTER_FAIL:
      return updateObject(state, {
        register: updateObject(state.register, { loading: false, error: action.payload }),
      });

    // FORGOT PASSWROD
    case FORGOT_PASSWORD_OTP_START:
      return updateObject(state, {
        forgotPassword: updateObject(state.forgotPassword, { loading: true, error: null }),
      });
    case FORGOT_PASSWORD_OTP_SUCCESS:
      return updateObject(state, {
        forgotPassword: updateObject(state.forgotPassword, {
          smsReference: action.payload,
          loading: false,
          error: null,
        }),
      });
    case FORGOT_PASSWORD_OTP_FAIL:
      return updateObject(state, {
        forgotPassword: updateObject(state.forgotPassword, {
          loading: false,
          error: action.payload,
        }),
      });

    // RESET PASSWORD
    case RESET_PASSWORD_START:
      return updateObject(state, {
        resetPassword: updateObject(state.resetPassword, { loading: true, error: null }),
      });
    case RESET_PASSWORD_SUCCESS:
      return updateObject(state, {
        resetPassword: updateObject(state.resetPassword, {
          result: action.payload,
          loading: false,
          error: null,
        }),
      });
    case RESET_PASSWORD_FAIL:
      return updateObject(state, {
        resetPassword: updateObject(state.resetPassword, { loading: false, error: action.payload }),
      });

    // SET REDIRECT PATH
    case SET_AUTH_REDIRECT_PATH:
      return updateObject(state, { authRedirectPath: action.payload });

    // SET IS AUTHENTICATED
    case SET_IS_AUTHENTICATED:
      return updateObject(state, { isAuthenticated: action.payload });

    // AUTH
    case AUTH_START:
      return updateObject(state, {
        auth: updateObject(state.auth, {
          loading: true,
          error: null,
        }),
      });
    case AUTH_SUCCESS:
      return updateObject(state, {
        auth: updateObject(state.auth, {
          loading: false,
          error: null,
          ...action.payload,
        }),
        isAuthenticated: true,
      });
    case AUTH_FAIL:
      return updateObject(state, {
        auth: updateObject(state.auth, {
          loading: false,
          error: action.payload,
        }),
      });
    case AUTH_LOGOUT:
      return updateObject(state, {
        auth: updateObject(state.auth, {
          loading: false,
          error: null,
          token: null,
          expiresAt: null,
          userInfo: {},
        }),
        isAuthenticated: false,
      });

    // CHECK AUTH STATE
    case CHECK_AUTH_FAIL:
      return updateObject(state, {
        check: updateObject(state.check, {
          loading: false,
          error: action.payload,
        }),
      });
    case CHECK_AUTH_START:
      return updateObject(state, {
        check: updateObject(state.check, {
          loading: true,
          error: null,
        }),
      });
    case CHECK_AUTH_SUCCESS:
      return updateObject(state, {
        check: updateObject(state.check, { loading: false, error: null }),
      });

    case FETCH_CSRF_FAIL:
      return updateObject(state, {
        csrf: updateObject(state.csrf, {
          loading: false,
          error: action.payload,
        }),
      });
    case FETCH_CSRF_START:
      return updateObject(state, {
        csrf: updateObject(state.csrf, {
          loading: true,
          error: null,
        }),
      });
    case FETCH_CSRF_SUCCESS:
      return updateObject(state, {
        csrf: updateObject(state.csrf, { loading: false, error: null, data: action.payload }),
      });

    default:
      return state;
  }
};

export default reducer;
