import { MDBBtn, MDBCol, MDBModalBody, MDBModalFooter, MDBRow } from "mdbreact";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "reactstrap";

import { ClarificationTermText } from "../PrivacyTexts";
import * as privacyActions from "../../redux/privacy/actions";

function ClarificationTermModal() {
  const dispatch = useDispatch();
  const privacyUiState = useSelector(state => state.privacy.ui);

  const toggleModal = () => {
    const status = !privacyUiState.isClaritifactionTermModalOpen;
    status
      ? dispatch(privacyActions.showClarificationTermModal())
      : dispatch(privacyActions.hideClarificationTermModal());
  };

  useEffect(() => {
    return () => {
      if (privacyUiState.isClaritifactionTermModalOpen === true)
        dispatch(privacyActions.hideClarificationTermModal());
    };
  }, [dispatch, privacyUiState.isClaritifactionTermModalOpen]);

  return (
    <Modal
      size="lg"
      keyboard={false}
      backdrop="static"
      scrollable
      isOpen={privacyUiState.isClaritifactionTermModalOpen}
      toggle={toggleModal}>
      <MDBModalBody>
        <MDBRow>
          <MDBCol md="12" className="p-4 text-justify">
            <ClarificationTermText />
          </MDBCol>
        </MDBRow>
      </MDBModalBody>
      <MDBModalFooter className="justify-content-center align-items-center">
        <MDBBtn color="blue-grey" onClick={toggleModal}>
          AYDINLATMA METNİNİ OKUDUM VE ONAYLIYORUM
        </MDBBtn>
      </MDBModalFooter>
    </Modal>
  );
}

export default React.memo(ClarificationTermModal);
