import React from "react";
import PropTypes from "prop-types";

function Logo(props) {
  return (
    <div className="featured-menus ftco-animate">
      <div className="menu-img img" style={{ backgroundImage: `url(${props.imagePath})` }}></div>
      <div className="text text-center">
        <h3>{props.name}</h3>
      </div>
      <div className="text text-center mt-1">
        <h3>{props.subText}</h3>
      </div>
    </div>
  );
}

Logo.defaultProps = {
  imagePath: "",
  name: "",
  subText: "",
};

Logo.propTypes = {
  imagePath: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  subText: PropTypes.string,
};

export default Logo;
