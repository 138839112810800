import axios from "../../lib/publicAxios";

import {
  // FETCH CITIES
  FETCH_CITIES_START,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAIL,

  // FETCH CITY BY ID
  FETCH_CITY_BY_ID_START,
  FETCH_CITY_BY_ID_FAIL,
  FETCH_CITY_BY_ID_SUCCESS,
} from "./types";

/**
 * FETCH ALL CITIES
 */

export const fetchCities = () => {
  return dispatch => {
    dispatch({
      type: FETCH_CITIES_START,
    });

    axios
      .get(`/city/?pageSize=100&page=1&orderBy=[{ "name": "asc"}]`)
      .then(res => {
        const fetchedData = res.data.records;
        dispatch({ type: FETCH_CITIES_SUCCESS, payload: fetchedData });
      })
      .catch(err => {
        dispatch({
          type: FETCH_CITIES_FAIL,
          payload: err,
        });
      });
  };
};

/**
 * FETCH CITY BY ID
 */

export const fetchCityById = cityId => {
  return dispatch => {
    dispatch({
      type: FETCH_CITY_BY_ID_START,
    });

    axios
      .get(`/city/${cityId}`)
      .then(res => {
        dispatch({ type: FETCH_CITY_BY_ID_SUCCESS, payload: res.data });
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          dispatch({
            type: FETCH_CITY_BY_ID_FAIL,
            payload: err.response.data.message,
          });
        } else if (err.message) {
          dispatch({
            type: FETCH_CITY_BY_ID_FAIL,
            payload: err.message,
          });
        } else {
          dispatch({
            type: FETCH_CITY_BY_ID_FAIL,
            payload: err,
          });
        }
      });
  };
};
