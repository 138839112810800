import React from "react";
import { MDBBtn } from "mdbreact";

/**
 * Stats Component - to illustrate the possible functions * Could be used for nav buttons or overview
 */
const Stats = ({
  currentStep,
  firstStep,
  goToStep,
  lastStep,
  nextStep,
  previousStep,
  totalSteps,
  step,
}) => (
  <div>
    <hr />
    {step > 1 && (
      <MDBBtn outline color="blue-grey" block className="py-3 px-5 mb-3" onClick={previousStep}>
        <i className="fas fa-angle-left"></i> Önceki Adıma Dönün
      </MDBBtn>
    )}
    {step < totalSteps ? (
      <MDBBtn color="primary" block className="py-3 px-5 mb-3" onClick={nextStep}>
        Sonraki Adıma Geçin <i className="fas fa-angle-right"></i>
      </MDBBtn>
    ) : (
      <MDBBtn color="primary" block className="py-3 px-5" onClick={nextStep}>
        <i className="fas fa-check"></i> Tamamla
      </MDBBtn>
    )}

    {/*     {step > 1 && (
      <MDBBtn outline color="blue-grey" block className="py-3 px-5 mb-2" onClick={previousStep}>
        <i className="fas fa-angle-left"></i> Önceki Adıma Dönün
      </MDBBtn>
    )}
    {step < totalSteps ? (
      <MDBBtn color="primary" block className="py-3 px-5" onClick={nextStep}>
        Sonraki Adıma Geçin <i className="fas fa-angle-right"></i>
      </MDBBtn>
    ) : (
      <MDBBtn color="primary" block className="py-3 px-5" onClick={nextStep}>
        <i className="fas fa-check"></i> Tamamla
      </MDBBtn>
    )} */}
  </div>
);

export default Stats;
