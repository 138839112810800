import PropTypes from "prop-types";
import React from "react";

import "./pagewrap.css";
import PageWrapImg from "../../assets/images/page-wrap.jpg";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

function PageWrap(props) {
  return (
    <section
      className="hero-wrap hero-wrap-2"
      style={{ backgroundImage: `url(${props.image})` }}
      data-stellar-background-ratio="0.5">
      <div className="overlay"></div>
      <MDBContainer>
        <MDBRow className="no-gutters slider-text align-items-end justify-content-center">
          <MDBCol md="9" className="ftco-animate text-center mb-4">
            <h1 className="mb-2 bread">{props.title}</h1>
            <p className="breadcrumbs">
              <span className="mr-2">
                {props.homeText} <i className="ion-ios-arrow-forward"></i>
              </span>{" "}
              <span>
                {props.pageName} <i className="ion-ios-arrow-forward"></i>
              </span>
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}

PageWrap.defaultProps = {
  title: "",
  image: PageWrapImg,
};

PageWrap.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default PageWrap;
