import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import React from "react";

import "./timeline.css";

function Timeline(props) {
  return (
    <section className="ftco-section timeline1 lisanslama-timeline">
      <MDBContainer>
        <MDBRow className="no-gutters justify-content-center mb-5 pb-2">
          <MDBCol md="12" className="text-center heading-section ftco-animate">
            <h2 className="mb-4">{props.title}</h2>
            {props.description ? <p>{props.description}</p> : null}
          </MDBCol>
        </MDBRow>
        <MDBContainer>{props.children}</MDBContainer>
      </MDBContainer>
    </section>
  );
}

export default Timeline;
