import AOS from "aos";
import React, { Component } from "react";

import "aos/dist/aos.css";

import { SiteTopNavigation } from "../TopNavigation";
import Footer from "../Footer";
import Header from "../Header";

import "../../assets/css/animate.css";
import "./site.css";

class SiteLayout extends Component {
  componentDidMount() {
    AOS.init({
      duration: 800,
      easing: "slide",
    });
    window.initApp();
  }

  componentDidUpdate() {
    AOS.refresh();
    window.initApp();
  }

  render() {
    return (
      <div className="site">
        <Header
          email="lisanslama@lisanslama.org"
          phoneNumber="+90 212 243 82 14"
          openDays="Pazartesi - Cuma"
          openHours="09:00 - 18:00"
        />
        <SiteTopNavigation siteTitle="Online Lisanslama" />
        <main>{this.props.children}</main>
        <Footer
          siteTitle="Online Lisanslama"
          siteDescription="MÜ-YAP meslek birliğinin oluşturduğu, kullanıcının müzik lisansına daha kolay ve tek elden ulaşmasını sağlayan bir organizasyondur. Online lisanslama vasıtasıyla MÜ-YAP'tan lisans almanız ya da lisans bedelini ayrı ayrı yatırmanıza gerek kalmamış ve çok daha kolay biçimde yasal müzik kullanımınız sağlanmıştır."
        />
      </div>
    );
  }
}

export default SiteLayout;
