export const FETCH_USER_LICENSES_START = "FETCH_USER_LICENSES_START";
export const FETCH_USER_LICENSES_SUCCESS = "FETCH_USER_LICENSES_SUCCESS";
export const FETCH_USER_LICENSES_FAIL = "FETCH_USER_LICENSES_FAIL";

export const FETCH_LICENSE_START = "FETCH_LICENSE_START";
export const FETCH_LICENSE_SUCCESS = "FETCH_LICENSE_SUCCESS";
export const FETCH_LICENSE_FAIL = "FETCH_LICENSE_FAIL";

export const FETCH_USER_COMPANIES_START = "FETCH_USER_COMPANIES_START";
export const FETCH_USER_COMPANIES_SUCCESS = "FETCH_USER_COMPANIES_SUCCESS";
export const FETCH_USER_COMPANIES_FAIL = "FETCH_USER_COMPANIES_FAIL";

export const FETCH_USER_PLANTS_START = "FETCH_USER_PLANTS_START";
export const FETCH_USER_PLANTS_SUCCESS = "FETCH_USER_PLANTS_SUCCESS";
export const FETCH_USER_PLANTS_FAIL = "FETCH_USER_PLANTS_FAIL";

export const DOWNLOAD_LICENSE_PDF_START = "DOWNLOAD_LICENSE_PDF_START";
export const DOWNLOAD_LICENSE_PDF_SUCCESS = "DOWNLOAD_LICENSE_PDF_SUCCESS";
export const DOWNLOAD_LICENSE_PDF_FAIL = "DOWNLOAD_LICENSE_PDF_FAIL";
