import { createClient } from "@google/maps";

const googleMapsClient = createClient({
  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
});
const findLocation = async function() {
  if (localStorage.getItem("geolocation")) {
    const geolocation = JSON.parse(localStorage.getItem("geolocation"));

    await googleMapsClient.reverseGeocode(
      {
        latlng: `${geolocation.lat},${geolocation.lng}`,
      },
      response => {
        console.log(response);
      },
    );
  }
};

export { findLocation };
