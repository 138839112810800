import updateObject from "../../lib/UpdateObject";
import {
  // ADD COMPANY
  ADD_COMPANY_FAIL,
  ADD_COMPANY_START,
  ADD_COMPANY_SUCCESS,

  // ADD COMPANY MODAL
  ADD_COMPANY_HIDE_MODAL,
  ADD_COMPANY_SHOW_MODAL,

  // ADD COMPANY PLANT LICENSE
  ADD_COMPANY_PLANT_LICENSE_FAIL,
  ADD_COMPANY_PLANT_LICENSE_START,
  ADD_COMPANY_PLANT_LICENSE_SUCCESS,

  // ADD PLANT LICENSE
  ADD_PLANT_LICENSE_FAIL,
  ADD_PLANT_LICENSE_START,
  ADD_PLANT_LICENSE_SUCCESS,

  //
  ADD_COMPANY_LICENSE_START,
  ADD_COMPANY_LICENSE_FAIL,
  ADD_COMPANY_LICENSE_SUCCESS,
} from "./types";

const initialState = {
  company: {
    data: {},
    loading: false,
    error: null,
  },
  companyPlantLicense: {
    data: {},
    loading: false,
    error: null,
  },
  plantLicense: {
    data: {},
    loading: false,
    error: null,
  },
  license: {
    data: {},
    loading: false,
    error: null,
  },
  ui: {
    newCompanyModalShow: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // ADD COMPANY
    case ADD_COMPANY_START:
      return updateObject(state, {
        company: updateObject(state.company, { loading: true, error: null }),
      });
    case ADD_COMPANY_SUCCESS:
      return updateObject(state, {
        company: updateObject(state.company, { loading: false, error: null, data: action.payload }),
      });
    case ADD_COMPANY_FAIL:
      return updateObject(state, {
        company: updateObject(state.company, { loading: false, error: action.payload }),
      });

    // ADD COMPANY PLANT LICENSE
    case ADD_COMPANY_PLANT_LICENSE_START:
      return updateObject(state, {
        companyPlantLicense: updateObject(state.companyPlantLicense, {
          loading: true,
          error: null,
        }),
      });
    case ADD_COMPANY_PLANT_LICENSE_SUCCESS:
      return updateObject(state, {
        companyPlantLicense: updateObject(state.companyPlantLicense, {
          loading: false,
          error: null,
          data: action.payload,
        }),
      });
    case ADD_COMPANY_PLANT_LICENSE_FAIL:
      return updateObject(state, {
        companyPlantLicense: updateObject(state.companyPlantLicense, {
          loading: false,
          error: action.payload,
        }),
      });

    // ADD PLANT LICENSE
    case ADD_PLANT_LICENSE_START:
      return updateObject(state, {
        plantLicense: updateObject(state.plantLicense, {
          loading: true,
          error: null,
        }),
      });
    case ADD_PLANT_LICENSE_SUCCESS:
      return updateObject(state, {
        plantLicense: updateObject(state.plantLicense, {
          loading: false,
          error: null,
          data: action.payload,
        }),
      });
    case ADD_PLANT_LICENSE_FAIL:
      return updateObject(state, {
        plantLicense: updateObject(state.plantLicense, {
          loading: false,
          error: action.payload,
        }),
      });

    // ADD LICENSE
    case ADD_COMPANY_LICENSE_START:
      return updateObject(state, {
        license: updateObject(state.license, {
          loading: true,
          error: null,
        }),
      });
    case ADD_COMPANY_LICENSE_SUCCESS:
      return updateObject(state, {
        license: updateObject(state.license, {
          loading: false,
          error: null,
          data: action.payload,
        }),
      });
    case ADD_COMPANY_LICENSE_FAIL:
      return updateObject(state, {
        license: updateObject(state.license, {
          loading: false,
          error: action.payload,
        }),
      });

    // UI
    case ADD_COMPANY_SHOW_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { newCompanyModalShow: true }),
      });
    case ADD_COMPANY_HIDE_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { newCompanyModalShow: false }),
      });

    default:
      return state;
  }
};

export default reducer;
