import PropTypes from "prop-types";
import React from "react";

import "./about.css";
import leftImage from "../../assets/images/about-left.jpg";
import rightImage from "../../assets/images/about-right.jpg";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

function About(props) {
  return (
    <section id="about" className="ftco-section ftco-wrap-about">
      <MDBContainer>
        <MDBRow>
          <MDBCol md="7" className="d-flex">
            <div
              className="img img-1"
              style={{ backgroundImage: `url(${props.rightImage})` }}></div>
          </MDBCol>
          <MDBCol md="5" className="wrap-about pt-5 pt-md-5 pb-md-3 ftco-animate">
            <div className="heading-section mb-4 my-5 my-md-0">
              <h2 className="mb-4">{props.title}</h2>
            </div>
            <p style={{ textAlign: "justify" }}>{props.description}</p>
            <p className="time">
              <span>
                <a href="#!" style={{ color: "#11a0d9" }}>
                  {props.phone}
                </a>
              </span>
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}

About.propTypes = {
  leftImage: PropTypes.string.isRequired,
  rightImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  openHours: PropTypes.string.isRequired,
  openDays: PropTypes.string.isRequired,
};

About.defaultProps = {
  leftImage,
  rightImage,
  title: "",
  description: "",
  phone: "",
  openDays: "",
  openHours: "",
};

export default About;
