import { MDBCol, MDBContainer, MDBRow, MDBSpinner } from "mdbreact";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import Lottie from "lottie-react";

import * as licenseActions from "../../redux/license/actions";
import downloadAnimation from "./download-animation-black.json";

function IsValidLicense() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const isValidState = useSelector((state) => state.license.isValid, shallowEqual);

  useEffect(() => {
    document.body.style.backgroundColor = null;
    dispatch(licenseActions.isValidLicense(match.params.id));

    return () => {
      document.body.style.backgroundColor = null;
      dispatch(licenseActions.resetIsValidLicense());
    };
  }, [dispatch, match.params.id]);

  let loadingComponent = null;
  let errorComponent = null;
  let contentComponent = null;

  if (isValidState.loading === true && !isValidState.error) {
    errorComponent = null;
    loadingComponent = <MDBSpinner big />;
  }
  if (isValidState.error) {
    loadingComponent = null;
    document.body.style.backgroundColor = "#BF0426";
    errorComponent = <h3 className="h3 h3-responsive text-white">{isValidState.error}</h3>;
  }

  if (!isValidState.loading && !isValidState.error && isValidState.data) {
    document.body.style.backgroundColor = "#72A603";
    contentComponent = (
      <>
        <Lottie
          animationData={downloadAnimation}
          loop
          alt="Lisans indirme animation"
          style={{
            height: 250,
          }}
        />
        <h3 className="h3 h3-responsive text-black">Lisans dosyanız indiriliyor..</h3>
      </>
    );
  }

  return (
    <div className="vh-100 site-blocks-cover d-flex align-items-center justify-content-center">
      <MDBContainer fluid>
        <MDBRow>
          <MDBCol className="vh-100 d-flex flex-column align-items-center justify-content-center">
            {loadingComponent}
            {errorComponent}
            {contentComponent}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

export default IsValidLicense;
