import updateObject from "../../lib/UpdateObject";

import {
  // FETCH ALL BY CITY
  FETCH_PROVINCES_BY_CITY_FAIL,
  FETCH_PROVINCES_BY_CITY_START,
  FETCH_PROVINCES_BY_CITY_SUCCESS,

  // FETCH BY ID
  FETCH_PROVINCE_BY_ID_FAIL,
  FETCH_PROVINCE_BY_ID_SUCCESS,
  FETCH_PROVINCE_BY_ID_START,
} from "./types";

const initialState = {
  provinces: {
    loading: false,
    error: null,
    data: [],
  },
  province: {
    loading: false,
    error: null,
    data: {},
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH ALL BY CITY
    case FETCH_PROVINCES_BY_CITY_START:
      return updateObject(state, {
        provinces: updateObject(state.provinces, { loading: true, error: null }),
      });
    case FETCH_PROVINCES_BY_CITY_SUCCESS:
      return updateObject(state, {
        provinces: updateObject(state.provinces, {
          loading: false,
          error: null,
          data: action.payload,
        }),
      });
    case FETCH_PROVINCES_BY_CITY_FAIL:
      return updateObject(state, {
        provinces: updateObject(state.provinces, { loading: false, error: action.payload }),
      });

    // FETCH BY ID
    case FETCH_PROVINCE_BY_ID_START:
      return updateObject(state, {
        province: updateObject(state.province, { loading: true, error: null }),
      });
    case FETCH_PROVINCE_BY_ID_SUCCESS:
      return updateObject(state, {
        province: updateObject(state.province, {
          loading: false,
          error: null,
          data: updateObject(state.province.data, action.payload),
        }),
      });
    case FETCH_PROVINCE_BY_ID_FAIL:
      return updateObject(state, {
        province: updateObject(state.province, { loading: false, error: action.payload }),
      });

    default:
      return state;
  }
};

export default reducer;
