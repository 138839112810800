import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import app from "./app/reducer";
import auth from "./auth/reducer";
import calculation from "./calculation/reducer";
import city from "./city/reducer";
import company from "./company/reducer";
import companytype from "./companytype/reducer";
import contractTerm from "./contracterm/reducer";
import feedback from "./feedback/reducer";
import license from "./license/reducer";
import location from "./location/reducer";
import otpRequest from "./otprequest/reducer";
import payment from "./payment/reducer";
import plant from "./plant/reducer";
import privacy from "./privacy/reducer";
import profile from "./profile/reducer";
import province from "./province/reducer";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    auth,
    calculation,
    city,
    company,
    companytype,
    contractTerm,
    feedback,
    license,
    location,
    otpRequest,
    payment,
    plant,
    privacy,
    profile,
    province,
  });

export default rootReducer;
