import React from "react";
import { MDBInput, MDBCol } from "mdbreact";

export default function PersonForm({ onTextChange, onCheckboxChange }) {
  return (
    <>
      <MDBCol lg="12" md="12" sm="12" xs="12">
        <MDBInput
          type="text"
          name="personCount"
          label="İşletmenize kayıtlı kişi sayısı"
          group
          onChange={onTextChange}
        />
      </MDBCol>
      <MDBCol lg="12" md="12" sm="12" xs="12">
        <MDBInput
          containerClass="pl-0"
          label="Sezonluk Mu? (6 aydan daha az süre faaliyette)"
          type="checkbox"
          name="isSeasonal"
          id="isSeasonal"
          onChange={onCheckboxChange}
        />
      </MDBCol>
    </>
  );
}
