import React from "react";
import PropTypes from "prop-types";
import v4 from "uuid/v4";

function LicensingAreaItem(props) {
  const items = props.items.map(i => {
    return (
      <React.Fragment key={v4()}>
        <span>{i}</span> <br />
      </React.Fragment>
    );
  });
  return (
    <div className={`${props.column} d-flex align-self-stretch`}>
      <div className="menus d-sm-flex ftco-animate align-items-stretch">
        <div
          className={props.right ? "menu-img img order-md-last" : "menu-img img"}
          style={{ backgroundImage: `url(${props.image})` }}></div>
        <div className="text d-flex align-items-center" style={{ background: "white" }}>
          <div>
            <div className="d-flex">
              <div className="one-half">
                <h3>{props.title}</h3>
              </div>
            </div>
            <p>{items}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

LicensingAreaItem.defaultProps = {
  right: false,
  column: "col-md-12 col-lg-6",
};

LicensingAreaItem.propTypes = {
  right: PropTypes.bool,
  column: PropTypes.string,
  image: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default LicensingAreaItem;
