import React from "react";
import { Helmet } from "react-helmet";
import PageWrap from "../../components/PageWrap";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";

const BakanlikYazi = () => {
  return (
    <div>
      <Helmet>
        <title>lisanslama.org - Sıkça Sorulan Sorular - Bakanlık Yazısı</title>
        <meta
          name="description"
          content="Online lisanslama sıkça sorulan sorular bakanlık yazısı sayfası, lisanslama.org sıkça sorulan sorular bakanlık yazısı sayfası, lisanslama.org sıkça sorulan sorular bakanlık yazısı, lisanslama.org S.S.S. bakanlık yazısı"
        />
      </Helmet>
      <PageWrap
        title="Sıkça Sorulan Sorular Bakanlık Yazısı"
        homeText="Anasayfa"
        pageName="Bakanlık Yazısı "
      />
      <MDBContainer className="my-5">
        <MDBRow>
          <MDBCol md="12">
            <img
              width="100%"
              alt="Sayfa 1"
              src={require("../../assets/images/bakanlik_yazisi_1.jpg").default}></img>
            <img
              width="100%"
              alt="Sayfa 2"
              src={require("../../assets/images/bakanlik_yazisi_2.jpg").default}></img>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default BakanlikYazi;
