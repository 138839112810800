import React from "react";

function TermOfUseText() {
  return (
    <>
      <h3 className="h3 h3-responsive text-center mb-5">KULLANIM KOŞULLARI</h3>
      <p>
        <a
          rel="noopener noreferrer"
          href="https://www.lisanslama.org"
          target="_blank"
          style={{ textDecoration: "underline" }}>
          https://www.lisanslama.org
        </a>{" "}
        uzantılı internet sitesinde (bundan sonra kısa <b>"İNTERNET SİTESİ"</b> denilecektir.)
        sunulan hizmetler MÜ-YAP Bağlantılı Hak Sahibi Fonogram Yapımcıları Meslek Birliği (bundan
        böyle kısaca, <b>"MÜ-YAP"</b> olarak anılacaktır) tarafından sağlanmaktadır. "İNTERNET
        SİTESİ"nin yasal sahibi MÜ-YAP olup, "İNTERNET SİTESİ" üzerinde her türlü kullanım ve
        tasarruf yetkisi MÜ-YAP'a aittir.
      </p>

      <p>
        İşbu kullanım koşullarını{" "}
        <a
          rel="noopener noreferrer"
          href="https://www.lisanslama.org"
          target="_blank"
          style={{ textDecoration: "underline" }}>
          https://www.lisanslama.org
        </a>{" "}
        uzantılı internet sitesi adına MÜ-YAP gerektiği zaman değiştirebilir; ancak bu değişiklikler
        düzenli olarak "İNTERNET SİTESİ"nde yayınlanacak ve aynı tarihten itibaren geçerli
        olacaktır.
      </p>

      <p>
        İNTERNET SİTESİ hizmetlerinden yararlanan ve "İNTERNET SİTESİ"ne erişim sağlayan her gerçek
        ve tüzel kişi, MÜ-YAP tarafından işbu kullanım koşulları hükümlerinde yapılan her
        değişikliği, önceden kabul etmiş sayılmaktadır.
      </p>

      <p>
        MÜ-YAP, bu "İNTERNET SİTESİ"nde yer alan veya alacak olan bilgileri, formları ve içeriği
        dilediği zaman değiştirme hakkını saklı tutmaktadır.
      </p>

      <h3 className="h3 h3-responsive mt-5">Sözleşme Tanımları</h3>

      <p>
        <a
          rel="noopener noreferrer"
          href="https://www.lisanslama.org"
          target="_blank"
          style={{ textDecoration: "underline" }}>
          https://www.lisanslama.org
        </a>{" "}
        uzantılı internet sitesi: MÜ-YAP'ın, kullacıların müzik lisansına daha kolay ulaşmasını
        sağlamak, işletmelerin sorunlarına daha kısa yoldan çözümler bulmak için sahip oldukları
        "İNTERNET SİTESİ" dir.
      </p>

      <p>
        <b>İNTERNET SİTESİ:</b> MÜ-YAP tarafından belirlenen çerçeve içerisinde çeşitli hizmetlerin
        ve içeriklerin sunulduğu, MÜ-YAP adlı meslek birliği işletmeleri veya kuruluşları için
        lisans sözleşmesi imzalayarak müzik parçalarını (fonogramları) kullanmak için sertifika
        almak isteyen kullanıcılara çevrimiçi (on-line) ortam aracılığıyla erişmelerine imkan
        sağlayan bir internet sitesidir.
      </p>

      <p>
        <b>ÜYE:</b> 5846 sayılı FSEK'in 42'nci madde ve ilgili yasal mevzuat uyarınca MÜ-YAP'a yetki
        belgesi vermiş gerçek ve/veya tüzel kişiliği haiz fonogram yapımcılarıdır.
      </p>

      <p>
        <b>KULLANICI:</b>{" "}
        <a
          rel="noopener noreferrer"
          href="https://www.lisanslama.org"
          target="_blank"
          style={{ textDecoration: "underline" }}>
          https://www.lisanslama.org
        </a>{" "}
        uzantılı internet sitesini ziyaret eden ve kullanan kişidir.
      </p>

      <p>
        <b>LİNK:</b> "İNTERNET SİTESİ" üzerinden bir başka internet sitesine, dosyalara, içeriğe
        veya başka bir internet sitesinden ""İNTERNET SİTESİ"ne, dosyalara ve içeriğe erişimi mümkün
        kılan bağlantıdır.
      </p>

      <p>
        <b>İÇERİK:</b> "İNTERNET SİTESİ"nde ve/veya herhangi bir başka internet sitesinde yayınlanan
        veya erişimi mümkün olan her türlü bilgi, dosya, resim, program, rakam, fiyat, vs. görsel,
        yazınsal ve işitsel imgelerdir.
      </p>

      <p>
        <b>İNTERNET SİTESİ KULLANIM KOŞULLARI:</b> "İNTERNET SİTESİ" vasıtasıyla sunulan genel veya
        kişiye özel nitelikteki hizmetlerden yararlanacak gerçek ve/veya tüzel kişilerle MÜ-YAP
        arasında elektronik ortamda akdedilen işbu sözleşmedir
      </p>

      <p>
        <b>KİŞİSEL BİLGİ:</b> KULLANICILARIN kimliği, adresi, elektronik posta adresi, telefon
        numarası, IP adresi, kredi kartı bilgileri, konum, adres ve iletişim bilgileri veya
        "İNTERNET SİTESİ"nin hangi bölümlerini ziyaret ettiği, domain tipi, browser tipi, ziyaret
        tarihi, saati, vs. bilgilerdir.
      </p>

      <p>
        Hizmetlerin Kapsamı, MÜ-YAP'ın, "İNTERNET SİTESİ" üzerinden müzik içeriği kullanan işletme
        ve kuruluşlara, 5846 sayılı Fikir ve Sanat Eserleri Kanunu mevzuatı kapsamında MÜ-YAP
        tarafından online olarak yazılı izin ve sertifika verilmesine ilişkin hizmetlerin sunulması
        ve ilişki faaliyetlerden ibarettir
      </p>

      <p>
        MÜ-YAP, "İNTERNET SİTESİ" üzerinden sunacağı hizmetlerin kapsamını ve niteliğini
        belirlemekte tamamen serbest olup, hizmetlere ilişkin olarak yapacağı değişiklikleri
        "İNTERNET SİTESİ" nde yayınlamasıyla yürürlüğe koymuş addedilir.
      </p>

      <p>
        <b>İNTERNET SİTESİ</b>,{" "}
        <a
          rel="noopener noreferrer"
          href="https://www.lisanslama.org"
          target="_blank"
          style={{ textDecoration: "underline" }}>
          https://www.lisanslama.org
        </a>{" "}
        uzantılı internet sitesini ifade eder ve bu "İNTERNET SİTESİ" bünyesinde sunulacak
        hizmetlerden yararlanabilmek için "İNTERNET SİTESİ""nin ilgili bölümünün içeriğinde
        belirtilecek özellikleri MÜ-YAP belirlemekte tamamen serbest olup, özelliklere ilişkin
        olarak yapacağı değişikliklerin "İNTERNET SİTESİ"nde yayınlamasıyla yürürlüğe koymuş
        addedilir. Genel Hükümler "İNTERNET SİTESİ" üzerinden, MÜ-YAP'ın kendi kontrolünde olmayan
        ve başkaca üçüncü kişilerin sahip olduğu ve işlettiği başka internet sitelerine ve/veya
        başka içeriklere link verilebilir. Bu linkler KULLANICI'lara ve ÜYE'lere yönlenme kolaylığı
        sağlamak amacıyla konmuş olup, herhangi bir internet sitesini veya o siteyi işleten kişiyi
        desteklememektedir. Link verilen internet sitesinin içerdiği bilgilere yönelik herhangi bir
        türde bir beyan veya garanti niteliği taşımamaktadır. "İNTERNET SİTESİ" üzerindeki linkler
        vasıtasıyla erişilen web siteleri ve içerikleri hakkında MÜ-YAP'ın herhangi bir sorumluluğu
        yoktur ve bu internet sitelerinin kullanımıyla doğabilecek zararlar, KULLANICI"ların ve
        ÜYE"lerin kendi sorumluluğundadır. MÜ-YAP bu tür link verilen web sitelerine erişimi, kendi
        yazılı muvafakatine bağlayabilir.
      </p>

      <p>
        <a
          rel="noopener noreferrer"
          href="https://www.lisanslama.org"
          target="_blank"
          style={{ textDecoration: "underline" }}>
          https://www.lisanslama.org
        </a>
      </p>

      <p>
        <b>KULLANICILAR</b>, "İNTERNET SİTESİ"ndeki, yüklenmeye (download) ve/veya paylaşıma müsait
        dosya, bilgi ve belgelerin, virüslerden, wormlardan, truva atlarından, dialer
        programlarından spam, spyware veya bunlar gibi diğer her türlü kötü ve zarar verme amaçlı
        kodlardan veya materyallerden arındırılamamış olabileceğini ve bu hususlarda "İNTERNET
        SİTESİ"nin garanti vermediğini kabul etmektedir. Bu tip kötü ve zarar verme amaçlı
        programların, kodların veya materyallerin önlenmesi, veri giriş-çıkışlarının doğruluğu veya
        herhangi bir kayıp verinin geri kazanılması için gereken tüm yazılım ve donanım
        ihtiyaçlarını karşılamak, bakım ve güncellemelerini yapmak tamamen KULLANICI"nın ve ÜYE"nin
        sorumluluğundadır. Bu tür kötü amaçlı programlar, kodlar veya materyallerin sebep
        olabileceği, veri yanlışlıkları veya kayıplarından dolayı KULLANICI ve ÜYE"nin veya üçüncü
        kişilerin uğrayabileceği hiçbir zarardan MÜ-YAP sorumlu değildir. Bu tür kötü amaçlı
        programlar, kodlar veya materyaller, veri yanlışlıkları veya kayıplarından dolayı
        KULLANICI'nın ve ÜYE'nin veya üçüncü kişilerin uğrayabileceği hiçbir zarardan MÜ-YAP sorumlu
        değildir.
      </p>

      <p>
        MÜ-YAP , işbu "İNTERNET SİTESİ" ve "İNTERNET SİTESİ" uzantısında mevcut her tür hizmet,
        ürün, kampanya, vs. bilgiler ve "İNTERNET SİTESİ"ni kullanma koşulları ile SİTE"de sunulan
        bilgileri önceden bir ihtara gerek olmaksızın değiştirme, "İNTERNET SİTESİ" ve içeriğini
        yeniden düzenleme, yayını durdurma ve/veya duraklatma hakkını saklı tutar. Değişiklikler,
        "İNTERNET SİTESİ"nde yayınlanmalarıyla yürürlüğe girerler. "İNTERNET SİTESİ"nin kullanımı ya
        da "İNTERNET SİTESİ"ne giriş ile bu değişiklikler de kabul edilmiş sayılır. Bu koşullar link
        verilen diğer internet sayfaları için de geçerlidir. MÜ-YAP, sözleşmenin ihlali, haksız
        fiil, ihmal veya diğer sebepler neticesinde; işlemin kesintiye uğraması, hata, ihmal,
        kesinti, silinme, kayıp, işlemin veya iletişimin gecikmesi, bilgisayar virüsü, iletişim
        hatası, hırsızlık, imha veya izinsiz olarak kayıtlara girilmesi, değiştirilmesi veya
        kullanılması hususunda ve durumunda herhangi bir sorumluluk kabul etmez.
      </p>

      <p>
        "İNTERNET SİTESİ" dâhilinde üçüncü kişiler tarafından sağlanan hizmetlerden ve yayınlanan
        içeriklerden dolayı MÜ-YAP'ın, iş birliği içinde bulunduğu kurumların, MÜ-YAP çalışanlarının
        ve yöneticilerinin, sorumluluğu bulunmamaktadır.
      </p>

      <p>
        Herhangi bir üçüncü kişi tarafından sağlanan ve yayınlanan bilgilerin, içeriklerin, görsel
        ve işitsel imgelerin doğruluğu ve hukuka uygunluğunun taahhüdü bütünüyle bu eylemleri
        gerçekleştiren üçüncü kişilerin sorumluluğundadır. MÜ-YAP, üçüncü kişiler tarafından
        sağlanan hizmetlerin ve içeriklerin güvenliğini, doğruluğunu ve hukuka uygunluğunu taahhüt
        etmemektedir.
      </p>

      <p>
        "İNTERNET SİTESİ"ni kullananlar, yalnızca hukuka uygun ve şahsi amaçlarla SİTE üzerinde
        işlem yapabilirler. KULLANICI'ların ve ÜYE'lerin, "İNTERNET SİTESİ" dâhilinde yaptığı her
        işlem ve eylemdeki hukuki ve cezai sorumlulukları kendilerine aittir. Her KULLANICI ve her
        ÜYE, MÜ-YAP ve/veya başka bir üçüncü kişinin haklarına tecavüz teşkil edecek nitelikteki
        herhangi bir faaliyette bulunmayacağını taahhüt eder. KULLANICI ve ÜYE'lerin "İNTERNET
        SİTESİ" üzerindeki faaliyetleri nedeniyle üçüncü kişilerin uğradıkları veya
        uğrayabilecekleri zararlardan dolayı MÜ-YAP'ın doğrudan ve/veya dolaylı hiçbir sorumluluğu
        yoktur.
      </p>

      <p>
        İşbu "İNTERNET SİTESİ" nin sahibi MÜ-YAP meslek birliğidir. Bu "İNTERNET SİTESİ"nde bulunan
        bilgiler, yazılar, resimler, markalar, slogan ve diğer işaretler ile sair sınaî ve fikri
        mülkiyet haklarına ilişkin bilgilerin korunmasına yönelik programlarla, sayfa düzeni ve
        "İNTERNET SİTESİ" nin sunumu MÜ-YAP'ın izin ve lisans aldığı kuruluşların mülkiyetindedir.
        İşbu "İNTERNET SİTESİ"ndeki bilgilerin ya da "İNTERNET SİTESİ" sayfalarına ilişkin her tür
        veri tabanı, internet sitesi, software-code"ların html kodu ve diğer kodlar vs. ile
        "İNTERNET SİTESİ" içeriğinde bulunan ürünlerin, tasarımların, resimlerin, metinlerin,
        görsel, işitsel vesaire imgelerin, video kliplerin, dosyaların, katalogların ve listelerin
        kısmen ya da tamamen kopyalanması, değiştirilmesi, yayınlanması, online ya da diğer bir
        medya kullanılmak suretiyle gönderimi, dağıtımı, satılması yasaktır. KULLANICI ve ÜYE,
        yukarıda sayılan ve bunlarla sınırlı olmayan "İNTERNET SİTESİ" yazılım, donanım ve içeriğini
        çoğaltmayacağını, kopyalamayacağını, dağıtmayacağını, işlemeyeceğini gerek bu eylemleri ile
        gerekse de başka yollarla lisanslama.org internet sitesi ile doğrudan ve/veya dolaylı olarak
        rekabete girmeyeceğini kabul ve taahhüt etmektedir. KULLANICI ve ÜYE, lisanslama.org
        internet sitesi hizmetlerini, lisanslama.org internet sitesi bilgilerini ve lisanslama.org
        internet sitesinin telif haklarına tâbi çalışmalarını yeniden satmak, işlemek, paylaşmak,
        dağıtmak, sergilemek veya başkasının
        <a
          rel="noopener noreferrer"
          href="https://www.lisanslama.org"
          target="_blank"
          style={{ textDecoration: "underline" }}>
          https://www.lisanslama.org
        </a>{" "}
        hizmetlerine erişmesi veya kullanmasına izin vermek hakkına sahip değildir. Bu sayfadaki
        bilgilerin kısmen kopyalanması, basılması, işlenmesi, dağıtılması, çoğaltılması,
        sergilenmesi ancak ticari olmayan kişisel ihtiyaçlar için ve MÜ-YAP'ın yazılı izni ile
        mümkündür.
      </p>

      <p>
        MÜ-YAP, İNTERNET SİTESİ üzerinden KULLANICI'lar ve ÜYE'ler tarafından kendisine iletilen
        bilgileri ,İnternet Sitesi Kullanım Koşulları“ hükümleri doğrultusunda kullanabilir. Bu
        bilgileri işleyebilir, bir veri tabanı üzerinde tasnif edip muhafaza edebilir. MÜ-YAP,{" "}
        <b>
          aynı zamanda; KULLANICI ve ÜYE'nin kimliği, adresi, elektronik posta adresi, telefon
          numarası, IP adresi, İNTERNET SİTESİ'nin hangi bölümlerini ziyaret ettiği, domain tipi,
          browser tipi, ziyaret tarihi, saati vs bilgileri de istatistiki değerlendirme,
          kampanyaların duyurusunu yapma ve kişiye yönelik hizmetler sunma gibi amaçlarla
          kullanabilir.
        </b>{" "}
        KULLANICI'lara ve ÜYE'lere ait kişisel bilgiler, kanunla yetkili kılınan mercilerin talebi
        ve aşağıda sayılan haller hariç olmak üzere gerçek ve tüzel üçüncü kişilere
        açıklanmayacaktır. Kişisel bilgi ender olarak lisanslama.org internet sitesi için veya onun
        adına davranan üçüncü taraflara veya lisanslama.org internet sitesinin işi ile ilgili
        olanlara verilerin özgün olarak kullanım amaçlarını daha iyi işlemek ya da KULLANICI'ların
        ve ÜYE"lerin önerdiği amaçlara uygun olarak daha iyi hizmet verebilmek üzere verilir.
      </p>

      <p>
        İşbu "İNTERNET SİTESİ" Kullanım Koşulları dâhilinde MÜ-YAP tarafından açıkça yetki
        verilmediği hallerde lisanslama.org internet sitesi hizmetleri, lisanslama.org internet
        sitesi bilgileri, MÜ-YAP'ın telif haklarına tâbi çalışmaları,MÜ-YAP ticari markaları, MÜ-YAP
        ve lisanslama.org internet sitesinin ticari görünümü veya bu SİTE vasıtasıyla sağladığı
        başkaca varlık ve bilgilere yönelik tüm haklarını saklı tutmaktadır. MÜ-YAP, "İNTERNET
        SİTESİ"ne erişilmesi, "İNTERNET SİTESİ"nin ya da "İNTERNET SİTESİ"ndeki bilgilerin ve diğer
        verilerin programların vs. kullanılması sebebiyle, sözleşmenin ihlali, haksız fiil, ya da
        başkaca sebeplere binaen, doğabilecek doğrudan ya da dolaylı hiçbir zarardan sorumlu
        değildir. MÜ-YAP , sözleşmenin ihlali, haksız fiil, ihmal veya diğer sebepler neticesinde;
        işlemin kesintiye uğraması, hata, ihmal, kesinti hususunda herhangi bir sorumluluk kabul
        etmez. Bu "İNTERNET SİTESİ"ne ya da link verilen diğer internet sitelerine erişilmesi ya da
        "İNTERNET SİTESİ"nin kullanılması ile MÜ-YAP'ın, kullanım/ziyaret sonucunda, doğabilecek her
        tür sorumluluktan, mahkeme ve diğer masraflar da dahil olmak üzere her tür zarar ve talep
        hakkından ayrı kılındığı kabul edilmektedir.
      </p>

      <p>
        Devir: MÜ-YAP, bu sözleşmeyi bildirimsiz olarak istediği zaman kısmen veya bütünüyle
        devredebilir. Ancak KULLANICI ve ÜYE bu sözleşmeyi veya herhangi bir kısmını başka bir
        tarafa devredemez. Bu türden bir devir girişimi geçersizdir.
      </p>

      <h3 className="h3 h3-responsive mt-5">Mücbir Sebepler</h3>
      <p>
        Hukuken mücbir sebep sayılan tüm durumlarda, MÜ-YAP işbu, İNTERNET SİTESİ KULLANIM
        KOŞULLARI'nı geç ifa etmekten veya ifa etmemekten dolayı yükümlü değildir. Bu ve bunun gibi
        durumlar, MÜ-YAP ve lisanslama.org internet sitesi açısından, gecikme veya ifa etmeme veya
        temerrüt addedilmeyecek veya bu durumlar için MÜ-YAP'ın herhangi bir tazminat yükümlülüğü
        doğmayacaktır. Uygulanacak Hukuk ve Yetki İşbu ,İNTERNET SİTESİ KULLANIM KOŞULLARI'ndan
        kaynaklanacak ihtilaflar Türk Hukuku'na tabidir ve İstanbul Merkez Mahkemeleri ve İcra
        Daireleri yetkilidir. MÜ-YAP'ın KULLANICI ve ÜYE'nin bulunduğu ülkede dava açma hakkı
        saklıdır. Yürürlük ve Kabul İşbu ,İNTERNET SİTESİ KULLANIM KOŞULLARI, İNTERNET SİTESİ
        içeriğinde ilan edildiği tarihte yürürlük kazanır. KULLANICI'lar ve ÜYE'ler, işbu sözleşme
        hükümlerini İNTERNET SİTESİ'ni kullanmakla kabul etmiş olmaktadırlar. MÜ-YAP, dilediği zaman
        işbu sözleşme hükümlerinde değişikliğe gidebilir, değişiklikler, sürüm numarası ve
        değişiklik tarihi belirtilerek "İNTERNET SİTESİ" üzerinden yayınlanır ve aynı tarihte
        yürürlüğe girer.
      </p>

      <p><b>Güncellenme Tarihi: 9 Eylül 2024</b></p>
    </>
  );
}

export default React.memo(TermOfUseText);
