import React from "react";

function ClarificationTermText() {
  return (
    <>
      <h3 className="h3 h3-responsive text-center mb-5">
        AYDINLATMA METNİ (VERİ KORUMA BİLDİRİMİ)
      </h3>
      <p>
        6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında yasal bir zorunluluk olan kişisel
        verilerinizin işlenmesi sırasında aydınlatma yükümlülüğü nedeni ile, verilerinizi neden
        işlediğimizi, haklarınızın neler olduğunu size bu metinle anlatmak isteriz. Kişisel
        verilerinizi korumak önceliğimizdir ve bu konuda da kendimizi geliştirmeye devam ediyoruz.
      </p>
      <p>
        Aşağıda yer alan düzenlemeler kimliği belirli ya da belirlenebilir bir kişiye ait olan
        veriler ile ilgilidir. Şirketlere ilişkin veriler Kişisel Verilerin Korunması Kanunu
        kapsamında değerlendirilmeyip sadece gerçek kişiye ilişkin veriler nedeni ile veri koruma
        bildirimini gerçekleştirmekteyiz.
      </p>
      <p>Birlikte ne haklara sahip olduğunuza göz atalım.</p>

      <h3 className="h3 h3-responsive mt-4">İçindekiler</h3>
      <ol>
        <li>Veri Koruma Bildirimi kimlere uygulanır?</li>
        <li>Verilerimin korunmasından kim sorumludur, kime ulaşabilirim?</li>
        <li>Hangi verilerim hangi hukuki sebebe dayanarak işleniyor?</li>
        <li>Verilerim nereden geliyor?</li>
        <li>Verilerim nerede kullanılıyor?</li>
        <li>Verilerim aktarılacak mı?</li>
        <li>Verilerim üçüncü bir ülkeye ya da uluslararası bir kuruluşa aktarılacak mı?</li>
        <li>Otomatik bir karar verme sürecine dahil ediliyor muyum?</li>
        <li>Verilerim ne kadar süre ile kaydedilecek?</li>
        <li>OLB'ye karşı haklarım nelerdir?</li>
        <li>Veri işleme onayımı geri alabilir miyim?</li>
        <li>Kişisel Verilerin Korunması Kurumu'na şikayet hakkım var mıdır?</li>
      </ol>

      <h3 className="h3 h3-responsive">1. Veri Koruma Bildirimi Kimlere Uygulanır?</h3>
      <p>
        Online Lisanslama Birimi olarak,{" "}
        <a
          rel="noopener noreferrer"
          href="https://www.lisanslama.org"
          target="_blank"
          style={{ textDecoration: "underline" }}>
          https://www.lisanslama.org
        </a>{" "}
        sitesinde kullanıcı girişi bölümünde, lisanslama sözleşmesi kurulması aşamasında, kredi
        kartı ödeme kısmında tarafımıza başvuran gerçek kişilere ait tüm veriler kişisel veridir ve
        gerçek kişileri kapsar.
      </p>

      <h3 className="h3 h3-responsive">2. Verilerimin işlenmesinden kim sorumludur?</h3>
      <p>
        MÜ-YAP, kullanıcının müzik lisansına daha kolay ve tek elden ulaşmasını sağlamak,
        işletmelerin sorunlarına daha kısa yoldan çözümler bulmak için Lisanslama.org internet
        sitesi ile hizmet vermektedir.
      </p>
      <p>Lisanslama.org internet sitesinin tüm işlemlerinden MÜ-YAP sorumludur.</p>

      <p>
        <b>MÜ-YAP Bağlantılı Hak Sahibi Fonogram YapımcıIarı Meslek Birliği</b>
      </p>
      <p>Adres: Kuloğlu Mah. Turnacıbaşı Cad. Saray Han. No:10 K:5 P.K. 34433 Beyoğlu-İSTANBUL</p>
      <p>E-Posta: muyap@mu-yap.org.tr</p>
      <p>Telefon: +90 212 292 46 13</p>
      <p>Faks: +90 212 292 46 17</p>

      <p className="mt-2">
        Yukarıda yer alan Veri Sorumlusu, kişisel verilerinizin işlenmesinden ve verileriniz ile
        ilgili her işlemden sorumludur.
      </p>

      <h3 className="h3 h3-responsive">
        3. Hangi verilerim hangi hukuki sebebe dayanarak işleniyor?
      </h3>
      <p>
        Verileriniz{" "}
        <a
          rel="noopener noreferrer"
          href="https://www.lisanslama.org"
          target="_blank"
          style={{ textDecoration: "underline" }}>
          https://www.lisanslama.org
        </a>{" "}
        portalında toplanan veriler de dahil olmak üzere, gerçekleştirdiğimiz çevrimiçi hizmetlerin
        ve sözleşme ilişkilerinin yerine getirilmesinin bir parçası olarak işlenmektedir.
      </p>
      <p>
        Lisanslama sözleşmesinin kurulması için işletmenizin ticari unvanı, vergi dairesi, vergi
        numarası, adresi, tabela adı, telefonu, e-posta adresi, işletmenin yetkilisinin adı, soyadı,
        T.C kimlik numarası veya pasaport numarası, iletişim bilgileri, güncel adresi ve e-posta
        adresi ile doğum tarihi verileri işlenmektedir.
      </p>
      <p>
        Ödeme işlemlerinizin gerçekleştirilmesi için kredi kartınızın son dört hanesi, CVV kodu,
        kredi kartınızın üzerinde yer alan adı ve soyadı verileriniz işlenmektedir.
        www.lisanslama.org sitesinde yer alan lisans başvurusu işlemleri ve kullanıcı bilgileri
        portalı için işletmeniz ya da kuruluşunuz için lisans alma başvurusu yaptığınız takdirde IP
        alan adresiniz, işletmenizin veya kuruluşunuzun ticaret unvanı, adresi, iletişim bilgileri,
        işletme veya kurulun konum bilgileri ile işletme adına başvuran şahsın ad-soyad, adres ve
        iletişim bilgileri, ödeme yapılarak lisans/sertifika alınması tercih edilirse banka kredi
        kartıyla ödeme yapılabilmesi için gerekli veriler toplanmakta ve amaçla sınırlı olarak
        işlenmektedir.
      </p>
      <p>
        Lisanslama sözleşmesi öncesinde lisanslama faaliyetlerini tanıtmak ve sözleşme ilişkisinin
        kurulmasını gerçekleştirmek için daha önce tarafımızla paylaştığınız verileriniz kapsamında
        iletişim numaranıza kısa mesaj göndermek ya da kayıtlı e-posta adresinize tanıtım formu
        yollamak nedeni ile verileriniz işlenmektedir.
      </p>
      <p>
        www.lisanslama.org adresini ziyaret ettiğinizde IP alan adresiniz, işletmeniz ya da
        kuruluşunuz için lisans alma başvurusu yaptığınız takdirde işletmenizin veya kuruluşunuzun
        ticaret ünvanı, adresi, iletişim bilgileri ile işletme adına başvuran şahsın ad-soyad, adres
        ve iletişim bilgileri, ödeme yapılarak lisans/sertifika alınması tercih edilirse banka kredi
        kartıyla ödeme yapılabilmesi için gerekli veriler toplanmakta ve amaçla sınırlı olarak
        işlenmektedir.
      </p>
      <h3 className="h3 h3-responsive">
        Ve aşağıda sayılan amaçlarla da kişisel verileriniz işlenmektedir:
      </h3>
      <p>
        Kişisel verileriniz, "Kanun"a uygun olarak, Online Lisanslama Birimi tarafından sağlanan
        hizmet ve MÜ-YAP'ın başta 5846 sayılı Fikir ve Sanat Eserleri Kanunu (FSEK) olmak üzere
        ilgili mevzuattan kaynaklanan faaliyetlerine bağlı olarak değişkenlik gösterebilmekle
        birlikte; otomatik ya da otomatik olmayan yollarla, MÜ-YAP birimleri ve ofisleri, internet
        sitesi, sosyal medya mecraları, mobil uygulamalar ve benzeri vasıtalarla sözlü, yazılı ya da
        elektronik olarak toplanabilecektir. Çağrı merkezlerimizi, internet sayfamızı veya mobil
        uygulamalarımızı kullandığınızda veya internet sitemizi, sosyal medya mecralarını ziyaret
        ettiğinizde, kişisel verileriniz oluşturularak ve güncellenerek işlenebilecektir.
      </p>
      <p>
        Kişisel verileriniz "Kanun"un 5. ve 6. maddelerinde belirtilen şartlara ve ilgili tüm
        mevzuata uygun olarak, lisanslama.org internet sitesi tarafından;
      </p>
      <ol style={{ listStyle: "disc" }}>
        <li>
          Yasal düzenlemelerin gerektirdiği veya zorunlu kıldığı şekilde hukuki yükümlülüklerimizin
          yerine getirilmesinin sağlanması,
        </li>
        <li>
          MÜ-YAP ile lisans sözleşmesi imzalayan kullanıcıların bilgilerinin temin edilmesi ve
          saklanması,
        </li>
        <li>
          Operasyonel faaliyetlerinin yerine getirilmesi için yazılım hizmetleri ve diğer iç / dış
          kaynak hizmetlerinin sağlanması,
        </li>
        <li>
          MÜ-YAP'ın FSEK'te belirtilen faaliyetlerinin mevzuata ve ilgili geliştirilen
          politikalarımıza uygun olarak yerine getirilmesi için ilgili birimler tarafından gerekli
          çalışmaların yapılması ve bu doğrultuda faaliyetlerin yürütülmesi,
        </li>
        <li>
          MÜ-YAP'ın kısa, orta, uzun vadeli çalışma politikalarının tespiti, planlanması ve
          uygulanması, MÜ-YAP'ın kısa, orta, uzun vadeli çalışma politikalarının tespiti,
          planlanması ve uygulanması,
        </li>
        <li>
          MÜ-YAP tarafından sunulan hizmet ve projelerin ilgili kişilerin beğeni, kullanım
          alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek önerilmesi, hizmet ve projeler
          hakkında bilgi verilmesi,
        </li>
        <li>Etkin bir müşteri hizmetinin sunulabilmesi,</li>
        <li>Hizmet ve tekliflerin sunulması,</li>
        <li>Her türlü pazarlama ve tanıtım faaliyetlerinin yürütülebilmesi,</li>
        <li>Ziyaretçi profillerinin belirlenebilmesi,</li>
        <li>MÜ-YAP'ın ticari güvenilirliğinin sağlanabilmesi,</li>
        <li>İstek, talep ve şikayetlerin cevaplanarak çözümlenmesinin sağlanması,</li>
        <li>
          Sözleşme kapsamında ve hizmet standartları çerçevesinde Müşteri'lere ve Ziyaretçiler'e
          destek hizmetinin sağlanması,
        </li>
        <li>Pazar araştırmaları ve istatistiksel çalışmalar yapılabilmesi,</li>
        <li>MÜ-YAP ile iş ilişkisi içinde bulunan kişiler ile irtibat sağlanması,</li>
        <li>Pazarlama, uyum yönetimi, satıcı/tedarikçi yönetimi,</li>
        <li>Bilgi güvenliği süreçlerinin planlanması, denetimi ve icrası,</li>
        <li>Bilgi teknolojileri alt yapısının oluşturulması ve yönetilmesi,</li>
        <li>Çalışanların Veri Sahibi bilgilerine erişim yetkilerinin planlanması ve icrası,</li>
        <li>Faturalandırma da dahil, finans ve/veya muhasebe işlemlerinin takibi,</li>
        <li>Hukuk işlerinin takibi,</li>
        <li>
          MÜ-YAP'dan lisans almak üzere başvurulması halinde lisans sözleşmesinin hazırlanması,
          lisans bedeli olarak ödenecek tutarın tahsili kapsamında,
        </li>
        <li>Verilerin doğru ve güncel olmasının sağlanması</li>
        <li>
          "Kanun" kapsamında ve Üye / Kullanıcı tarafından onaylanan ve kabul edilen Kullanıcı
          Sözleşmesinde belirtilen hükümler ile sınırlı olmak üzere;{" "}
          <a
            rel="noopener noreferrer"
            href="https://www.lisanslama.org"
            target="_blank"
            style={{ textDecoration: "underline" }}>
            https://www.lisanslama.org
          </a>{" "}
          internet sitesi ve mobil uygulamasına Üye/Kullanıcının yüklediği her türlü kişisel veri,
          görsel, ses, audio ve video gibi her türlü materyal ve tüm verilerin MÜ-YAP'ın iş
          ortakları ile paylaşılması, bu ortaklarla yapılan çalışmalarda kullanılması, amacıyla
          işlenir.
        </li>
      </ol>

      <h3 className="h3 h3-responsive">4. Verilerim nereden geliyor?</h3>
      <p>
        Kişisel verileriniz, hukuki yükümlülüklerin gerektirdiği süreyle ya da ilgili mevzuatta izin
        verilen süreyle mevzuata uygun koşullarda saklanmaktadır. Kişisel verileriniz "Kanun"un 5.
        ve 6. maddelerinde belirtilen kişisel veri işlenme şartları ve amaçları kapsamında bu metnin
        1. ve 2. maddelerinde belirtilen amaçlarla toplanabilecek, işlenebilecek, aktarılabilecek ve
        saklanabilecektir.
      </p>

      <p>
        Kişisel verileriniz, MÜ-YAP tarafından farklı yollardan (
        <a
          rel="noopener noreferrer"
          href="https://www.lisanslama.org"
          target="_blank"
          style={{ textDecoration: "underline" }}>
          https://www.lisanslama.org
        </a>{" "}
        internet sitesi mobil uygulaması, MÜ-YAP merkezi, şubeleri, işbirliği halindeki diğer meslek
        birlikleri veya diğer alt yüklenicileri veya iş ortakları ile iletişime geçebileceğiniz ofis
        ve diğer fiziki ortamlar, çağrı merkezleri, internet siteleri, mobil uygulamalar ve benzeri
        elektronik işlem platformları, sosyal medya veya diğer kamuya açık mecralar aracılığıyla,
        düzenleyecekleri eğitim, seminer ve benzeri ortamlara katılmanızla, tahkikat yöntemiyle veya
        diğer meslek birlikleri veya anlaşmalı oldukları diğer kişi ve kuruluşlar kanalıyla sözlü,
        yazılı, ses veya görüntü kaydı veya diğer fiziksel veya elektronik ortamda vb. ) elde
        edilebilir.
      </p>
      <p>
        Lisanslama.org internet sitesi bünyesindeki faaliyetlerin, MÜ-YAP'ın FSEK'ten kaynaklanan
        yükümlülük, ilke, hedef ve stratejilerine uygun olarak yürütülmesi, MÜ-YAP'ın hak ve
        menfaatleri ile itibarının korunması amacıyla, MÜ-YAP bünyesindeki birimlerden biri
        tarafından toplanan ve işlenmekte olan kişisel veriler, yine MÜ-YAP bünyesindeki diğer
        birimlere, iş ortaklarına, hizmet vericilerine ve işbirliği halindeki diğer meslek
        birliklerine aktarılabilir ve bunlar tarafından da işlenebilir.
      </p>

      <h3 className="h3 h3-responsive">5. Verilerim nerede kullanılıyor?</h3>
      <p>
        Lisanslama sözleşme ilişkisinin kurulması, devamı ve ödeme işlemlerinizin gerçekleştirilmesi
        için işlediğimiz kişisel verilerinizi sadece ilgili alanlarla sınırlı olarak kullanırız.
        Örneğin tahsilat işleminin gerçekleştirilmesi için sizi yönlendireceğimiz portala girmiş
        olduğunuz kredi kart numaranızın son dört hanesi ve güvenlik kodu verileriniz sadece ödeme
        alanı için kullanılacaktır.
      </p>
      <p>
        Ayrıca kişisel verilerinizi meşru işleme amaçlarımız doğrultusunda ya da kamu kurum ve
        kuruluşlarının istemi ile de işleriz. Örneğin üçüncü tarafların çıkarı ya da Lisanslama
        Birliğimizin ve veri sorumlusunun çıkarları, verilerinizin güvenliğinin sağlanması gibi.
        Verileriniz, BT güvenliğinin sağlanması, Muhasebe işlemlerinin gerçekleştirilmesi, e-posta
        hesaplarınızın güvende tutulması, verilerinizin buluta aktarılması, bilanço gibi operasyonel
        faaliyetlerin gerçekleştirilmesi, vergi dairesine bildirimlerin gerçekleştirilmesi, Kültür
        ve Turizm Bakanlığı'nın taleplerinin yerine getirilmesi nedeni ile kullanılmaktadır.
      </p>
      <p>
        Bazı durumlarda da açık onayınız alınarak verileriniz talep konusu alanda kullanılmaktadır.
        Açık onay alınması gereken durumlarda tarafınıza ulaşılacaktır.Bazı durumlarda da açık
        onayınız alınarak verileriniz talep konusu alanda kullanılmaktadır. Açık onay alınması
        gereken durumlarda tarafınıza ulaşılacaktır.
      </p>

      <h3 className="h3 h3-responsive">
        6. Verilerim aktarılacak mı ve hangi amaçlarla aktarılmaktadır?
      </h3>
      <p>
        Verileriniz kişisel verilerin aktarımını zorunlu kılan bazı hallerde aktarılmaktadır.
        Örneğin, Mahkeme, Savcılık soruşturma talepleri, Yasal takiplerin yerine getirilmesi, Vergi
        dairesine yapılması gereken bildirimler, muhasebe işlemlerinin gerçekleştirilmesi, Kültür ve
        Turizm Bakanlığı'na sunulması gereken bilgiler nedeni ile aktarılmaktadır. Bu örneklere
        benzer örnekler nedeni ile de kişisel verileriniz aktarılmaktadır. Online Lisanslama Birimi
        tarafından aktarma durumları sadece yasal gereklilikler nedeni ile yapılmaktadır.
      </p>
      <p>
        BT güvenliği, e-postalarınızın yasal süresi boyunca saklanması, evraklarınızın güvenliği
        nedeni ile de server ağları yurtiçinde bulunan şirketlerin teknik yardımı ile KVKK
        yükümlülükleri de yerine getirilmek sureti ile verileriniz aktarılmaktadır.
      </p>

      <h3 className="h3 h3-responsive">
        Ve aşağıda yer alan diğer amaçlarla verileriniz aktarılmaktadır:
      </h3>
      <p>
        Kişisel verileriniz; “Kanun” tarafından öngörülen temel ilkelere uygun olarak ve “Kanun”un
        8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçlarına uygun olarak,
        <a
          rel="noopener noreferrer"
          href="https://www.lisanslama.org"
          target="_blank"
          style={{ textDecoration: "underline" }}>
          https://www.lisanslama.org
        </a>{" "}
        internet sitesinin meşru ve hukuka uygun kişisel veri işleme amaçları doğrultusunda,{" "}
        <a
          rel="noopener noreferrer"
          href="https://www.lisanslama.org"
          target="_blank"
          style={{ textDecoration: "underline" }}>
          https://www.lisanslama.org
        </a>{" "}
        internet sitesi adına MÜ-YAP tarafından aşağıda yer alan amaçlarla;
      </p>
      <ol style={{ listStyle: "disc" }}>
        <li>Ticari itibarının ve oluşturduğu güvenin korunması,</li>
        <li>
          Kısa, orta ve uzun vadede ticari ve iş stratejilerini belirlenmesi, planlanması ve
          uygulanması{" "}
        </li>
        <li>
          Sunulan hizmet ve projelerden sizleri faydalandırmak için gerekli çalışmaların iş
          birimlerimiz tarafından yapılması,
        </li>
        <li>
          4. MÜ-YAP'ın FSEK'te belirtilen faaliyetleri çerçevesinde gerekli iş ve işlemlerin
          yapılmasının sağlanması ve bu doğrultuda faaliyetlerin yürütülmesi,
        </li>
        <li>
          İş ilişkisi içerisinde olunan kişilerin, kuruluşların, resmi mercilerin ve diğer meslek
          birliklerinin hukuki ve ticari güvenliğinin temini,
        </li>
        <li>
          MÜ-YAP tarafından yürütülen iletişime yönelik idari operasyonlar, MÜ-YAP'a ait
          lokasyonların fiziksel güvenliğini ve denetimini sağlamak, iş ortağı/müşteri/diğer meslek
          birlikleri/tedarikçi (yetkili veya çalışanları) değerlendirme süreçleri, itibar araştırma
          süreçleri, hukuki uyum süreci, denetim, mali işler vb.),
        </li>
        <li>
          İnsan kaynakları politikalarının yürütülmesi temini Amaçlarıyla MÜ-YAP ve MÜYORBİR
          yetkililerine, çalışanlarına, hizmet vericilerimize, diğer meslek birliklerine, resmi
          mercilere, iş ortaklarımıza, tedarikçilerimize "Kanun"en yetkili kurum ve kuruluşlara
          aktarılır.
        </li>
      </ol>
      <p>Kişisel verileriniz yurtdışına aktarılmamaktadır.</p>

      <h3 className="h3 h3-responsive">
        7. Verilerim Üçüncü Bir Ülkeye ya da Uluslararası Bir Kuruluşa Aktarılır mı?
      </h3>
      <p>
        Veriler, 3. bir ülkeye aktarılmamaktadır. Aktarılması durumunda Kişisel Verilerin Korunması
        Kanunu'nun 9. maddesinde yer alan hükümler uygulanacaktır. Buna göre;
      </p>
      <ol style={{ listStyle: "disc" }}>
        <li>İlgili kişinin açık rızası olmaksızın yurtdışına aktarılamaz.</li>
        <li>
          Kanunlarda açıkça öngörülmesi, fiili imkânsızlık nedeniyle rızasını açıklayamayacak
          durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir
          başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması, bir sözleşmenin
          kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına
          ait kişisel verilerin işlenmesinin gerekli olması, veri sorumlusunun hukuki yükümlülüğünü
          yerine getirebilmesi için zorunlu olması, ilgili kişinin kendisi tarafından
          alenileştirilmiş olması, bir hakkın tesisi, kullanılması veya korunması için veri
          işlemenin zorunlu olması, ilgili kişinin temel hak ve özgürlüklerine zarar vermemek
          kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması
          hallerinde ise;
        </li>
      </ol>

      <p>
        Yeterli korumanın bulunması (Aktarma ülkesinde veri koruma kanunun bulunması, yeterli yasal
        güvencelerin ve şikayet mekanizmalarının kurulmuş olması, aktarılacak veri sorumlusunun
        yeterli önlemleri almış olması gibi)
      </p>

      <p>
        Yeterli korumanın bulunmaması durumunda Türkiye'deki ve ilgili yabancı ülkedeki veri
        sorumlularının yeterli bir korumayı yazılı olarak taahhüt etmeleri ve Kurul'un yazılı
        izninin bulunması, durumlarında açık rıza şartı aranmaksızın kişisel veriler aktarılır.{" "}
      </p>

      <h3 className="h3 h3-responsive">8. Otomatik Karar Verme Gerçekleşiyor mu?</h3>
      <p>Otomatik karar verme söz konusu değildir.</p>

      <h3 className="h3 h3-responsive">9. Verilerim ne kadar süreyle kaydedilecek?</h3>
      <p>
        Kişisel verilerinizi yalnızca sözleşmeye bağlı olarak yerine getirdiğimiz sürece, yasal
        saklama süresi ile sınırlı işleriz ve depolarız. Silinme talebiniz ile ilgili başvurunuz
        incelendiğinde yasal süre doldu ise ya da meşru bir zemin kalmadı ise verilerinizin
        silinmesi talebiniz karşılanır.
      </p>

      <h3 className="h3 h3-responsive">
        10. lisanslama.org internet sitesine karşı haklarım nelerdir
      </h3>
      <ol style={{ listStyleType: "lower-alpha" }}>
        <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
        <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
        <li>
          Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını
          öğrenme,
        </li>
        <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
        <li>
          Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini
          isteme,
        </li>
        <li>
          7'nci maddede öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok
          edilmesini isteme,
        </li>
        <li>
          (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü
          kişilere bildirilmesini isteme,
        </li>
        <li>
          İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle
          kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,
        </li>
        <li>
          Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın
          giderilmesini talep etme,
        </li>
      </ol>
      <p>haklarına sahiptir.</p>

      <h4 className="h4 h4-responsive">
        10.1. Kişisel verilerim işlendi mi? Hangi kişisel verilerim işlendi?
      </h4>
      <h4 className="h4 h4-responsive">
        10.2. Kişisel verilerim hangi amaçla işlendi ve aydınlatma metninde yer alan işleme amacı
        ile bağlı olarak mı kullanıldı?
      </h4>
      <h4 className="h4 h4-responsive">
        10.3. Yurtiçinde ve yurtdışında verilerim kimlere aktarıldı?
      </h4>
      <h4 className="h4 h4-responsive">
        10.4. Kişisel verilerim hatalı, eksik, yanlış kaydedildi ise düzeltebilir misiniz?
      </h4>
      <h4 className="h4 h4-responsive">
        10.5. Saklama koşulları ortadan kalkan verilerimi silebilir misiniz? Silme yönteminiz
        konusunda bilgi verebilir misiniz?
      </h4>
      <h4 className="h4 h4-responsive">
        10.6. Silinen kişisel verilerimi aktardığınız üçüncü kişilere de bildirebilir misiniz?
      </h4>
      <h4 className="h4 h4-responsive">
        10.7. Otomatik işleme süreçlerine tabi tutuldum mu ve aleyhime bir durum oluştu mu?
      </h4>
      <h4 className="h4 h4-responsive">
        10.8. Kişisel verilerimin işleme amaçlarına aykırı olarak işlenmesi nedeni ile tarafınızdan
        zararımın giderilmesini talep ediyorum. İlgili sürede öder misiniz?
      </h4>

      <p>
        Yukarıda veri sorumlusuna yönelteceğiniz bir veya birkaç sorudan ya da hepsinden hak
        taleplerinizi sistemimizde mevcut Başvuru Formu'na açıklayıcı bir şekilde aktarmanız ve
        muyap@mu-yap.org adresine ya da Veri Sorumlusu Mü-YAP'ın Kuloğlu Mah. Turnacıbaşı Cad. Saray
        Han. No:10 K:5 P.K. 34433 Beyoğlu-İSTANBUL adresine ıslak imzalı gönderiniz. Başvurunuz,
        başvuru formunda yer alan şekil şartlarına uygun olarak gerçekleştirildiği taktirde veri
        sorumlularından birine ulaştığı tarihten itibaren 30 gün içerisinde cevaplanacaktır. Veri
        sorumlularımızdan birine başvurmadan, Kişisel Verilerin Korunması Kurulu'na yaptığınız
        başvurular geçersiz kabul edilecektir. Lütfen bu başvuru yolunu tüketiniz.
      </p>

      <h3 className="h3 h3-responsive">11. Veri işleme onayımı geri alabilir miyim?</h3>
      <p>
        6698 sayılı yasa yürürlüğe girmeden önce işlenen kişisel verileriniz ile ilgili işlenen
        kişisel verileriniz için silinmesini talep etme hakkınız halen mevcuttur. 9. maddede yazılı
        veri sorumlularımızdan birinin e-posta ve iletişim adresine başvurunuz ile bu verilerinizin
        silinip silinmediğini, işleme onayı ile ilgili bilgi alabilirsiniz. Verilerinizin işlenmesi
        ile ilgili iptal hakkınızın olduğu durumlarda iptal hükümleri talep tarihinizden sonrası
        için geçerlidir.
      </p>

      <h3 className="h3 h3-responsive">
        12. Kişisel Verilerin Korunması Kurulu'na şikayet hakkım var mıdır?
      </h3>
      <p>
        Veri sorumlusuna KVKK m.11'de yer alan ve burada 9. maddede saydığımız haklarınızı içeren
        usulüne uygun başvuru yapmanız halinde, Veri Sorumlusu, başvurunun kendisine ulaştığı
        tarihten itibaren 30 gün içerisinde cevap vermek yükümlülüğündedir. Başvurunuzun eksik
        bulunması, cevap verilmemesi ya da cevabın tarafınızı yeterince aydınlatmaması halinde
        Kişisel Verilerin Korunması Kurulu'na, Veri sorumlusunun cevabını öğrendiğiniz tarihten
        itibaren otuz gün her halde başvuru tarihinden itibaren altmış gün içinde Kurul'a şikayette
        bulunabilirsiniz. Veri sorumlusuna KVKK m.11'de yer alan ve burada 9. maddede saydığımız
        haklarınızı içeren usulüne uygun başvuru yapmanız halinde, Veri Sorumlusu, başvurunun
        kendisine ulaştığı tarihten itibaren 30 gün içerisinde cevap vermek yükümlülüğündedir.
        Başvurunuzun eksik bulunması, cevap verilmemesi ya da cevabın tarafınızı yeterince
        aydınlatmaması halinde Kişisel Verilerin Korunması Kurulu'na, Veri sorumlusunun cevabını
        öğrendiğiniz tarihten itibaren otuz gün her halde başvuru tarihinden itibaren altmış gün
        içinde Kurul'a şikayette bulunabilirsiniz.
      </p>
    </>
  );
}

export default React.memo(ClarificationTermText);
