export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const FORGOT_PASSWORD_OTP_START = "FORGOT_PASSWORD_OTP_START";
export const FORGOT_PASSWORD_OTP_SUCCESS = "FORGOT_PASSWORD_OTP_SUCCESS";
export const FORGOT_PASSWORD_OTP_FAIL = "FORGOT_PASSWORD_OTP_FAIL";

export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";

export const CHECK_AUTH_START = "CHECK_AUTH_START";
export const CHECK_AUTH_SUCCESS = "CHECK_AUTH_SUCCESS";
export const CHECK_AUTH_FAIL = "CHECK_AUTH_FAIL";

export const FETCH_CSRF_START = "FETCH_CSRF_START";
export const FETCH_CSRF_SUCCESS = "FETCH_CSRF_SUCCESS";
export const FETCH_CSRF_FAIL = "FETCH_CSRF_FAIL";
