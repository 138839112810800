import { MDBSpinner } from "mdbreact";
import React from "react";

export default function RouterLoadingFallback() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",

        backgroundColor: "#ffffff",
      }}>
      <div>
        <MDBSpinner big multicolor />
        <hr />
        <span className="mt-3">Yükleniyor...</span>
      </div>
    </div>
  );
};
