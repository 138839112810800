import axios from "../../lib/publicAxios";

import {
  // FETCH ALL
  FETCH_COMPANY_TYPES_START,
  FETCH_COMPANY_TYPES_SUCCESS,
  FETCH_COMPANY_TYPES_FAIL,

  // FETCH BY ID
  FETCH_COMPANY_TYPE_BY_ID_START,
  FETCH_COMPANY_TYPE_BY_ID_FAIL,
  FETCH_COMPANY_TYPE_BY_ID_SUCCESS,
} from "./types";

/**
 * @name fetchCompanyTypes
 * @description İşletme türlerinin listesini çeker
 */

export const fetchCompanyTypes = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_COMPANY_TYPES_START,
    });

    axios
      .get("/company-type/?pageSize=100&page=1")
      .then((res) => {
        const fetchedData = res.data.records;
        dispatch({ type: FETCH_COMPANY_TYPES_SUCCESS, payload: fetchedData });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_COMPANY_TYPES_FAIL,
          payload: err,
        });
      });
  };
};

/**
 * @name FETCH COMPANY TYPE BY ID
 * @description Belirli bir işletme türünün detaylarını çeker
 * @param {ObjectId-string} companyTypeId İşletme türü IDsi
 */

export const fetchCompanyTypeById = (companyTypeId, onSuccessCallback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_COMPANY_TYPE_BY_ID_START });
    axios
      .get(`/company-type/${companyTypeId}`)
      .then((response) => {
        dispatch({ type: FETCH_COMPANY_TYPE_BY_ID_SUCCESS, payload: response.data });

        if (onSuccessCallback && typeof onSuccessCallback === "function") {
          onSuccessCallback(response.data);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          dispatch({
            type: FETCH_COMPANY_TYPE_BY_ID_FAIL,
            payload: err.response.data.message,
          });
        } else if (err.message) {
          dispatch({
            type: FETCH_COMPANY_TYPE_BY_ID_FAIL,
            payload: err.message,
          });
        } else {
          dispatch({
            type: FETCH_COMPANY_TYPE_BY_ID_FAIL,
            payload: err,
          });
        }
      });
  };
};
