import download from "downloadjs";

import publicAxios from "../../lib/publicAxios";
import {
  // ADD LICENSE
  ADD_LICENSE_FAIL,
  ADD_LICENSE_START,
  ADD_LICENSE_SUCCESS,
  IS_VALID_LICENSE_FAIL,
  IS_VALID_LICENSE_START,
  IS_VALID_LICENSE_SUCCESS,

  // NEW LICENSE
  NEW_LICENSE_HIDE_MODAL,
  NEW_LICENSE_SHOW_MODAL,
  RESET_IS_VALID_LICENSE,
} from "./types";

export function showNewLicenseModal() {
  return (dispatch) => {
    dispatch({
      type: NEW_LICENSE_SHOW_MODAL,
    });
  };
}

export function hideNewLicenseModal() {
  return (dispatch) => {
    dispatch({
      type: NEW_LICENSE_HIDE_MODAL,
    });
  };
}

/**
 * @name addLicense
 * @description Lisans oluşturma isteği gönderir
 *
 * @param {ObjectId-string} plant - İşletmenin idsi
 */

export function addLicense(plant) {
  return (dispatch) => {
    dispatch({ type: ADD_LICENSE_START });

    publicAxios
      .post("/licenses", {
        plant,
      })
      .then((response) => {
        const license = response.data.data;
        dispatch({ type: ADD_LICENSE_SUCCESS, payload: license });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          dispatch({
            type: ADD_LICENSE_FAIL,
            payload: err.response.data.message,
          });
        } else if (err.message) {
          dispatch({
            type: ADD_LICENSE_FAIL,
            payload: err.message,
          });
        } else {
          dispatch({
            type: ADD_LICENSE_FAIL,
            payload: err,
          });
        }
      });
  };
}

export function isValidlicenseFail(error) {
  return {
    type: IS_VALID_LICENSE_FAIL,
    payload: error,
  };
}

export function isValidLicense(licenseId) {
  return (dispatch) => {
    dispatch({ type: IS_VALID_LICENSE_START });

    publicAxios
      .post(`/licenses/isvalid/${licenseId}`, null, {
        responseType: "blob",
        withCredentials: true,
      })
      .then((response) => {
        dispatch({
          type: IS_VALID_LICENSE_SUCCESS,
          payload: response.data,
        });
        download(response.data, `${licenseId}-mobil-lisans.pdf`, "pdf");
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          dispatch(isValidlicenseFail(err.response.data.message));
        } else if (err.message) {
          dispatch(isValidlicenseFail(err.message));
        } else {
          dispatch(isValidlicenseFail(err));
        }
      });
  };
}

export function resetIsValidLicense() {
  return (dispatch) => {
    dispatch({ type: RESET_IS_VALID_LICENSE });
  };
}
