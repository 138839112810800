import updateObject from "../../lib/UpdateObject";
import {
  // ADD PLANT
  ADD_PLANT_FAIL,
  ADD_PLANT_HIDE_MODAL,
  ADD_PLANT_SHOW_MODAL,
  ADD_PLANT_START,
  ADD_PLANT_SUCCESS,
} from "./types";

const initialState = {
  plant: {
    data: {},
    loading: false,
    error: null,
  },
  ui: {
    newPlantShowModal: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // ADD PLANT
    case ADD_PLANT_START:
      return updateObject(state, {
        plant: updateObject(state.plant, { loading: true, error: null }),
      });
    case ADD_PLANT_SUCCESS:
      return updateObject(state, {
        plant: updateObject(state.plant, { loading: false, error: null, data: action.payload }),
      });
    case ADD_PLANT_FAIL:
      return updateObject(state, {
        plant: updateObject(state.plant, { loading: false, error: action.payload }),
      });

    // UI
    case ADD_PLANT_SHOW_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { newPlantShowModal: true }),
      });
    case ADD_PLANT_HIDE_MODAL:
      return updateObject(state, {
        ui: updateObject(state.ui, { newPlantShowModal: false }),
      });

    default:
      return state;
  }
};

export default reducer;
